import {ContratMenuConviveDTO} from './contratmenuconvive-dto';
import {ObjectDTO} from "./object-dto";

export class ContratmenuDTO extends ObjectDTO {

  //contrat menu
  code : string;
  libelle : string;
  actif : boolean;

  // environnementSitesVisiblesDTOList: EnvironnementSitesVisiblesDTO[];
  contratMenuConvives: ContratMenuConviveDTO[] =[];


}
