// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.6-rc-9675(94e17eeb94)-C09/10/2023-10:30:35-B09/10/2023-10:33:44' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.6-rc-9675(94e17eeb94)-C09/10/2023-10:30:35-B09/10/2023-10:33:44",
  branch: "master",
  latestTag: "5.6-rc",
  revCount: "9675",
  shortHash: "94e17eeb94",
  longHash: "94e17eeb943eb4805f5dab92424e6990ac88c94c",
  dateCommit: "09/10/2023-10:30:35",
  dateBuild: "09/10/2023-10:33:44",
  buildType: "Ansible",
  } ;
