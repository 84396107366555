<dx-popup
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [fullScreen]="fullScreen"
  [title]="title"
  (onInitialized)="onInitPopup($event)"
  container=".dx-viewport"
  [(visible)]="display"
  (onDisposing)="clear()"
  (onHiding)="clear()"
  class="yo-wizard"
  resizeEnabled="true"
  [wrapperAttr]="{ class: 'yo-wizard' }"
  [height]="'90vh'"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>

  <ng-container *ngIf="currentStep === wizardSvc.STEP_INITIALIZATION">
    <div class="row part-content">
      <div class="col-lg-3 border-left-wizard"></div>
      <div class="col-lg-9 pg-t-15 text-justify">
        <h1>Bienvenue dans l'assistant de configuration des conditionnements</h1>
        <p>Cet Assistant va vous aider à configurer votre projet de conditionnement de plats</p>
        <p>Si vous souhaitez paramétrer manuellement le conditionnement de vos plats sans assistance, vous pouvez quitter
        cet assistant et parcourir manuellement les pages vous permettant de le faire. Il est recommandé de suivre cet assistant pour votre
        première mise en place des conditionnements avec DatamealOnline. Pour démarrer le paramétrage assisté, cliquez sur le bouton Démarrer</p>
      </div>
    </div>
    <div class="row part-footer">
      <div class="col-lg-12 text-center">
        Etape de démarrage : Bienvenue dans l'assistant de configuration des conditionnements - {{ currentStepPourcentage() }} %
      </div>
      <div class="col-lg-12 no-padding text-center mg-b-5">
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
        </div>
      </div>
      <div class="col-lg-12 text-center no-padding">
        <button title="Démarrer"
                class="mg-r-5"
                type="button"
                (click)="start()">
          <i class="fas fa-flag-checkered"></i> Démarrer
        </button>
      </div>
    </div>
  </ng-container>

  <yo-wizard-configuration-step-1 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_TARGET_MODEL_DISH"></yo-wizard-configuration-step-1>
  <yo-wizard-configuration-step-2 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_TYPO_CONDITIONNEMENT"></yo-wizard-configuration-step-2>
  <yo-wizard-configuration-step-3 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CONFIG_REFERENTIEL"></yo-wizard-configuration-step-3>
  <yo-wizard-configuration-step-4 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CONFIG_REFERENTIEL_COMBINAISONS"></yo-wizard-configuration-step-4>
  <yo-wizard-configuration-step-5 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT"></yo-wizard-configuration-step-5>
  <yo-wizard-configuration-step-6 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CHOICE_MEALS"></yo-wizard-configuration-step-6>
  <yo-wizard-configuration-step-7 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CHECK_RECIPES"></yo-wizard-configuration-step-7>
  <yo-wizard-configuration-step-8 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_TARGET_MODEL_PLC"></yo-wizard-configuration-step-8>
  <yo-wizard-configuration-step-9 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC"></yo-wizard-configuration-step-9>
  <yo-wizard-configuration-step-10 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_SELECT_PLCS"></yo-wizard-configuration-step-10>
  <yo-wizard-configuration-step-11 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_GROUPING_PLCS"></yo-wizard-configuration-step-11>
  <yo-wizard-configuration-step-12 (onEmitPreviousStep)="handlePreviousStep($event)" (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.STEP_CHECK_CREWS"></yo-wizard-configuration-step-12>
  <yo-wizard-configuration-step-13 (onEmitNextStep)="handleNextStep($event)" *ngIf="currentStep === wizardSvc.END"></yo-wizard-configuration-step-13>

</dx-popup>
