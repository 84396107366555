import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {Title} from '@angular/platform-browser';
import {MenuItem} from 'primeng/api';
import {FournisseurDTO} from '../../../core/dtos/fournisseur-d-t-o';
import {RoutemapService} from '../../../core/services/routemap.service';
import {GestionFournisseurService} from '../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {FournisseurService} from '../../../core/services/entities/fournisseur.service';
import {ItemNavigationService} from '../../../core/services/technique/item-navigation.service';

@Component({
  templateUrl:'./fournisseur-siderbar.component.html',
  styleUrls: ['./fournisseur-siderbar.component.scss']
})
export class FournisseurSiderbarComponent implements OnInit, OnDestroy {

  subFournisseur: Subscription;
  subscriptionRoute: Subscription;
  subActiveSecondaryRoute:Subscription;

  fournisseur: FournisseurDTO;
  navLinks: MenuItem[] = [];
  selectedNavLink:MenuItem;

  constructor(private route: ActivatedRoute,
              private fournisseurSvc: FournisseurService,
              private gestionFournisseurSvc :GestionFournisseurService,
              private routeMapSvc : RoutemapService,
              private titleSvc: Title,
              private itemNavSvc:ItemNavigationService,
              private utils: UtilsService) {
  }

  ngOnInit() {

    this.fournisseurSubscription();

    // fix kjlnhjk tabmenu route
    this.activeSecondaryRouteSubscription();
  }


  fournisseurSubscription = () => {
    this.subFournisseur = this.gestionFournisseurSvc.fournisseur$.subscribe( (data: FournisseurDTO) =>{
      this.fournisseur = data;
      this.titleSvc.setTitle(this.fournisseur && this.fournisseur.libelle);
      this.initNavLinks();
    });
  };

  private initNavLinks = () => {
    if(this.utils.isNullOrEmpty(this.fournisseur) || this.fournisseur.id === 0){
      this.fournisseur = this.fournisseurSvc.createEmptyDTO();
      this.fournisseur.id=0;
    }

    this.navLinks = [];
    this.navLinks.push({
      label: `Fiche d'identité`,
      routerLink:[`${this.fournisseur.id}/ficheidentite`],
    });

    if (this.utils.isNumberGt0(this.fournisseur.id)) {
      this.navLinks.push({
        label: `Catalogue articles`,
        routerLink:[`${this.fournisseur.id}/cataloguearticles`],
      });
      this.navLinks.push({
        label: `Filiales`,
        routerLink:[`${this.fournisseur.id}/filialesfournisseur`],
      });
    }
  };

  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subFournisseur);
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subActiveSecondaryRoute);
  };

  activeSecondaryRouteSubscription = () => {
    this.subActiveSecondaryRoute = this.itemNavSvc.secondaryRoute$.subscribe(event => {
      this.selectedNavLink = this.itemNavSvc.activeSecondaryRoute(event, this.navLinks);
    });

  };
}
