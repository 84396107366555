import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {EffectifMode} from "../constant/menu-plc.constant";
import {Observable, Subscription} from "rxjs";
import {PrintEffectifService} from "../../../core/services/gestion-plc/print-effectif.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {saveAs as fs_saveAs} from "file-saver";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {RegimeAlimentaireService} from "../../../core/services/entities/regime-alimentaire.service";
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";
import {ClientsService} from "../../../core/services/entities/clients.service";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";
import {ClientDTO} from "../../../core/dtos/client-dto";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {RepasDTO} from "../../../core/dtos/repas-dto";
import {RepasService} from "../../../core/services/entities/repas.service";
import {DxTagBoxComponent} from "devextreme-angular";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {SiteDTO} from "../../../core/dtos/site-dto";

@Component({
  selector: 'yo-print-effectif-totaux',
  templateUrl: './print-effectif-totaux.component.html',
  styleUrls: ['./print-effectif-totaux.component.scss']
})
export class PrintEffectifTotauxComponent implements OnInit, OnDestroy {

  dateTo: Date = new Date();

  dateFrom: Date = new Date();

  displayDialog: boolean = false;

  dialogTitle: string = 'Impression des effectifs totaux';

  typesEffectives: any[] = [{label: 'Prévision', value: EffectifMode.Prevision - 1},
    {label: 'Fabrication', value: EffectifMode.Fabrication - 1},
    {label: 'Facturation', value: EffectifMode.Facturation - 1}];

  typesEffectifsToExport: any[] = [EffectifMode.Prevision - 1];
  regimesSelected: number[] = [];
  repasSelected: number[] = [];
  clientsSelected: number[] = [];
  pointsLivraisonsClientSelected: number[] = [];

  sitesSelected: number[] = [];

  regimes: RegimeAlimentaireDTO[] = [];
  repas: RepasDTO[] = [];
  clients: ClientDTO[] = [];
  pointsLivraisonsClient: PointDeLivraisonDTO[] = [];

  sitesLocaux: SiteDTO[] = [];

  subDialog: Subscription;
  subRegimes: Subscription;
  subClients: Subscription;
  subRepas: Subscription;
  subPlc: Subscription;

  @ViewChild("tagEffs", {static: false}) tagEffs: DxTagBoxComponent;
  @ViewChild("tagRegimes", {static: false}) tagRegimes: DxTagBoxComponent;
  @ViewChild("tagRepas", {static: false}) tagRepas: DxTagBoxComponent;
  @ViewChild("tagClients", {static: false}) tagClients: DxTagBoxComponent;
  @ViewChild("tagPlcs", {static: false}) tagPlcs: DxTagBoxComponent;

  @ViewChild("tagSites", {static: false}) tagSites: DxTagBoxComponent;

  constructor(private printEffectifService: PrintEffectifService,
              private utilsSvc: UtilsService,
              private toastSvc: ToastService,
              private gds: GenericDatagridService,
              private regimeAlimentaireService: RegimeAlimentaireService,
              private repasSvc: RepasService,
              private clientsSvc: ClientsService,
              private pdlSvc: PointDeLivraisonService,
              private graphQlSvc: GraphQLService,
              private auth2Svc: Auth2Service) {
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subDialog);
    this.utilsSvc.unsubscribe(this.subRegimes);
    this.utilsSvc.unsubscribe(this.subClients);
    this.utilsSvc.unsubscribe(this.subPlc);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions = (): void => {
    this.subDialog = this.printEffectifService.displayDialogPrintEffectivesToTotal$
      .subscribe((params: any) => {
        this.displayDialog = true;
        if (params) {
          if (params.dateFrom) this.dateFrom = params.dateFrom;
          if (params.dateTo) this.dateTo = params.dateTo;
        }
      });
    this.subRegimes = this.getRegimesAlimentaires().subscribe(response => {
      this.regimes = response.allRegimesAlimentaires;
    });
    this.subRepas = this.gds.getAll(this.repasSvc.getEntityName(), this.repasSvc.getSort(), true).subscribe(response => {
      this.repas = response.resultList;
    });
    this.subClients = this.gds.getAll(this.clientsSvc.getEntityName(), this.clientsSvc.getSort(), true).subscribe(response => {
      this.clients = response.resultList;
      this.subPlc = this.gds.search(this.pdlSvc.findByClientsIds(this.clients.map(client => client.id)))
        .subscribe(response => {
          this.pointsLivraisonsClient = response.resultList;
        });
    });
    this.sitesLocaux = this.auth2Svc.utilisateur.siteListLocaux;
  }

  private getRegimesAlimentaires = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
    {
      allRegimesAlimentaires(filters: {
          siteIds: [${idsSites}]
        }) {
        id,
        libelle,
        code
      }
    }
    `)
  }

  closeDialog = (): void => {
    this.regimesSelected = [];
    this.repasSelected = [];
    this.clientsSelected = [];
    this.pointsLivraisonsClientSelected = [];
    this.sitesSelected = [];
    this.typesEffectifsToExport = [EffectifMode.Prevision - 1];
    this.tagEffs.instance.reset();
    this.tagRegimes.instance.reset();
    this.tagRepas.instance.reset();
    this.tagClients.instance.reset();
    this.tagPlcs.instance.reset();
    this.tagSites.instance.reset();
    this.displayDialog = false;
  }

  print = (): void => {
    let errors = '';
    if (!this.typesEffectifsToExport.length) errors += "Veuillez sélectionner au minimum un type d'effectif à exporter.";
    if (!this.dateFrom || !this.dateTo) errors += "Veuillez sélectionner une date de début et une date de fin.";
    if (errors.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, errors);
      return;
    }
    this.printEffectifService.printEffectivesToTotal({
      dateFrom: this.dateFrom.getTime(),
      dateTo: this.dateTo.getTime(),
      typesEffectifsExport: this.typesEffectifsToExport,
      regimes: this.regimesSelected,
      repas: this.repasSelected,
      clients: this.clientsSelected,
      pointsLivraison: this.pointsLivraisonsClientSelected,
      sites: this.sitesSelected
    })
      .subscribe(response => {
        let blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fs_saveAs(blob, `effectifs-totaux-${new Date().getTime()}.xlsx`);
      });
  }

  onChangeStartDate = ($event): void => this.dateFrom = $event.value;

  onChangeEndDate = ($event): void => this.dateTo = $event.value;

  onChangeTypeEffective = ($event): void => {
    this.typesEffectifsToExport = $event.value;
  }

  onChangeRegimes = ($event): void => {
    this.regimesSelected = $event.value;
  }

  onChangeRepas = ($event): void => {
    this.repasSelected = $event.value;
  }

  onChangeClient = ($event): void => {
    this.clientsSelected = $event.value;
    // 1 - On réinitialise les plc :
    this.subPlc = this.gds.search(this.pdlSvc.findByClientsIds(this.clients.map(client => client.id)))
      .subscribe(response => {
        // 2 - On récupère les plc des clients sélectionnés
        this.pointsLivraisonsClient = response.resultList
          .filter(plc => this.clientsSelected.filter(idclient => plc.client.id === idclient).length);
      });
  }

  onChangeSite = ($event): void => {
    this.sitesSelected = $event.value;
  }

  onChangePlc = ($event): void => {
    this.pointsLivraisonsClientSelected = $event.value;
  }

}
