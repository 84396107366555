import {ObjectDTO} from './object-dto';

export class BonReceptionLigneMotifNonConfoDTO extends ObjectDTO {

  // bon reception ligne
   idBonReceptionLigne : number;

  // motif non conformite
  idMotifNonConformite : number;
  libelleMotifNonConformite : string;

  // commentaire
  commentaire : string;


}
