<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idsite"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          placeholder="Selectionner le site..."
          formControlName="site"
        ></dx-lookup>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-text-box placeholder="Libellé" formControlName="libelle"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>


    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Abréviation <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-text-box placeholder="Abréviation" formControlName="abreviation"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'abreviation',label:'Abréviation'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Poids en Kg</div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-text-box placeholder="Poids en Kg" formControlName="poidsEnKg"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'poidsEnKg',label:'Poids en Kg'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        label="Enregistrer"
        class="cta-success"
        [disabled]="!canModify()">
      </yo-button>
      <yo-button
        class="mg-l-5"
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        iconClass="fas fa-times"
      >
      </yo-button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
