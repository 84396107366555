<p-dialog
  [closable]="true"
  [closeOnEscape]="true"
  (onHide)="closeDialog()"
  [(visible)]="displayDialog"
  [style]="{'width':'90vh'}"
  [modal]="true">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i [class]="isReadOnly?'fas fa-eye mg-r-5':'fas fa-edit mg-r-5'"></i><span>{{titleDialog}}</span>
    </div>
  </ng-template>

  <div class="d-flex flex-row">
    <div class="mg-r-30">
      <form [formGroup]="form" novalidate>
        <ng-container *ngFor="let field of fieldsFamilleProduit">
          <yo-dynamic-form-field-bs [fields]="fieldsFamilleProduit"
                                    [field]="field"
                                    [form]="formFamilleProduit">
          </yo-dynamic-form-field-bs>
        </ng-container>
      </form>
    </div>

    <div>
      <div class="d-flex justify-content-between mg-b-10">
        <div class="d-flex flex-row">
          <label>Selection du parent :</label>
          <strong class="mg-l-5">{{selectedParent ? selectedParent.libelle : ''}}</strong>
        </div>

        <span class="mg-l-15">
            <yo-button
              (onClick)="removeParent()"
              [disabled]="utils.isNullOrEmpty(selectedParent)"
              label="Enlever">
            </yo-button>
        </span>
      </div>

      <dx-tree-list
        id="parent-grid"
        [dataSource]="parentList"
        [showColumnHeaders]="false"
        [rootValue]="null"
        keyExpr="id"
        [height]="utils.scrollHeightContent(600)"
        (onSelectionChanged)="onChangeParentSelection($event)"
        parentIdExpr="parent.id"
        [showRowLines]="true"
        [showBorders]="true"
        [disabled]="isReadOnly"
        [columnAutoWidth]="true"
        [selectedRowKeys]="selectedRowKeys"
        [focusedRowEnabled]="true"
        [(focusedRowKey)]="selectedRowKeys[0]"
        [expandedRowKeys]="[1]">

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>

        <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
        <dxo-scrolling mode="standard"></dxo-scrolling>
      </dx-tree-list>

    </div>
  </div>
  <div class="d-flex justify-content-end mg-t-15">
    <span class="mg-r-5">
      <yo-button buttonClass="cta-success" [disabled]="isReadOnly" (onClick)="save()" tooltip="Enregistrer la famille produit" iconClass="fas fa-save"></yo-button>
    </span>

    <span class="mg-r-5">
      <yo-button class="mg-r-5" buttonClass="cta-delete" (onClick)="closeDialog()" tooltip="Annuler" iconClass="fas fa-times"></yo-button>
    </span>
  </div>
</p-dialog>
