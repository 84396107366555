<p-table
  [value]="secteursFournisseurs"
  [scrollHeight]="utils.scrollHeightContent(400)"
  styleClass="p-datatable-sm"
  [scrollable]="true"
  #dt
>

  <!--CAPTION-->
  <ng-template pTemplate="caption">
    <div class="row">
      <div class="col-md-6 text-left font-12">
        <span class="mg-r-5">PLANNING FOURNISSEURS, </span>
        <span class="mg-r-5"
              *ngIf="!utils.isCollectionNullOrEmpty(secteursFournisseurs)">{{secteursFournisseurs.length}}
          fournisseur(s)</span>

      </div>
      <div class="col-md-6 text-right font-12">
        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
        <input pInputText type="text" (input)="dt.filter($event.target.value, 'fournisseurLibelle','contains')">
      </div>

    </div>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <tr>
      <th [style.width.px]="50">Actions</th>
      <th [style.width.px]="250">Secteur Fournisseur</th>
      <th [style.width.px]="100">Code Client</th>
      <th [style.width.px]="100">Délai de livraison</th>
      <th [style.width.px]="100">Heure Limite</th>
      <th [style.width.px]="100" *ngFor="let jourSemaine of joursSemaine">
        {{jourSemaine.viewValue}}
      </th>
    </tr>

  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td [style.width.px]="50">
        <yo-button iconClass="fas fa-clipboard"
                   buttonClass="cta-inner-secondary" (onClick)="openDialogRemarqueCommande(rowData)"
                   tooltip="Ajouter une remarque permanent pour vos commandes">
        </yo-button>
      </td>
      <td [style.width.px]="250">
        <span [title]="getSecteurFournisseurInfoLivraison(rowData)"
              class="cursor">
            <yo-site [siteId]="rowData.secteur.site.id"></yo-site>
            <span class="mg-r-5">{{rowData.fournisseurLibelle}}</span>
            <span class="mg-r-5 genlabel">{{rowData.secteur.libelle}}</span>
        </span>
        <i class="fas fa-exclamation-triangle danger-color space cursor"
           *ngIf="!secteursFournisseurOfUDPSvc.secteurFournisseurActif(rowData)"
           [title]="getTooltipWarningFilialeInactive(rowData)">
        </i>
      </td>
      <td [style.width.px]="100">
        <input [style.width.px]="80"
               (ngModelChange)="onChangeCodeClient(rowData,$event)"
               [ngModel]="getCodeClient(rowData)"
               [disabled]="!this.gds.canModify(uniteDeProduction)">
      </td>
      <td [style.width.px]="100">

        <ng-container
          *ngTemplateOutlet="tplDelaiLivraison;context:{rowData:rowData,delaiLivraison:getDelaiLivraison(rowData)}"></ng-container>

      </td>

      <td [style.width.px]="100">
        <p-calendar
          firstDayOfWeek="1"
          appendTo="body"
          id="timeCalendar"
          [locale]="fr"
          [style.width.px]="80"
          [ngModel]="getHeureLimite(rowData)"
          (ngModelChange)="onChangeHeureLimite(rowData,$event)"
          [timeOnly]="true"></p-calendar>
      </td>
      <td [style.width.px]="100" *ngFor="let jourSemaine of joursSemaine" class="colJour">
        <p-checkbox
          [ngModel]="getAffectationValue(rowData,jourSemaine)"
          (onChange)="onChangeAffectation(rowData,jourSemaine, $event)"
          [disabled]="!this.gds.canModify(uniteDeProduction)"
          [binary]="true">
        </p-checkbox>

      </td>
    </tr>
  </ng-template>

</p-table>


<ng-template #tplDelaiLivraison let-rowData="rowData" let-delaiLivraison="delaiLivraison">

  <p title="Délai de livraison en jour" class="cursorHelp mg-b-10 " >
    <span *ngIf="delaiLivraison===0">Jour même</span>
    <span *ngIf="delaiLivraison===1">{{delaiLivraison}} Jour</span>
    <span *ngIf="delaiLivraison > 1">{{delaiLivraison}} Jours</span>
  </p>
  <p-slider [ngModel]="delaiLivraison" [min]="0" [max]="30"
            (ngModelChange)="onChangeDelaiLivraison(rowData,$event)"></p-slider>

</ng-template>


<!--DIALOG COMMANDE REMARQUE-->
<yo-dialog-remarque-commande
  [displayDialog]="displayDialogRemarqueCommande"
  (onClose)="onCloseDialogRemarqueCommande()"
>
</yo-dialog-remarque-commande>
