import {Component, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Router} from '@angular/router';

@Component({
  selector: 'yo-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.scss']
})
export class UtilisateursComponent implements OnInit {


  @ViewChild('grilleutilisateurs') grilleutilisateurs;

  constructor(private router: Router, private utils: UtilsService) { }


  ngOnInit() {
    this.utils.setTitle('Gestion des Utilisateurs');
  }

  ngAfterViewInit(): void {

  }


}
