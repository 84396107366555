import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import * as moment from 'moment';
import {CatalogueAchatDTO} from '../../../core/dtos/catalogue-achat-dto';
import {find as _find} from 'lodash'
import {BoncfDetailService} from '../../../core/services/entities/boncf-detail.service';
import {catchError, switchMap} from 'rxjs/operators';
import {saveAs as fs_saveAs} from 'file-saver';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {combineLatest} from 'rxjs';
import {confirm} from "devextreme/ui/dialog"
import {DxDataGridComponent} from "devextreme-angular";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-bc-edit-fournisseur',
  templateUrl: './bc-edit-fournisseur.component.html',
  styleUrls: ['./bc-edit-fournisseur.component.scss']
})
export class BcEditFournisseurComponent implements OnInit {

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<BonCfDTO[]>();

  @Input() displayDialog: boolean;
  @Input() bonCfs: BonCfDTO[];
  dateLivCalendar: Date;
  // locale pour le calendrier
  fr: any;
  totalLignesBonCf: number;
  dialogTitle: string;

  onModif: boolean = false;

  // Date minimale pour le jour de livraison
  minDate: Date = new Date();

  //udp secteur fournisseur
  joursSemaineFournisseur: number[] = [];
  delaiLivraison = 0;
  heureLimite: Date = new Date();
  // jours disponibles dans l'unite de production
  udpJoursLivraison: number[] = [];

  tva: number;
  fraisDeLivraison: number;

  _lignesBonCf: BonCfDetailDTO[];

  @ViewChild("grid") grid: DxDataGridComponent;

  selectedRows: number[] = [];
  allMode: string;
  checkBoxesMode: string;

  displayDialogEditDeliveryDate: boolean = false;

  constructor(public utils: UtilsService,
              private commandesSvc: CommandesService,
              public auth2Svc: Auth2Service,
              private bonCfDetailSvc: BoncfDetailService,
              private gds: GenericDatagridService,
              public bonCfSvc: BoncfService,
              public domSanitizer: DomSanitizer,
              private udpSvc: UnitesDeProductionService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }


  ngOnInit() {
    this.fr = DATEPICKER_FR;
  }


  @Input('lignesBonCf') set lignesBonCf(value: BonCfDetailDTO[]) {
    this._lignesBonCf = value;
    this.totalLignesBonCf = 0;

    if (!this.utils.isCollectionNullOrEmpty(value)) {
      this.totalLignesBonCf = value.length;
      this._lignesBonCf = this.sortBonCfDetailsByDescription(this._lignesBonCf);
      this.fraisDeLivraison = this.getFrancoDePort(this._bonCf, this._lignesBonCf);
      this.getTva(this._lignesBonCf);
    } else {
      this._lignesBonCf = [];
      this.tva = 0;
    }
  }

  _bonCf: BonCfDTO;

  @Input('bonCf') set bonCf(value: BonCfDTO) {
    this._bonCf = value;
    this.dialogTitle = this.commandesSvc.getCfTitle(value);

    if (!this.utils.isNullOrEmpty(this._bonCf)) {
      this.dateLivCalendar = moment(this._bonCf.dateLivraisonAjustee).toDate();
      this.heureLimite = new Date();
      this.delaiLivraison = 0;

      const udp$ = this.gds.getOne(this.udpSvc.getEntityName(), this._bonCf.uniteDeProductionId);
      const udpSf$ = this.udpSvc.getUniteDeProductionSecteurFournisseur(this._bonCf.uniteDeProductionId, this._bonCf.secteurFournisseurId);
      const all$ = combineLatest([udp$, udpSf$]);

      all$.pipe(
        switchMap(response => {
          const udp = response[0].one;
          const udpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO = response[1].one;

          // recuperer les jours de livraison de l'unite de prod
          this.udpJoursLivraison = this.udpSvc.getJoursLivraisonOfUdp(udp);
          this.delaiLivraison = udpSecteurFournisseur.delaiLivraisonJours;
          this.heureLimite = this.utils.convertNumberDateToDate(udpSecteurFournisseur.delaiLivraisonHeureLimite);

          return this.udpSvc.getLivraisons(udpSecteurFournisseur);
        }),
        catchError(err => this.utils.handleError(err))
      ).subscribe(response => {
        let days = response.resultList;
        if (this.utils.isCollectionNullOrEmpty(days)) {
          days = [];
        }
        this.joursSemaineFournisseur = days.map(liv => liv.numeroJourSemaine);
      });

      if (!this.utils.isNullOrEmpty(this._bonCf) && !this.utils.isNullOrEmpty(this._bonCf.uniteDeProductionId) && !this.utils.isNullOrEmpty(this._bonCf.secteurFournisseurId))
        this.udpSvc.getUniteDeProductionSecteurFournisseur(this._bonCf.uniteDeProductionId, this._bonCf.secteurFournisseurId).pipe(
          switchMap(response => {
            const udpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO = response.one;
            this.delaiLivraison = udpSecteurFournisseur.delaiLivraisonJours;
            this.heureLimite = this.utils.convertNumberDateToDate(udpSecteurFournisseur.delaiLivraisonHeureLimite);

            return this.udpSvc.getLivraisons(udpSecteurFournisseur);
          }),
          catchError(err => this.utils.handleError(err))
        ).subscribe(response => {
          let days = response.resultList;
          if (this.utils.isCollectionNullOrEmpty(days)) {
            days = [];
          }
          this.joursSemaineFournisseur = days.map(liv => liv.numeroJourSemaine);
        });
    }
  }

  focusOnCell = (rowIndex, columnIndex): void => {
    const element = this.grid.instance.getCellElement(rowIndex, columnIndex);
    this.grid.instance.focus(element);
  }

  onEditorPreparing = ($event: any): void => {
    if ($event.parentType === "dataRow") {
      if ($event.dataField === "prixUnitaire") {
        $event.editorOptions.onKeyDown = (arg) => {
          const eventKeyDown = arg.event.originalEvent;
          this.keydownPrixUnitaire(eventKeyDown, $event, $event.row.data);
        }
        this.computeMontantHTPrixUnitaire($event.row.data.prixUnitaire, $event.row.data)
      } else if ($event.dataField === "quantiteACommanderAjustee") {
        $event.editorOptions.onKeyDown = (arg) => {
          const eventKeyDown = arg.event.originalEvent;
          this.keydownQuantiteCommandeeAjustee(eventKeyDown, $event, $event.row.data);
        }
        this.computeMontantHT($event.row.data.quantiteACommanderAjustee, $event.row.data);
      }
    }
  };

  changePrixUnitaire = (newData, newPrice, currentRowData): void => {
    const idx: number = this._lignesBonCf.findIndex(p => p.idCatalogueAchat === currentRowData.idCatalogueAchat);
    if (idx >= 0) {
      this._lignesBonCf[idx].prixUnitaire = parseFloat(newPrice);
      this.computeMontantHTPrixUnitaire(newPrice, currentRowData);
    }
  };

  changeQteAjustee = (newData, newQte, currentRowData): void => {
    const idx: number = this._lignesBonCf.findIndex(p => p.idCatalogueAchat === currentRowData.idCatalogueAchat);
    if (idx >= 0) {
      if (!newQte) newQte = 0;
      this._lignesBonCf[idx].quantiteACommanderAjustee = parseFloat(newQte);
      this.computeMontantHT(newQte, currentRowData);
    }
  }

  changeRemarque = (newData, newText, currentRowData): void => {
    const idx: number = this._lignesBonCf.findIndex(p => p.idCatalogueAchat === currentRowData.idCatalogueAchat);
    if (idx >= 0)
      this._lignesBonCf[idx].commentaire = newText;
  }

  checkEditionMode = (): void => {
    this.onModif = this._bonCf.commentaire ? true : false;
  }

  onClosingByCrossIcon = async ($event): Promise<void> => {
    if (this.onModif)
      $event.cancel = true;
    this.closeDialog();
  }

  closeDialog: () => void = async () => {
    if (this.onModif) {
      let res = await confirm('Êtes vous sûr de vouloir fermer sans enregistrer vos modifications ?', "Quitter l'édition");
      if (res) {
        this.onModif = false;
        this.displayDialog = false;
        this.onClose.emit(this.displayDialog);
      }
    } else {
      this.onModif = false;
      this.displayDialog = false;
      this.onClose.emit(this.displayDialog);
    }
  };

  showDialogAddArticles = (): void => {
    this.bonCfDetailSvc.announceAvailableArticles(this._bonCf);
  };

  sauverBonCommandeObservable = () => {
    this._bonCf.dateLivraisonAjustee = this.dateLivCalendar.getTime();
    return this.commandesSvc.saveBonCf(this._bonCf, this._lignesBonCf);
  };

  /**
   * Enregistrer la proposition de commande
   */
  sauverBonCommande = (): void => {

    this.sauverBonCommandeObservable()
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {

          this._bonCf = response.one;

          // on rafraichit les lignes de la proposition avec les prix nouvellement calculés
          this._lignesBonCf = response.additionalProperties['lignes'] as BonCfDetailDTO[];
          this._lignesBonCf = this.sortBonCfDetailsByDescription(this._lignesBonCf);

          this.grid.instance.refresh();
          this.onSave.emit(this.bonCfs);

          const summary = `Proposition de commande "${this._bonCf.numeroBcf}" enregistrée !!`;
          const detail = 'Prix, quantité à commander, quantité à facturer recalculés';

          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, summary);

          this.onModif = false;
          this.closeDialog();
        }
      });
  };

  /**
   * Classement d'un tableau de {@link BonCfDetailDTO} en fonction du champ {@link BonCfDetailDTO.description} (mode ascendant).
   * @param bonCfDetailDTOArray
   */
  sortBonCfDetailsByDescription = (bonCfDetailDTOArray: BonCfDetailDTO[]): BonCfDetailDTO[] => {
    let sortedArray: BonCfDetailDTO[] = [];
    if (!this.utils.isCollectionNullOrEmpty(bonCfDetailDTOArray)) {
      sortedArray = bonCfDetailDTOArray.sort((bonCfDetail1, bonCfDetail2) => {
        if (bonCfDetail1.description.toLowerCase() > bonCfDetail2.description.toLowerCase()) {
          return 1;
        } else if (bonCfDetail1.description.toLowerCase() < bonCfDetail2.description.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    return sortedArray;
  };

  addArticles = ($event: CatalogueAchatDTO[]): void => {
    this.onModif = true;

    if (this.utils.isCollectionNullOrEmpty(this._lignesBonCf)) {
      this._lignesBonCf = [];
    }

    // on verifie que les nouveaux articles ne soient pas déjà dans la commande
    let articlesExists: BonCfDetailDTO[] = [];
    for (let article of $event) {

      const articleExists = _find(this._lignesBonCf, {'idCatalogueAchat': article.id}) as BonCfDetailDTO;

      if (articleExists) {
        articlesExists.push(articleExists);
      } else {
        // on mappe l'article en bonCfDetail et on l'ajoute à la proposition de commande
        const ligneBonCfDetail = this.bonCfDetailSvc.createEmptyDTOForPropositionCommande(article, this._bonCf);
        this._lignesBonCf.push(ligneBonCfDetail);
      }
      this._lignesBonCf = this.sortBonCfDetailsByDescription(this._lignesBonCf);
      this.totalLignesBonCf = this._lignesBonCf.length;
      this.fraisDeLivraison = this.getFrancoDePort(this._bonCf, this._lignesBonCf);
      this._bonCf.montantHT = this.bonCfDetailSvc.computeTotalFacture(this._bonCf, this._lignesBonCf);
      this.getTva(this._lignesBonCf);
    }

    if (!this.utils.isCollectionNullOrEmpty(articlesExists)) {
      const summary = articlesExists.length > 1 ? `Les articles ${articlesExists.map(lb => lb.description).join(', ')} sont déjà dans la commande.` : `L'article ${articlesExists[0].description} existe déjà dans la commande.`;
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, summary);
    }
  };

  /**
   * Classement d'un tableau de {@link CatalogueAchatDTO}s en fonction du champ {@link CatalogueAchatDTO.produitArticle.libelle} (mode ascendant).
   * @param catalogueAchatDTOArray
   */
  sortCatalogueAchatByProduitArticleLibelle = (catalogueAchatDTOArray: CatalogueAchatDTO[]): CatalogueAchatDTO[] => {
    let sortedArray: CatalogueAchatDTO[] = [];
    if (!this.utils.isCollectionNullOrEmpty(catalogueAchatDTOArray)) {
      sortedArray = catalogueAchatDTOArray.sort((catalogueAchat1, catalogueAchat2) => {
        if (catalogueAchat1.produitArticle.libelle.toLowerCase() > catalogueAchat2.produitArticle.libelle.toLowerCase()) {
          return 1;
        } else if (catalogueAchat1.produitArticle.libelle.toLowerCase() < catalogueAchat2.produitArticle.libelle.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return sortedArray;
  };

  removeLignesCommande = (): void => {
    const bonCfDetails: BonCfDetailDTO[] = this.selectedRows.map(id => this._lignesBonCf.find(l => l.idCatalogueAchat === id));
    if (!this.utils.isCollectionNullOrEmpty(this._lignesBonCf) && !this.utils.isCollectionNullOrEmpty(bonCfDetails)) {
      this.onModif = true;
      const bcfds = [];

      for (const bonCfDetailDTO of this._lignesBonCf) {
        let found = false;

        for (const bcfdToDel of bonCfDetails) {
          if (bonCfDetailDTO.idCatalogueAchat === bcfdToDel.idCatalogueAchat) {
            found = true;
            break;
          }
        }
        if (!found) {
          bcfds.push(bonCfDetailDTO);
        }
      }
      this._lignesBonCf = bcfds;
      // recalculer le montant HT qui doit prendre en compte le franco de port
      this._bonCf.montantHT = this.bonCfDetailSvc.computeTotalFacture(this._bonCf, this._lignesBonCf);
      // recalculer la tva
      this.getTva(this._lignesBonCf);
      // mettre à jour le nombre lignes dans la grille
      this.totalLignesBonCf = this._lignesBonCf.length;
    }
  };

  imprimerBonDeCommande = (): void => {
    let bonsCfAImprimer: BonCfDTO[] = [];
    bonsCfAImprimer.push(this._bonCf);
    this.sauverBonCommandeObservable().pipe(
      switchMap(response => {
        return this.commandesSvc.printPDFCommandes(bonsCfAImprimer);
      })
    ).subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, 'propositions.pdf');
    });
  };

  getNbCaracteresCommentaire: () => number = (): number => {
    return !this.utils.isNullOrEmpty(this._bonCf.commentaire) ? this._bonCf.commentaire.length : 0;
  };

  getStyleRemarque = () => this.domSanitizer.bypassSecurityTrustStyle(this.getNbCaracteresCommentaire() > 5000 ? 'color: red !important;' : 'color: black !important;');

  /**
   * Calculer le prix total d'un article, la qté facturée et le montant HT d'une commande lorsqu'on modifie une quantite à commander
   * @param $event quantite à commander modifiée
   * @param bonCfDetail
   */
  computeMontantHT = (qte: any, bonCfDetail: BonCfDetailDTO): void => {
    this.onModif = true;
    qte = parseFloat(qte);

    bonCfDetail.quantiteACommanderAjustee = qte;
    bonCfDetail.quantiteAFacturer = this.utils.convertQuantiteUdm1ToQuantiteUdm2(qte, bonCfDetail.ratioUC, bonCfDetail.ratioUF);
    bonCfDetail.prixTotal = bonCfDetail.prixUnitaire * bonCfDetail.quantiteAFacturer;

    // montant HT doit aussi prendre en compte le franco de port
    this._bonCf.montantHT = this.bonCfDetailSvc.computeTotalFacture(this._bonCf, this._lignesBonCf);
    this.getTva(this._lignesBonCf);
  };

  /**
   * Calculer le prix total d'un article et le montant HT d'une commande lorsqu'on modifie le prix unitaire
   * @param $event prix unitaire modifiée
   * @param bonCfDetail
   */
  computeMontantHTPrixUnitaire = (prix: any, bonCfDetail: BonCfDetailDTO): void => {

    this.onModif = true;
    prix = parseFloat(prix);

    bonCfDetail.prixUnitaire = prix;
    bonCfDetail.prixTotal = bonCfDetail.prixUnitaire * bonCfDetail.quantiteAFacturer;

    // montant HT doit aussi prendre en compte le franco de port
    this._bonCf.montantHT = this.bonCfDetailSvc.computeTotalFacture(this._bonCf, this._lignesBonCf);
    this.getTva(this._lignesBonCf);
  };


  /**
   * Récupérer le franco de port
   * @param bonCf
   * @param lignes
   */
  getFrancoDePort = (bonCf: BonCfDTO, lignes: BonCfDetailDTO[]): number => this.bonCfDetailSvc.computeFrancoDePort(bonCf, lignes);

  getTooltipFraisDePort = (bonCf: BonCfDTO): string => {

    if (this._bonCf.fournisseurFdpPoids > 0 && this._bonCf.fournisseurFdpMontant > 0) {
      return `Attention, le minimum de commande (${bonCf.fournisseurFdpMontant} €) ou de poids (${bonCf.fournisseurFdpPoids} Kg) n'est pas atteint, des frais de port vont être appliqués !`;
    } else if (this._bonCf.fournisseurFdpMontant > 0) {
      return `Attention, le minimum de commande (${bonCf.fournisseurFdpMontant} €)  n'est pas atteint, des frais de port vont être appliqués !`;
    } else if (this._bonCf.fournisseurFdpPoids > 0) {
      return `Attention, le minimum de poids (${bonCf.fournisseurFdpPoids} Kg) n'est pas atteint, des frais de port vont être appliqués !`;
    }
    return '';
  };

  updateQuantiteCommandeeAjustee = (operator: string, bonCfDetail: BonCfDetailDTO): void => {
    let floatQca = parseFloat(bonCfDetail.quantiteACommanderAjustee.toString());
    let diff = 0;

    if (floatQca % 1 === 0) {
      diff = 1;
    }

    if (operator === '+') {
      bonCfDetail.quantiteACommanderAjustee = Math.ceil(floatQca) + diff
    } else {
      if (bonCfDetail.quantiteACommanderAjustee >= 1) {
        bonCfDetail.quantiteACommanderAjustee = Math.floor(floatQca) - diff;
      }
      // ne peut être  inférieur à 0
      else {
        bonCfDetail.quantiteACommanderAjustee = 0;
      }
    }
    this.computeMontantHT(bonCfDetail.quantiteACommanderAjustee, bonCfDetail);
  };

  keydownQuantiteCommandeeAjustee = ($eventKeyboard: KeyboardEvent, $e: any, bonCfDetail: BonCfDetailDTO): void => {
    const key = $eventKeyboard.key;

    if (key === 'ArrowUp' || key === 'ArrowRight') {
      this.updateQuantiteCommandeeAjustee('+', bonCfDetail);
    } else if (key === 'ArrowDown' || key === 'ArrowLeft') {
      this.updateQuantiteCommandeeAjustee('-', bonCfDetail);
    }
    if (key === 'ArrowUp' || key === 'ArrowRight' || key === 'ArrowDown' || key === 'ArrowLeft')
      this.focusOnCell($e.rowIndex, $e.index);
  };

  updatePrixUnitaire = (operator: string, bonCfDetail: BonCfDetailDTO): void => {
    let floatQca = parseFloat(bonCfDetail.prixUnitaire.toString());
    if (operator === '+') {
      bonCfDetail.prixUnitaire = floatQca + 1;
    } else {
      if (bonCfDetail.prixUnitaire >= 1) {
        bonCfDetail.prixUnitaire = floatQca - 1;
      }
      // ne peut être  inférieur à 0
      else {
        bonCfDetail.prixUnitaire = 0;
      }
    }
    this.computeMontantHTPrixUnitaire(bonCfDetail.prixUnitaire, bonCfDetail);
  };

  keydownPrixUnitaire = ($eventKeyboard: KeyboardEvent, $e: any, bonCfDetail: BonCfDetailDTO): void => {
    const key = $eventKeyboard.key;
    if (key === 'ArrowUp' || key === 'ArrowRight') {
      this.updatePrixUnitaire('+', bonCfDetail);
    } else if (key === 'ArrowDown' || key === 'ArrowLeft') {
      this.updatePrixUnitaire('-', bonCfDetail);
    }
    if (key === 'ArrowUp' || key === 'ArrowRight' || key === 'ArrowDown' || key === 'ArrowLeft')
      this.focusOnCell($e.rowIndex, $e.index);
  };

  getTva = (lignesBonCf: BonCfDetailDTO[]): void => {
    this.tva = 0;
    lignesBonCf.forEach((lbc: BonCfDetailDTO) => {
      this.tva += (lbc.prixTotal * lbc.tvaArticle) / 100;
    });
    this.tva += (this.fraisDeLivraison * this._bonCf.fournisseurFdpTva) / 100;
  };

  updateDeliveryDate = () => {
    this.displayDialogEditDeliveryDate = true;
  };

  closeDialogEditDeliveryDate = ($event: any) => {
    this.displayDialogEditDeliveryDate = $event.displayDialog;
    this._bonCf = $event.bonCf;
  }
}
