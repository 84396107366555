<p-dialog
  [dismissableMask]="false"

  (onHide)="closeDialog()"
  positionTop="100"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'min-height':'800px', 'max-height':'800px', 'width':'1300px'}"
>


  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-cogs mg-r-5"></i> Duplication des menus
    </div>
  </ng-template>

  <!--  CONTENT-->
  <ng-container>

    <div style="min-height:600px;max-height: 900px;">

      <!--  VISUALISER LES ETAPES-->
      <div class="row">
        <div class="col-md-12">
          <p-steps [model]="steps" [(activeIndex)]="activeStep" [readonly]="true"></p-steps>
        </div>
      </div>


      <!--    ETAPE DE SELECTION DES PRESTATIONS-->
      <ng-container *ngIf="activeStep=== STEP_DUPLICATION.SELECTION_PRESTATION">
        <div>

          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-8">
                  <h6 class="first font-14">Choisir une prestation à dupliquer</h6>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <h6 class="first font-14">Sélectionner les prestations cibles</h6>

            </div>
          </div>
          <div class="row">

            <!--        SOURCE-->
            <div class="col-md-6">
              <p-table [columns]="cols"
                       [value]="sourceCmcCaList"
                       [scrollHeight]="'400px'"
                       styleClass="p-datatable-sm"
                       [scrollable]="true"
                       selectionMode="single"
                       [(selection)]="selectedSourceCmcCa"
                       dataKey="id"
                       #dtSource>

                <!--  HEADER-->
                <ng-template pTemplate="header" let-columns>
                  <tr>

                    <ng-container *ngFor="let col of columns">

                      <ng-container [ngSwitch]="col.field">

                        <ng-container *ngSwitchCase="'cmSiteLibelle'">
                          <th [style.width.px]="CELL_WIDTH_SITE">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmLibelle'">
                          <th [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmcLibelle'">
                          <th [style.width.px]="CELL_WIDTH_PRESTATION">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'regimeLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            {{col.header}}
                          </th>
                        </ng-container>

                      </ng-container>

                    </ng-container>

                  </tr>

                  <tr>

                    <ng-container *ngFor="let col of columns">

                      <ng-container [ngSwitch]="col.field">

                        <ng-container *ngSwitchCase="'cmSiteLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtSource.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmLibelle'">
                          <th [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtSource.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmcLibelle'">
                          <th [style.width.px]="CELL_WIDTH_PRESTATION">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtSource.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'regimeLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtSource.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                      </ng-container>

                    </ng-container>
                  </tr>

                </ng-template>

                <!--  BODY-->
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

                      <ng-container *ngSwitchCase="'cmSiteLibelle'">
                        <td [style.width.px]="CELL_WIDTH_SITE">
                          <ng-container *ngTemplateOutlet="tplCellSite;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'cmLibelle'">
                        <td [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                          <ng-container
                            *ngTemplateOutlet="tplCellOffreAlimentaire;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'cmcLibelle'">
                        <td [style.width.px]="CELL_WIDTH_PRESTATION">
                          <ng-container *ngTemplateOutlet="tplCellPrestation;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'regimeLibelle'">
                        <td [style.width.px]="CELL_WIDTH_REGIME">
                          <ng-container *ngTemplateOutlet="tplCellRegime;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                    </ng-container>
                  </tr>
                </ng-template>


              </p-table>

            </div>

            <!--        CIBLE-->
            <div class="col-md-6">
              <p-table [columns]="cols"
                       [value]="targetCmcCaList"
                       [scrollHeight]="'400px'"
                       styleClass="p-datatable-sm"
                       [scrollable]="true"
                       selectionMode="multiple"
                       [(selection)]="selectedTargetCmcCaList"
                       #dtTarget
              >

                <!--  HEADER-->
                <ng-template pTemplate="header" let-columns>
                  <tr>

                    <ng-container *ngFor="let col of columns">


                      <ng-container [ngSwitch]="col.field">

                        <ng-container *ngSwitchCase="'cmSiteLibelle'">
                          <th [style.width.px]="CELL_WIDTH_SITE">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmLibelle'">
                          <th [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmcLibelle'">
                          <th [style.width.px]="CELL_WIDTH_PRESTATION">
                            {{col.header}}
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'regimeLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            {{col.header}}
                          </th>
                        </ng-container>

                      </ng-container>

                    </ng-container>

                  </tr>

                  <tr>

                    <ng-container *ngFor="let col of columns">

                      <ng-container [ngSwitch]="col.field">

                        <ng-container *ngSwitchCase="'cmSiteLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtTarget.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmLibelle'">
                          <th [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtTarget.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'cmcLibelle'">
                          <th [style.width.px]="CELL_WIDTH_PRESTATION">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtTarget.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                        <ng-container *ngSwitchCase="'regimeLibelle'">
                          <th [style.width.px]="CELL_WIDTH_REGIME">
                            <input pInputText style="width:95%;" type="text"
                                   (input)="dtTarget.filter($event.target.value, col.field, 'contains')">
                          </th>
                        </ng-container>

                      </ng-container>

                    </ng-container>
                  </tr>

                </ng-template>

                <!--  BODY-->
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                  <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

                      <ng-container *ngSwitchCase="'cmSiteLibelle'">
                        <td [style.width.px]="CELL_WIDTH_SITE">
                          <ng-container *ngTemplateOutlet="tplCellSite;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'cmLibelle'">
                        <td [style.width.px]="CELL_WIDTH_OFFRE_ALIM">
                          <ng-container
                            *ngTemplateOutlet="tplCellOffreAlimentaire;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'cmcLibelle'">
                        <td [style.width.px]="CELL_WIDTH_PRESTATION">
                          <ng-container *ngTemplateOutlet="tplCellPrestation;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'regimeLibelle'">
                        <td [style.width.px]="CELL_WIDTH_REGIME">
                          <ng-container *ngTemplateOutlet="tplCellRegime;context:{rowData:rowData}"></ng-container>
                        </td>
                      </ng-container>

                    </ng-container>
                  </tr>
                </ng-template>

              </p-table>
            </div>
          </div>
          <div class="row mg-t-20">
            <div class="col-md-auto">
              <ng-container *ngTemplateOutlet="tplErrorList;context:{errorList:errorStepPrestationList}"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <!--    ETAPE DE SELECTION DE LA PERIODE-->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PERIODE">
        <div>

          <div class="row">
            <!--          RECAPITULATIF DE LA SELECTION DES PRESTATIONS-->
            <div class="col-md-6">
              <ng-container *ngTemplateOutlet="tplRecapSelectionPrestations"></ng-container>
            </div>

            <!--          PERIODES-->
            <div class="col-md-6">
              <div class="row">

                <div class="col-md-12">
                  <h6 class="first font-14">Période de la prestation à dupliquer</h6>
                  <div class="p-g p-fluid">
                    <div class="p-g-12 p-md-6">
                      <p-calendar
                        dateFormat="dd/mm/yy"


                        selectionMode="range"
                        [readonlyInput]="true"
                        [numberOfMonths]="2"
                        firstDayOfWeek="1"
                        [(ngModel)]="selectedRangeSourceDates"
                        (onSelect)="changeSelectedRangeSourceDates($event)"
                        (onFocus)="getMonthMenusCompoDates($event)"
                        (onMonthChange)="getMonthMenusCompoDates($event)"
                        (onYearChange)="getMonthMenusCompoDates($event)"
                        [disabledDays]="sourceDisabledDays"
                        [locale]="localeFr"
                        [showIcon]="true"
                        [showWeek]="true">
                        <ng-template pTemplate="date" let-date>
                          <yo-date-dispo [date]="date"
                                         [dateSaisieEffectif]="utils.isDateInList(date,sourceDatesSaisieEffectifs)"
                                         [dateCreationMenu]="utils.isDateInList(date,sourceDatesCreationMenus)"></yo-date-dispo>
                        </ng-template>
                      </p-calendar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mg-t-20">
                <div class="col-md-12">
                  <h6 class="first font-14">Jour de début sur les prestations cibles</h6>
                  <div class="p-g p-fluid">
                    <div class="p-g-12 p-md-6">
                      <p-calendar
                        dateFormat="dd/mm/yy"


                        [readonlyInput]="true"
                        [numberOfMonths]="2"
                        firstDayOfWeek="1"
                        [(ngModel)]="selectedDateDebutTarget"
                        [locale]="localeFr"
                        [disabledDays]="disabledDateDebutDays"
                        [showIcon]="true"
                        [showWeek]="true">
                      </p-calendar>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row mg-t-20">
            <div class="col-md-auto">
              <ng-container *ngTemplateOutlet="tplErrorList;context:{errorList:errorStepPeriodeList}"></ng-container>
            </div>
          </div>

        </div>

      </ng-container>

      <!--    ETAPE RECAPITULATIF-->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.RECAPITULATIF">
        <div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <ng-container *ngTemplateOutlet="tplRecapSelectionPrestations"></ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="first font-14">Période de la prestation à dupliquer</h6>
                      <div>
                        {{duplicationMenuService.getPeriodeADupliquerLabel(selectedRangeSourceDates)}}
                      </div>
                    </div>
                  </div>
                  <div class="row mg-t-20 ">
                    <div class="col-md-12">
                      <h6 class="first font-14">Jour de début sur les prestations cibles</h6>
                      <div>
                        {{duplicationMenuService.getJourDebutPrestationCibles(selectedDateDebutTarget)}}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column ">
                    <h6 class="first font-14 mg-t-20">Écraser les menus</h6>

                    <div class="d-flex flex-row" style="height:60px;">
                      <p-selectButton [options]="optionsEcraserMenu" [(ngModel)]="optionEcraserMenu">
                      </p-selectButton>
                      <div class="col font-12">
                          <span *ngIf="optionEcraserMenu === DME.AUCUN">
                                Ne recopier les plats sources que lorsque l'emplacement est vide dans les prestations cibles.
                          </span>
                        <span *ngIf="optionEcraserMenu === DME.ECRASER_SI_PLAT_EXISTANT">
                                Si le plat cible existe déjà, il sera écrasé !
                          </span>
                        <span *ngIf="optionEcraserMenu === DME.ECRASER_TOUT">
                                          Enlever tous les plats de la période cible et recopier les plats sources !!!
                          </span>
                      </div>
                    </div>


                    <h6 class="first font-14 mg-t-20">Régime cible non trouvé</h6>
                    <p-checkbox [(ngModel)]="optionRegimeSource"
                                label="Recopier le plat du régime source"
                                binary="true"></p-checkbox>

                    <h6 class="first font-14 mg-t-20">Déclinaison cible non trouvée</h6>
                    <p-checkbox [(ngModel)]="optionDeclinaisonSource"
                                label="Recopier le plat de la déclinaison source"
                                binary="true"></p-checkbox>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!--      ETAPE DE RAPPORT DE LOGS-->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.RAPPORT">
        <div>
          <ng-container
            *ngTemplateOutlet="tplRapportDuplication;context:{rapportErreursDuplicationMenu:rapportErreursDuplicationMenu}"></ng-container>
        </div>
      </ng-container>

    </div>
  </ng-container>

  <ng-template pTemplate="footer">

    <span class="mg-r-5">



      <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PRESTATION">
<!--      bouton visibles à l'etape selection des prestations-->
      <yo-button
        iconClass="fas fa-chevron-right"
        (onClick)="validateStepPrestation(STEP_DUPLICATION.SELECTION_PERIODE)"
        tooltip="Sélectionner la période"
        label="Suivant"
      ></yo-button>
        </ng-container>

      <!--      bouton visibles à l'etape selection des périodes-->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.SELECTION_PERIODE">

        <yo-button
          iconClass="fas fa-chevron-left"
          (onClick)="activeStep=STEP_DUPLICATION.SELECTION_PRESTATION"
          tooltip="Sélection des prestations"
          label="Précédent">
        </yo-button>

        <yo-button
          iconClass="fas fa-chevron-right"
          (onClick)="validateStepPeriode(STEP_DUPLICATION.RECAPITULATIF)"
          tooltip="Options de recopie "
          label="Suivant">
        </yo-button>

      </ng-container>

      <!--      bouton visibles à l'etape de récapitulatif -->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.RECAPITULATIF">

        <yo-button
          iconClass="fas fa-chevron-left"
          (onClick)="activeStep=STEP_DUPLICATION.SELECTION_PERIODE"
          tooltip="Sélection de la période"
          label="Précédent">
        </yo-button>

        <yo-button iconClass="fas fa-chevron-right"
                   (onClick)="launchDuplicationMenus()"
                   tooltip="Lancer la duplication des menus"
                   [disabled]="duplicationEnCours"
                   label="Suivant">
        </yo-button>

      </ng-container>

      <!--      bouton visibles à l'etape de rapport -->
      <ng-container *ngIf="activeStep===STEP_DUPLICATION.RAPPORT">

        <yo-button iconClass="fas fa-chevron-left"
                   (onClick)="activeStep=STEP_DUPLICATION.RECAPITULATIF"
                   tooltip="Options de recopie"
                   label="Précédent">
        </yo-button>

      </ng-container>


    </span>
    <span class="mg-r-5">
      <yo-button buttonClass="cta-inner-delete"
                 iconClass="fas fa-times"
                 (onClick)="closeDialog()"
                 tooltip="Fermer la fenêtre"
                 label="Fermer"></yo-button>
    </span>


  </ng-template>


</p-dialog>

<!--TEMPLATE CELL REGIME-->
<ng-template #tplCellRegime let-rowData="rowData">
  <div class="no-overflow">
    <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="rowData.referent"
       title="Régime référent"
       ></i>
    <span [title]="rowData.regimeLibelle" >{{rowData.regimeLibelle}}</span>

  </div>

</ng-template>

<!--TEMPLATE CELL SITE-->
<ng-template #tplCellSite let-rowData="rowData">
  <div class="no-overflow">
    <yo-site [siteId]="rowData.cmSiteId" [siteLibelle]="rowData.cmSiteLibelle"
             [siteLibelleTooltip]="rowData.cmSiteLibelle"></yo-site>
  </div>
</ng-template>

<!--TEMPLATE CELL OFFRE ALIMENTAIRE-->
<ng-template #tplCellOffreAlimentaire let-rowData="rowData">
  <div class="no-overflow" [title]="rowData.cmLibelle" >
    {{rowData.cmLibelle}}
  </div>
</ng-template>

<!--TEMPLATE CELL PRESTATION-->
<ng-template #tplCellPrestation let-rowData="rowData">
  <div class="no-overflow" [title]="rowData.cmcLibelle" >
    {{rowData.cmcLibelle}}
  </div>
</ng-template>


<!--TEMPLATE ERRORLIST-->
<ng-template #tplErrorList let-errorList="errorList">
  <div class="row">
    <div class="col-md-auto">
      <ng-container *ngIf="!utils.isCollectionNullOrEmpty(errorList)">
        <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
      </ng-container>
    </div>
    <div class="col">
      <div *ngFor="let error of errorList" class="danger-color">
        {{error}}
      </div>
    </div>
  </div>

</ng-template>

<ng-template #tplRecapSelectionPrestations>

  <div class="row">
    <div class="col-md-12">
      <h6 class="first font-14">Prestation à dupliquer</h6>
      <div class="mg-t-10">
        <yo-site [siteId]="selectedSourceCmcCa?.cmSiteId"
                 [siteLibelle]="selectedSourceCmcCa?.cmSiteLibelle"></yo-site>
        ,
        <span class="mg-r-5" title="Offre alimentaire"
              >{{selectedSourceCmcCa?.cmLibelle}},</span>
        <span class="mg-r-5" title="Prestation" >{{selectedSourceCmcCa?.cmcLibelle}}
          ,</span>

        <span style="white-space: nowrap">
                  <i class="fab fa-first-order fa-xs  prefere-color mg-r-5"
                     *ngIf="selectedSourceCmcCa?.referent"
                     title="Régime référent"
                     ></i>
                  <span class="mg-r-5" title="Régime"
                        >{{selectedSourceCmcCa?.regimeLibelle}}</span>
                  </span>
      </div>
    </div>
  </div>

  <div class="row mg-t-20">
    <div class="col-md-12">
      <h6 class="first font-14">Prestations cible</h6>
      <div style="overflow: auto; max-height: 350px;">
        <div class="mg-t-10" *ngFor="let targeCmcCa of selectedTargetCmcCaList">
          <yo-site [siteId]="targeCmcCa?.cmSiteId"
                   [siteLibelle]="targeCmcCa?.cmSiteLibelle"></yo-site>
          ,

          <span class="mg-r-5" title="Offre alimentaire" >{{targeCmcCa?.cmLibelle}}
            ,</span>
          <span class="mg-r-5" title="Prestation" >{{targeCmcCa?.cmcLibelle}}
            ,</span>

          <span style="white-space: nowrap">
                  <i class="fab fa-first-order fa-xs  prefere-color mg-r-5" *ngIf="targeCmcCa?.referent"
                     title="Régime référent"
                     ></i>
                  <span class="mg-r-5" title="Régime" >{{targeCmcCa?.regimeLibelle}}</span>
                    </span>

        </div>
      </div>
    </div>
  </div>

</ng-template>

<!--TEMPLATE RAPPORT D'ERREUR-->
<ng-template #tplRapportDuplication let-rapportErreursDuplicationMenu="rapportErreursDuplicationMenu">

  <h3 class="first font-12">RÉSULTATS </h3>


  <div class="row">
    <div class="col-md-3">Nombre de plats à dupliquer :</div>
    <div class="col-md-9">{{nbPlatsSources}}</div>
  </div>

  <div class="row">
    <div class="col-md-3">Nombre de plats copiés :</div>
    <div class="col-md-9">{{nbPlatsCopies}}</div>
  </div>


  <ng-container *ngIf="!utils.isCollectionNullOrEmpty(rapportErreursDuplicationMenu)">
    <h3 class="first font-12 mg-t-20">RAPPORT</h3>

    <div class="row" style="max-height: 400px; overflow:auto;">
      <div class="col-md-12">

        <ng-container *ngFor="let rapportPrestation of rapportErreursDuplicationMenu">
          <div class="row mg-t-10">
            <div class="col-md-12">
                    <span class="badge badge-secondary">
                    <yo-site [siteId]="rapportPrestation.siteId"
                             [siteLibelle]="rapportPrestation.siteLibelle"></yo-site>
                    ,
                    <span class="mg-r-5" title="Offre alimentaire"
                          >{{rapportPrestation.offreAlimentaire}}
                      ,</span>
                    <span class="mg-r-5" title="Prestation"
                          >{{rapportPrestation.prestationLibelle}}
                      ,</span>

                    <span style="white-space: nowrap">
                  <i class="fab fa-first-order fa-xs  prefere-color mg-r-5"
                     *ngIf="rapportPrestation.contrainteAlimReferent"
                     title="Régime référent"
                     ></i>
                  <span class="mg-r-5" title="Régime"
                        >{{rapportPrestation.contrainteAlimLibelle}}</span>
                    </span>

                      <span>(Nombre de plats copiés : {{rapportPrestation.nbPlatsCopies}})</span>

                      </span>
            </div>
          </div>

          <!--                  ERREUR DE PERIODE INACTIVE-->
          <ng-container
            *ngFor="let rdPeriodeInactive of rapportPrestation.mapErreurPeriodeInactive | keyvalue; let i=index;let last=last; let first=first;">
            <ng-container *ngIf="first">
              <div style="margin-top:20px; ">
                    <span
                      class="badge badge-light">ERREUR {{rdPeriodeInactive.value.errorCode}}</span> {{rdPeriodeInactive.value.errorDetail}}
              </div>
              <span style="margin-left: 115px;"></span>
            </ng-container>

            <span class="mg-l-5 mg-t-10 font-12">{{getDateFromString(rdPeriodeInactive.key)}}</span>
            <ng-container *ngIf="!last">
              ,<span class="mg-r-5"></span>
            </ng-container>
          </ng-container>


          <!--                  ERREUR DE VALIDATION MENUS-->
          <ng-container
            *ngFor="let rdValidation of rapportPrestation.mapErreurValidation | keyvalue; let i=index;let last=last; let first=first;">
            <ng-container *ngIf="first">
              <div style="margin-top:20px; ">
                    <span
                      class="badge badge-light">ERREUR {{rdValidation.value.errorCode}}</span> {{rdValidation.value.errorDetail}}
              </div>
              <span style="margin-left: 115px;"></span>
            </ng-container>


            <span class="mg-l-5 mg-t-10 font-12">{{getDateFromString(rdValidation.key)}}</span>
            <ng-container *ngIf="!last">
              ,<span class="mg-r-5"></span>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let rdJour of rapportPrestation.mapRapportDuplicationJour | keyvalue">
            <ng-container *ngIf="rdJour.value.inError">
              <div class="row mg-t-10">
                <div class="col-md-12">
                  <span class="badge badge-info">{{getDateFromString(rdJour.value.dateDDMMYYYY)}}</span>
                </div>
              </div>

              <ng-container *ngFor="let rdCmcd of rdJour.value.mRapportDuplicationCmcdList">
                <div style="margin-top:20px; ">

                        <span
                          class="badge badge-light mg-r-5">ERREUR {{rdCmcd.errorCode}}</span>
                  {{rdCmcd.errorDetail}}
                </div>
              </ng-container>

              <ng-container *ngFor="let rdPlat of rdJour.value.mRapportDuplicationPlatList">
                <div style="margin-top:20px; ">

                          <span
                            class="badge badge-light mg-r-5">ERREUR {{rdPlat.errorCode}}</span> {{rdPlat.errorDetail}}
                </div>

              </ng-container>

            </ng-container>
          </ng-container>


        </ng-container>

      </div>

    </div>
  </ng-container>
</ng-template>
