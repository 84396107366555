import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {HttpParams} from "@angular/common/http";
import {TacheDTO} from "../../dtos/tache-dto";

export const URL_GET_ALL = `dolrest/taches/list`;
export const URL_SAVE = `dolrest/taches/save`;
export const URL_DELETE = `dolrest/taches/delete`;

@Injectable({
  providedIn: 'root'
})
export class TachesService {

  private subjectOpenDialogTache = new Subject<TacheDTO>();
  openDialogTache$ = this.subjectOpenDialogTache.asObservable();

  private subjectTacheSaved = new Subject<TacheSavedSupplier>();
  tacheSaved$ = this.subjectTacheSaved.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer toutes les tâches
   * @returns {Observable<TacheDTO>}
   */
  getAll = (): Observable<ResponseWrapper<TacheDTO>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde une tâche
   * @param tâche
   */
  save = (t: TacheDTO): Observable<ResponseWrapper<TacheDTO>> => this.httpSvc.post(URL_SAVE, t);

  announceOpenDialogTache = (param: TacheDTO) => {
    this.subjectOpenDialogTache.next(param);
  };

  announceTacheSaved = (tache: TacheDTO, isUpdate: boolean = false) => {
    this.subjectTacheSaved.next({ isUpdate, tache } as TacheSavedSupplier);
  };

  /**
   * Supprime une ou plusieurs tâche(s) en fonction de
   * leurs identifiants.
   * @param tachesIdsToDelete
   */
  deleteByIds = (tachesIdsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", tachesIdsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

}

export interface TacheSavedSupplier {
  isUpdate: boolean;
  tache: TacheDTO;
}
