import {RouterModule, Routes} from "@angular/router";
import {
  FP_ROUTES, FS_ROUTES,
  HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES
} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {NgModule} from "@angular/core";
import {FacturesFournisseursComponent} from "./fournisseurs/factures-fournisseurs.component";
import {FacturesRootComponent} from "./factures-root.component";

const routes: Routes = [
  {
    path: FP_ROUTES.FACTURES,
    component: FacturesRootComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    data: HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES /* || HAS_ROLE_PLC_FACTURES */,
    children: [
      {
        path: FS_ROUTES.FACTURES_FOURNISSEURS,
        component: FacturesFournisseursComponent,
        runGuardsAndResolvers: 'always',
        data: HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES
      }
    ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacturesRoutingModule {
}
