import {Component, OnInit} from '@angular/core';
import {TextureService} from '../../core/services/entities/texture.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-textures',
  templateUrl: './textures.component.html',
  styleUrls: ['./textures.component.scss']
})
export class TexturesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/textures';

  constructor(public textureSvc:TextureService) { }

  ngOnInit() {
  }

}
