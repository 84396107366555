import {Component, OnDestroy, OnInit} from '@angular/core';
import ConditionnementVarianteDTO from '../../../../../core/dtos/conditionnement/conditionnement-variante-dto';
import {DeclinaisonDTO} from '../../../../../core/dtos/declinaison-dto';
import {combineLatest, Subscription} from 'rxjs';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {ModelesPlatsService} from '../../../../../core/services/conditionnements/modeles-plats.service';
import ModeleConditionnementPlatDTO from '../../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../../../core/suppliers/dialog-msg-supplier';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import ModeleConditionnementPlatDeclinaisonDTO
  from '../../../../../core/dtos/conditionnement/modele-conditionnement-plat-declinaison-dto';
import {ActivatedRoute} from '@angular/router';
import {DeclinaisonsService} from "../../../../../core/services/declinaisons/declinaisons.service";
import {ConditionnementsVarianteService} from "../../../../../core/services/conditionnements/conditionnement-variante.service";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../../../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ConditionnementVarianteDeclinaisonsModel} from "../../../../../core/models/gestion-conditionnements/conditionnement-variante-declinaisons-model";
import {RoutemapService} from "../../../../../core/services/routemap.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {GraphQLService} from "../../../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-mp-add-cv-decli',
  templateUrl: './mp-add-cv-decli.component.html',
  styleUrls: ['./mp-add-cv-decli.component.scss']
})
export class ModelePlatAddConditionnementVarianteDeclinaison implements OnInit, OnDestroy {

  conditionnementsVariantesList: ConditionnementVarianteForListBoxDTO[] = [];

  declinaisonsList: DeclinaisonDTO[] = [];

  conditionnementsVariantesSelectedList: ConditionnementVarianteForListBoxDTO[] = [];

  declinaisonsSelectedList: DeclinaisonDTO[] = [];

  modelePlat: ModeleConditionnementPlatDTO;

  modelesConditionnementsPlatDeclinaisonsList: ModeleConditionnementPlatDeclinaisonDTO[] = [];

  modelesConditionnementsPlatCVList: ModeleConditionnementsPlatConditionnementsVariantesDTO[] = [];

  associations: ConditionnementVarianteDeclinaisonsModel[] = [];

  subOpenDialog: Subscription;
  subSupplier: Subscription;

  openDialog = false;

  hasIDistri = false;

  form: FormGroup = new FormGroup({});

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-parametrage-modeles-plats';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private mcpSvc: ModelesPlatsService,
              private dcSvc: DeclinaisonsService,
              private routeMapSvc: RoutemapService,
              private cvSvc: ConditionnementsVarianteService,
              private toastSvc: ToastService,

              private graphQlSvc: GraphQLService) {

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subSupplier);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initOpenDialogSubscription();
    this.initForm();
    this.initDataFromSupplierSubscription();
  }

  initHasGestionIDistri(): void {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  }

  initOpenDialogSubscription(): void {
    this.subOpenDialog = this.mcpSvc.creationRowParametrageAsked$.subscribe(response => {
      this.openDialog = true;
    });
  }

  initForm(): void {
    this.form = new FormGroup({
      conditionnementsVariantes: new FormControl(this.conditionnementsVariantesSelectedList, [Validators.required]),
      declinaisons: new FormControl(this.declinaisonsSelectedList, [Validators.required])
    });
  }

  closeDialog(): void {
    this.openDialog = false;
  }

  async save(): Promise<void> {
    const result = confirm(`Êtes-vous sûr de vouloir enregistrer la grille de configuration du modèle de plat comme ceci ?
    <br/> Pour rappel, le fait de décocher un conditionnement ou une déclinaison, entraînera la suppression définitive des éléments associés.`,
      'Confirmation');
    const isConfirmed: boolean = await result;
    if(isConfirmed) {
      const conditionnementsVariantesList = this.form.controls['conditionnementsVariantes'].value;
      const declinaisonsList = this.form.controls['declinaisons'].value;
      this.mcpSvc.saveRowParametrage(this.modelePlat, conditionnementsVariantesList, declinaisonsList)
        .subscribe(() => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Grille configurée avec succès`);
          this.closeDialog();
          this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLAT, this.modelePlat.id, 'parametrage']);
        });
    }
  }

  canModify(modelePlat: ModeleConditionnementPlatDTO) {
    return modelePlat.site && this.auth2Svc.isSiteLocal(modelePlat.site.id) && this.hasIDistri;
  }

  public help(): DialogMsgSupplier {
    const dms = new DialogMsgSupplier();
    dms.title = `Associations couplées avec le modèle de conditionnement plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = ['Les conditionnements variantes et les déclinaisons configurés n\'apparaissent pas dans les menus de sélections',
      'Le type de conditionnement configuré à partir de cette boîte de dialogue sera un type parmi les types déjà configurés',
      'Le reste configuré à partir de cette boîte de dialogue sera un reste parmi ceux configurés'
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };

    return dms;
  }

  initDataFromSupplierSubscription() {
    this.subSupplier = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlatSupplier) {
          this.conditionnementsVariantesList = data.modelePlatSupplier.conditionnementVarianteList;
          this.modelePlat = data.modelePlatSupplier.modelePlatConditionnement;
          this.modelesConditionnementsPlatDeclinaisonsList = data.modelePlatSupplier.mcpDeclinaisonList;
          this.modelesConditionnementsPlatCVList = data.modelePlatSupplier.mcpConditionnementVarianteList;
          this.initDataFromCombineLastest();
        }
      });
  }

  /**
   * Initialise les conditionnements déjà configurés
   * @private
   */
  private initConditionnementsConfigured(cvAllList: ConditionnementVarianteDTO[]): void {
    // On enlève les conditionnements variantes déjà configurés :
    if (this.modelesConditionnementsPlatCVList && cvAllList) {
      const configList: ConditionnementVarianteForListBoxDTO[] = this.modelesConditionnementsPlatCVList.map(m => m.conditionnementVariante)
        .map(cv => ({label: cv.conditionnement.libelle + ' - ' + cv.variante.libelle, ...cv} as ConditionnementVarianteForListBoxDTO));
      this.conditionnementsVariantesSelectedList = this.utils.preSelectMultiList(this.conditionnementsVariantesList, configList);
    }
  }

  /**
   * Initialise les déclinaisons déjà configurées
   * @private
   */
  private initDeclinaisonsConfigured(): void {
    if (this.modelesConditionnementsPlatDeclinaisonsList) {
      const declinaisonsConfigureesList = this.modelesConditionnementsPlatDeclinaisonsList.map(mcpd => mcpd.declinaison);
      this.declinaisonsSelectedList = this.utils.preSelectMultiList(this.declinaisonsList, declinaisonsConfigureesList);
    }
  }

  /**
   * Récupération des données concernant les conditionnements variantes et les déclinaisons
   * pour préparer la plistbox.
   * @private
   */
  private initDataFromCombineLastest(): void {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    const cvAll$ = this.cvSvc.getAll();
    const decliAllFabrique$ = this.graphQlSvc.sendQuery(`
      {
          allDeclinaisons(filters: { siteIds: [${idsSites}] }) {
            id, libelle, site { id, libelle }
          }
      }
    `);


    const combine$ = combineLatest([cvAll$, decliAllFabrique$]);

    combine$.subscribe(response => {

      const cvAllList = response[0].resultList || [];
      this.declinaisonsList = response[1].allDeclinaisons || [];
      this.declinaisonsList = this.declinaisonsList.sort((d1, d2) => d1.libelle.localeCompare(d2.libelle));

      this.conditionnementsVariantesList = cvAllList.map(cv => ({label: cv.conditionnement.libelle + ' - ' + cv.variante.libelle, ...cv} as ConditionnementVarianteForListBoxDTO));

      // On enlève les conditionnements variantes déjà configurés :
      this.initConditionnementsConfigured(cvAllList);

      // On enlève les déclinaisons déjà configurées :
      this.initDeclinaisonsConfigured();

      this.initForm();
    });
  }

}

export class ConditionnementVarianteForListBoxDTO extends ConditionnementVarianteDTO {

  label: string;

}
