

<div class="d-flex flex-column mg-t-10" [style.max-width.px]="utils.getMaxWidthBody()+50">

  <div  [style.height.px]="utils.getWindowAvailableHeight(214)" style="overflow: auto;">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        N'oubliez pas d'enregistrer votre travail.
      </div>
      <div class="mg-r-5">
        <yo-button iconClass="fas fa-save" (onClick)="saveFicheIdentite()"
                [disabled]="isDisabledSaveBtn()" tooltip="Enregistrer" buttonClass="cta-success"></yo-button>
      </div>
      <div>
        <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="utils.sidenav=false"
                buttonClass="cta-delete"></yo-button>
      </div>
    </div>
  </div>
</div>

