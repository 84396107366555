<ng-container *ngIf="!utils.isNullOrEmpty(form)">

  <div class="d-flex flex-column">

    <div class="flex-grow-1" [style.max-height.px]="catalogueAchatSvc.getMaxContentHeightDialogArticle(cads)"
         style="overflow-y:auto;overflow-x: hidden; margin-bottom: 4px;">
      <yo-form [form]="form" [groups]="groups" [options]="formOptions">
      </yo-form>

    </div>


    <div>
      <hr>
      <div class="d-flex">

        <div class="mr-auto">
          <label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i>
            Si l'article est le privilégié, son prix d'achat sera répercuté sur le produit déclinaison lors de
            l'enregistrement.</label>
        </div>
        <div *ngIf="cads.allProduitsDeclines && cads.allProduitsDeclines.length" class="mg-r-5">
          <yo-button
            iconClass="fas fa-save"
            buttonClass="cta-success"
            (onClick)="saveCatalogueAchat()"
            tooltip="Enregistrer"
            [disabled]="!canModifyForUpdate() && this.catalogueAchat.id != null"></yo-button>
        </div>
        <div class="mg-r-5">
          <yo-button
            iconClass="fas fa-trash"
            (onClick)="openDeleteDialog(catalogueAchat)"
            [disabled]="!canModifyForUpdate()"
            tooltip="Supprimer cet article"
            buttonClass="cta-inner-delete">
          </yo-button>
        </div>
        <div class="mg-r-5">
          <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
                  buttonClass="cta-inner-delete"></yo-button>
        </div>
      </div>

    </div>
  </div>

</ng-container>



