import {Component, OnInit} from "@angular/core";
import {FamillesGemrcnService} from "../../../core/services/entities/familles-gemrcn.service";
import {Subscription} from "rxjs";
import {FamilleGemrcnDTO} from "../../../core/dtos/famille-gemrcn-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {FormControl, FormGroup} from "@angular/forms";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {CouleurDTO} from "../../../core/dtos/couleur-dto";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {GraphQLService} from "../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-dialogEdition',
  templateUrl: './dialog-edition.component.html',
  styleUrls: ['./dialog-edition.component.scss']
})
export class DialogEditionComponent implements OnInit {

  subOpenDialogConditionnement: Subscription

  subColor: Subscription;

  familleGemrcn: FamilleGemrcnDTO;

  form: FormGroup = new FormGroup({});

  colors: CouleurDTO[] = [];

  dialogTitle: String = 'Modification d\'une famille GEMCRN';

  sitePlaceholder: String = 'Selectionner le site...';

  displayDialog = false;

  idSelectedColor: number;

  idSelectedSite: number;

  constructor(private familleGemrcnSvc: FamillesGemrcnService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService,
              private toastSvc: ToastService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
    this.initColorsSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialogConditionnement);
    this.utilsSvc.unsubscribe(this.subColor);
  }

  private initColorsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subColor = this.graphQlSvc.sendQuery(`
      {
        allCouleurs(filters: {
          siteIds: [${idsSites}]
        }) {
          id,
          libelle,
          code,
          hexa,
          actif
        }
      }
    `)
      .subscribe(response => this.colors = response.allCouleurs);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialogConditionnement = this.familleGemrcnSvc.openDialogEdition$
      .subscribe((cdt: FamilleGemrcnDTO) => {
        this.displayDialog = true;
        if (!cdt) {
          this.familleGemrcn = new FamilleGemrcnDTO();
          this.familleGemrcn.id = 0;
          this.dialogTitle = 'Création d\'une famille GEMCRN';
        } else {
          this.familleGemrcn = cdt;
          this.dialogTitle = 'Modification d\'une famille GEMCRN';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let newFamille: FamilleGemrcnDTO = {...this.familleGemrcn};
      newFamille.site = {id: this.idSelectedSite} as SiteDTO;
      newFamille.couleur = {id: this.idSelectedColor} as CouleurDTO;
      newFamille.libelle = this.form.controls['libelle'].value;
      newFamille.code = this.form.controls['code'].value;
      newFamille.ordre = this.form.controls['ordre'].value;
      newFamille.actif = this.form.controls['actif'].value;
      this.familleGemrcnSvc.save(newFamille)
        .subscribe(response => {
          const isUpdate = newFamille.id !== null && newFamille.id !== undefined && newFamille.id !== 0;
          this.familleGemrcnSvc.announceFamilleGemrcnSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la famille GEMRCN réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.familleGemrcn?.site?.libelle : 'Selectionner le site...';
    this.idSelectedColor = this.familleGemrcn?.couleur?.id;
    this.idSelectedSite = this.familleGemrcn?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.familleGemrcn?.libelle),
      code: new FormControl(this.familleGemrcn?.code),
      ordre: new FormControl(this.familleGemrcn ? this.familleGemrcn.ordre : 0),
      actif: new FormControl(this.familleGemrcn?.actif != null ? this.familleGemrcn.actif : true)
    });
  };

  getCurrentColor = (): CouleurDTO => this.colors.find(c => c.id === this.idSelectedColor);

  getTooltipValue = (value: string): string => value ? value : "couleur";

  onChangeColor = ($event: any): void => {
    this.idSelectedColor = $event.selectedItem?.id;
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.familleGemrcnSvc.canModify(this.familleGemrcn);
  }

}
