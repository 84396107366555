import {ObjectDTO} from './object-dto';
import {ProduitArticleDTO} from './produit-article-dto';
import {UnitedemesureDTO} from './unitedemesure-dto';
import {LotMarchePdLumpCaDto} from './gerstion-marche/lot-marche-pd-lump-ca-dto';
import {FournisseurDTO} from './fournisseur-d-t-o';
import {GroupeAchatCaViewModel} from "../models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {GroupeAchat__FournisseurDTO} from "./unite-de-production/groupe-achat-fournisseur-dto";
import {ProduitDeclinaisonDTO} from "./produit-declinaison-dto";
import {UniteDeProductionDTO} from "./unite-de-production-dto";
import UniteDeProduction__CatalogueAchatDTO from "./unite-de-production__catalogue-achat-dto";

export class CatalogueAchatDTO extends ObjectDTO {

  caParentId : number;

  // produit declinaison
  produitDeclinaison: ProduitDeclinaisonDTO;
  produitDeclinaisonLibelle: string;
  produitDeclinaisonCode: string;
  produitDeclinaisonId: number;
  produitDeclinaisonActif: boolean;

  // produit article
  produitArticle: ProduitArticleDTO;
  produitArticleLibelle : string;
  produitArticleReferenceArticle : string;

  // unites de mesure
  uniteDeCommande: UnitedemesureDTO;
  uniteDeCommandeCode: string;
  uniteDeCommandeRefSite: string;

  uniteDeFacturation: UnitedemesureDTO;
  uniteDeFacturationCode: string;
  uniteDeFacturationRefSite: string;

  uniteDeStockage: UnitedemesureDTO;
  uniteDeStockCode: string;
  uniteDeStockRefSite: string;

  uniteTechnique: UnitedemesureDTO;

  fournisseurOwnerLibelle: string;
  fournisseur : FournisseurDTO;

  // catalogue achat
  prix: number;
  prixPrecedent: number;
  prixMoyen: number;
  referenceArticle: string;
  ratioUniteCommandeUniteBase: number;
  ratioUniteFacturationUniteBase: number;
  ratioUniteStockageUniteBase: number;
  ratioUniteTechnique: number;
  minimumDeCommande: number;
  actif: boolean;
  bio: boolean;
  produitCarne: boolean;
  circuitCourt: boolean;
  codeApi: string;

  prixOrigine: number;

  //Init ligne
  quantite: number;

  // infos associé à un article de type marché
  lmPdUpCa: LotMarchePdLumpCaDto;

  // produit
  produitId: number;

  // Famille produit
  idFamilleProduit: number;
  libelleFamilleProduit: string;

  // prix
  groupeAchatCaList :GroupeAchatCaViewModel[] = [];

  groupeAchatFournisseur: GroupeAchat__FournisseurDTO;

  filialeCode: string;
  filialeRefSite: string;
  secteurCode: string;
  secteurRefSite: string;


  // udp
  idUniteProduction: number;
  udpForGroupeAchat: UniteDeProductionDTO;
  uniteDeProductionCatalogueAchat: UniteDeProduction__CatalogueAchatDTO;
}
