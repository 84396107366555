import {GenericRequestSupplier} from "../../suppliers/generics/generic-request-supplier";
import {ExportPropertyDTO} from "./export-property-dto";

export class ExportRequestDTO extends GenericRequestSupplier{

  filename:string;
  sheetname:string;

  exportProperties:ExportPropertyDTO[];
  idsToExport: number[] = [];

}
