import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient} from '@angular/common/http';
import {GenericHandler} from '../generics/generic-handler';
import {Router} from '@angular/router';
import {DecoupageRepasDTO} from '../../dtos/decoupagerepas-dto';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';

export const URL_POST_CMC_PLANNING_DECOUPAGEREPAS_LIST=`dolrest/gestioncontrats/contrat-menu-convive/planning/decoupageRepasList`;

@Injectable()
export class DecoupagesRepasService extends GenericHandler<DecoupageRepasDTO> {

  getTotalRecordsLabel(): string {
    return 'Composantes repas';
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES COMPOSANTES REPAS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE COMPOSANTE REPAS';
  }


  constructor(utils: UtilsService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName(): string {
    return 'DecoupageRepas';
  }

  getFields(dto: DecoupageRepasDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut un découpage repas est actif.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new DecoupageRepasDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      // new FormFieldTextboxSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: true,
      //   value: dto.site,
      //   order: 1
      // }),

      // new FormFieldDropdownSupplier({
      //   key: 'site',
      //   label: 'Site',
      //   readonly: !this.canModifySite(dto),
      //   type: 'externalRef', // this.initArray(this.typeProduitSvc.typeProduitList);
      //   value: this.utils.preSelectSingleList(this.optionsSites,dto.site),
      //   // value: this.getSelectedItem(dto.site,        this.initArray(this.auth2Svc.utilisateur.siteListLocaux),
      //   order: 1,
      //   options: this.optionsSites,
      //   required: true,
      // }),

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        excelType:'string',
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'ordre',
        label: 'Ordre',
        excelType:'integer',
        readonly: !this.canModify(dto),
        value: dto.ordre,
        type: 'number',
        required: true,
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 5
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): DecoupageRepasDTO {
    return new DecoupageRepasDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA COMPOSANTE REPAS '${data.libelle.toUpperCase()}'`;
  }
}
