import {Component, OnInit} from '@angular/core';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {TaxesService} from '../../core/services/entities/taxes.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/taxes';

  ngOnInit(): void {
  }

  constructor(public taxesSvc: TaxesService,
              public gds: GenericDatagridService) {
  }

}
