import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FamilleProduitDTO} from '../../dtos/famille-produit-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {Observable, Subject} from 'rxjs';
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";

export const URL_FIND_ALL = `dolrest/famillesproduits/findAll`;

@Injectable()
export class FamillesProduitService extends GenericHandler<FamilleProduitDTO> {
  get findAll(): (isFabrique?: boolean) => Observable<ResponseWrapper<FamilleProduitDTO>> {
    return this._findAll;
  }

  set findAll(value: (isFabrique?: boolean) => Observable<ResponseWrapper<FamilleProduitDTO>>) {
    this._findAll = value;
  }


  familleProduitList: FamilleProduitDTO[];

  private subjectfamilleProduit = new Subject();
  familleProduit$ = this.subjectfamilleProduit.asObservable();


  announceFamilleProduit = (familleProduit: FamilleProduitDTO) => {
    this.subjectfamilleProduit.next(familleProduit);
  };

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES FAMILLES PRODUITS';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE FAMILLE PRODUIT';

  getEntityName = (): string => 'FamilleProduit';

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              private httpSvc: HttpService,
              title: Title,
              private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  getFields = (dto: FamilleProduitDTO): FormFieldBaseSupplier<any>[] => {

    // Par défaut une FamilleProduit est active.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new FamilleProduitDTO();
      dto.actif = true;
      dto.fabrique = false;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        excelType:'string',
        required: true,
        order: 3
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: false,
        excelType:'string',
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'fabrique',
        label: 'Est fabriqué',
        readonly: !this.canModify(dto),
        value: dto.fabrique,
        required: false,
        excelType:'boolean',
        order: 5
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        excelType:'boolean',
        order: 6
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getAllFamilleProduits = () => this.gds.getAll(this.getEntityName(), ['libelle,asc'], false);


  getAllFromEnvironnement = (): void => {

    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.familleProduitList = [];
        this.familleProduitList = response.resultList;
        this.announceObjects(this.familleProduitList);
      });
  };

  createEmptyDTO = (): FamilleProduitDTO => new FamilleProduitDTO();

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LA FAMILLE PRODUIT '${data.libelle.toUpperCase()}'`;

  /**
   * enlever tous les sous niveaux de famille<br>
   *     enlever les familles winapro incoherentes A000...
   * @param response
   */
  removeChildren = (familles: FamilleProduitDTO[]) => {

    if (this.utils.isCollectionNullOrEmpty(familles)) {
      familles = [];
    }

    // enlever tous les sous niveaux de famille
    familles = familles.filter(f => !f.code.includes('.'));

    // enlever les familles winapro incoherentes A000...
    familles = familles.filter(f => !f.libelle.includes('A0000'));

    return familles;
  };

  private _findAll = (isFabrique: boolean = true): Observable<ResponseWrapper<FamilleProduitDTO>> => {
    return this.httpSvc.get(`${URL_FIND_ALL}?isFabrique=${isFabrique}`);
  }
}
