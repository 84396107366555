import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {CoreModule} from "../core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FacturesRoutingModule} from "./factures-routing.module";
import {FacturesFournisseursComponent} from "./fournisseurs/factures-fournisseurs.component";
import {FacturesRootComponent} from "./factures-root.component";
import {
    DxDataGridModule,
    DxDateBoxModule,
    DxPopupModule, DxScrollViewModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxToolbarModule
} from "devextreme-angular";

import {
  DialogJoinBonReceptionComponent
} from "./fournisseurs/dialog-join-bon-reception/dialog-join-bon-reception.component";
import {DialogDetailsInvoiceComponent} from "./fournisseurs/dialog-details-invoice/dialog-details-invoice.component";

@NgModule({
    imports: [
        CommonModule,
        FacturesRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        DxDataGridModule,
        DxToolbarModule,

        DxTagBoxModule,
        DxPopupModule,
        DxDateBoxModule,
        DxTextBoxModule,
        DxScrollViewModule,
    ],
  declarations: [
    FacturesRootComponent,
    FacturesFournisseursComponent,
    DialogJoinBonReceptionComponent,
    DialogDetailsInvoiceComponent
  ]
})
export class FacturesModule { }
