<dx-popup
  [showTitle]="true"
  title="AJOUTER DES DENRÉES"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="displayDialog"
  [fullScreen]="fullScreen"
  (onHiding)="closeDialog()"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>
  <div class="mg-b-10">
    <p-panel header="Options de recherche" [toggleable]="true" (onAfterToggle)="onToggleSearchPanel($event)">
      <div class="row">
        <div class="col-md-2">
          <dx-text-box valueChangeEvent="keyup" (onValueChanged)="announceDenreeLibelleSearch($event)"
                       [value]="denreeSearchValue" placeholder="Déclinaison">
          </dx-text-box>
        </div>
        <div class="col-md-3">
          <dx-tag-box
            [dataSource]="appellationList"
            placeholder="Filtrer par appellation..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeAppellation($event)"
            [value]="appellationListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-2">
          <dx-tag-box
            [dataSource]="allergeneList"
            placeholder="Filtrer par allergène..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeAllergene($event)"
            [value]="allergeneListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-2">
          <dx-tag-box
            [dataSource]="declinaisonList"
            placeholder="Filtrer par déclinaison..."
            displayExpr="libelle"
            valueExpr="id"
            [multiline]="false"
            [showSelectionControls]="true"
            (onValueChanged)="onChangeDeclinaison($event)"
            [value]="declinaisonListSelected">
          </dx-tag-box>
        </div>
        <div class="col-md-3">
          <dx-drop-down-box
            [(value)]="treeBoxValue"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Filtrer par famille de produit..."
            [showClearButton]="true"
            [dataSource]="treeDataSource"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                #treeViewProduitDeclinaison
                [dataSource]="familleProduitList"
                [height]="150"
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parent.id"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="true"
                displayExpr="libelle"
                [selectByClick]="true"
                (onItemSelectionChanged)="onChangeParentDenreeSelection($event)"
                [searchEnabled]="true"
                searchMode="contains"
              >
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </p-panel>
  </div>

  <dx-data-grid
    [dataSource]="customStore"
    [remoteOperations]="true"
    keyExpr="id"
    height="70%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #gridAjoutDenreePropositionCommande>
    <dxi-column dataField="id" cellTemplate="templateSelection"
                caption="Sélection" [width]="200" [allowSorting]="false"
                [allowFiltering]="false">
    </dxi-column>
    <dxi-column dataField="libelle" alignment="left" caption="Déclinaison"
                [allowFiltering]="true"
                cellTemplate="libelleCellTemplate">
    </dxi-column>
    <dxi-column dataField="code" alignment="left" caption="Code"
                [allowFiltering]="true"
                cellTemplate="codeCellTemplate">
    </dxi-column>
    <dxi-column dataField="site" alignment="left" caption="Site"
                [allowFiltering]="true"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="prixUF" alignment="left" caption="Prix d'achat en UF"
                [allowFiltering]="false"
                cellTemplate="prixUFCellTemplate">
    </dxi-column>
    <dxi-column dataField="prixKg" alignment="left" caption="Prix d'achat au KG"
                [allowFiltering]="false"
                cellTemplate="prixKGCellTemplate">
    </dxi-column>
    <dxi-column dataField="uniteDeCommande" alignment="left" caption="UC"
                [allowFiltering]="true"
                cellTemplate="uniteCommandeCellTemplate">
    </dxi-column>
    <dxi-column dataField="familleProduitLibelle" alignment="left"
                caption="Famille de produit"
                [allowFiltering]="true"
                cellTemplate="familleProduitCellTemplate">
    </dxi-column>


    <!--    TEMPLATE-->
    <div *dxTemplate="let cell of 'libelleCellTemplate'">
      {{cell.row.data.produitArticle?.produitDeclinaison?.libelle}}
    </div>
    <div *dxTemplate="let cell of 'codeCellTemplate'">
      {{cell.row.data.produitArticle?.produitDeclinaison?.code}}
    </div>
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      {{cell.row.data.produitArticle?.produitDeclinaison?.site?.libelle}}
    </div>
    <div class="float-right" *dxTemplate="let cell of 'prixUFCellTemplate'">
      {{cell.row.data.groupeAchatCaList[0]?.prixUF?.value |currency:'EUR':'symbol':'1.4-4'}}
      / {{cell.row.data.uniteDeFacturation?.abreviation}}
    </div>
    <div class="float-right" *dxTemplate="let cell of 'prixKGCellTemplate'">
      {{cell.row.data.groupeAchatCaList[0]?.prixKg?.value | currency:'EUR':'symbol':'1.4-4'}} / KIL
    </div>
    <div *dxTemplate="let cell of 'uniteCommandeCellTemplate'">
      {{cell.row.data.uniteDeCommande?.libelle}}
    </div>
    <div *dxTemplate="let cell of 'familleProduitCellTemplate'">
      {{cell.row.data.produitArticle?.produitDeclinaison?.familleProduit?.libelle}}
    </div>

    <div *dxTemplate="let cell of 'templateSelection'" class="custom-button-container">
      <yo-cell-button (click)="addProduitDeclinaison(cell.row.data)"
                      [class.hidden]="cell.row.data.quantite && cell.row.data.quantite>=0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-cart-plus"
                      yoTooltip="Ajouter un article"
      ></yo-cell-button>
      <yo-cell-button (click)="removeProduitDeclinaison(cell.row.data)"
                      [class.hidden]="!cell.row.data.quantite || cell.row.data?.quantite <= 0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-minus"
                      yoTooltip="Enlever un article"
      ></yo-cell-button>
      <ng-container id="input" *ngIf="cell.row.data.quantite && cell.row.data.quantite > 0">
            <span style="padding: 0 5px;">
                          <input pInputText pKeyFilter="pnum"
                                 [style.width.px]="62"
                                 min="0"
                                 [(ngModel)]="cell.row.data.quantite">
                          <span> {{cell.row.data.produitArticle.produitDeclinaison.uniteDeMesure.abreviation}} </span>
                        </span>
      </ng-container>
      <yo-cell-button (click)="addProduitDeclinaison(cell.row.data)"
                      [class.hidden]="!cell.row.data.quantite || cell.row.data.quantite<=0"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      yoIconClass="fas fa-plus"
                      yoTooltip="Ajouter un article"
      ></yo-cell-button>
    </div>

    <!--    OPTION-->
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[20, 50, 100, 500]"
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Déclinaison(s)">
    </dxo-pager>
  </dx-data-grid>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div>
        <label class="font-12 genlabel">
          <i class="fas fa-info-circle mg-r-5"></i> Veuillez ajouter des denrées à votre panier
        </label>
      </div>
    </div>
    <div>
      <span class="mg-r-5">
         <yo-button iconClass="fas fa-plus" (onClick)="sendProduitDeclinaisonList()"
                    [disabled]="!catalogueAchatList.length" tooltip="Affection du contenu du panier"
                    buttonClass="cta-success">
        </yo-button>
      </span>
      <span class="mg-r-5">
        <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
                   buttonClass="cta-inner-delete">
        </yo-button>
      </span>
    </div>
  </div>
</dx-popup>
