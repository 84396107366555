import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {Observable, Subscription} from 'rxjs';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FormGroup} from '@angular/forms';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {SecteurDTO} from '../../../../core/dtos/secteur-dto';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {AdressesService} from '../../../../core/services/entities/adresses.service';
import {SecteurFournisseurDTO} from '../../../../core/dtos/secteurfournisseur-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {SecteursFournisseursService} from '../../../../core/services/entities/secteurs-fournisseurs.service';
import {FormOptions} from '../../../../core/suppliers/form-options';
import {GROUP_POSITION, GroupFormFields} from '../../../../core/suppliers/group-formfields';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {ObjectDTO} from '../../../../core/dtos/object-dto';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-filiale-form-dialog',
  templateUrl: './filiale-form-dialog.component.html',
  styleUrls: ['./filiale-form-dialog.component.scss'],
})
export class FilialeFormDialogComponent implements OnInit, OnDestroy {

  @Input() displayDialog: boolean;
  @Output() onClose = new EventEmitter<boolean>();

  formOptions: FormOptions = {width: 980};
  groups: GroupFormFields[] = [];

  filiale: FournisseurDTO;
  secteurList: SecteurDTO[] = [];
  adresse: AdresseDTO;

  subEditFiliale: Subscription;
  subCreateFiliale: Subscription;

  isCreateMode: boolean;

  title: string = '';

  form: FormGroup;
  formFiliale: FormGroup = new FormGroup({});
  formAdresse: FormGroup = new FormGroup({});
  formSecteurs: FormGroup = new FormGroup({});
  formFraisPort: FormGroup = new FormGroup({});

  constructor(public gds: GenericDatagridService,
              public auth2Svc: Auth2Service,
              private fournisseurSvc: FournisseurService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private adressesSvc: AdressesService,
              private utils: UtilsService,
              private gfs: GenericFormService,
              private secteursFournisseursService: SecteursFournisseursService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService) {
  }


  ngOnInit(): void {

    this.form = new FormGroup({
      formInfoGenerales: this.formFiliale,
      formAdresse: this.formAdresse,
      formSecteurs: this.formSecteurs,
      formFraisPort: this.formFraisPort,
    });


    this.getSecteurs().subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.secteurList = response.allSecteurs;
      }
    });

    this.initEdit();
    this.initCreate();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subEditFiliale);
    this.utils.unsubscribe(this.subCreateFiliale);
  }

  getSecteurs = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allSecteurs(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            site {
                id,
                libelle,
            },
        }
      }
    `);
  }

  initGroups = (fililale: FournisseurDTO) => {

    const fieldsFiliale = this.fournisseurSvc.getFields(fililale);
    const fieldsAdresse = this.adressesSvc.getFields(fililale.adresse ? fililale.adresse : this.adressesSvc.createEmptyDTO());

    const fieldsInfoKey = ['site', 'libelle', 'code', 'siret', 'actif'];
    const fieldsInfo = this.gfs.getFieldsByKey(fieldsInfoKey, fieldsFiliale);
    this.formFiliale = this.gfs.toFormGroup(fieldsInfo);

    const fieldsSecteurKey = ['secteur'];
    const fieldSecteur = this.gfs.getFieldsByKey(fieldsSecteurKey, fieldsFiliale);
    this.formSecteurs = this.gfs.toFormGroup(fieldSecteur);

    const fieldsAdresseKey = ['nom', 'adresse1', 'adresse2', 'adresse3', 'codePostal', 'ville', 'telephone', 'fax', 'email'];
    const fieldAdresse = this.gfs.getFieldsByKey(fieldsAdresseKey, fieldsAdresse);
    this.formAdresse = this.gfs.toFormGroup(fieldAdresse);

    const fieldsFraisPortKey = ['francoDePortActif', 'francoDePortCout', 'francoDePortMontant', 'francoDePortPoids', 'francoDePortTva'];
    const fieldFraisPort = this.gfs.getFieldsByKey(fieldsFraisPortKey, fieldsFiliale);
    this.formFraisPort = this.gfs.toFormGroup(fieldFraisPort);

    this.form = new FormGroup({
      formFiliale: this.formFiliale,
      formSecteurs: this.formSecteurs,
      formAdresse: this.formAdresse,
      formFraisPort: this.formFraisPort
    });

    this.groups = [
      {
        titlePanel: 'Filiale',
        fields: fieldsInfo,
        form: this.formFiliale,
        titleIcon: 'fas fa-industry',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsInfoKey,
        }
      },
      {
        titlePanel: 'Frais de port',
        fields: fieldFraisPort,
        form: this.formFraisPort,
        titleIcon: 'fas fa-truck-moving',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsFraisPortKey,
        }
      },
      {
        titlePanel: 'Adresse',
        fields: fieldAdresse,
        form: this.formAdresse,
        titleIcon: 'fas fa-home',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsAdresseKey,
        }
      },
      {
        titlePanel: 'Secteur',
        fields: fieldSecteur,
        form: this.formSecteurs,
        titleIcon: 'fas fa-map-marker-alt',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsSecteurKey,
        }
      }
    ];
  };

  initEdit = () => {
    this.subEditFiliale = this.fournisseurSvc.editFiliale$.subscribe((data: FournisseurDTO) => {
      this.isCreateMode = false;

      this.gestionFournisseurSvc.getFournisseuById(this.utils.isNullOrEmpty(data) ? 0 : data.id).subscribe((response: ResponseWrapper<FournisseurDTO>) => {
        this.filiale = response.one;
        this.adresse = this.filiale.adresse;
        this.initGroups(this.filiale);
        this.title = 'Edition de la Filiale ' + this.filiale.libelle;
      });
    });
  };

  initCreate = () => {
    this.subCreateFiliale = this.fournisseurSvc.createFiliale$.subscribe((data: FournisseurDTO) => {

      this.isCreateMode = true;

      this.title = 'Création d\'une Filiale ';
      this.adresse = new AdresseDTO(data.site);

      this.filiale = new FournisseurDTO();
      let parent = new ObjectDTO();

      //parent
      this.filiale.parent = parent;
      this.filiale.parent.id = data.id;
      this.filiale.parent.code = data.code;
      this.filiale.parent.libelle = data.libelle;
      this.filiale.parent.actif = data.actif;

      //filiale
      this.filiale.site = data.site;
      this.filiale.libelle = data.libelle;
      this.filiale.id = 0;
      this.filiale.francoDePortTva = 20;
      this.filiale.francoDePortCout = 0;

      this.initGroups(this.filiale);
    });
  };

  closeDialog = (isFromSavingBtn: boolean) => {
    this.displayDialog = false;
    this.onClose.emit(isFromSavingBtn);
  };

  isVisibleSaveBtn = () => {
    if (this.isCreateMode) {
      return true;
    } else {
      return this.auth2Svc.isSiteLocal(this.filiale?.site.id);
    }
  };

  initSaveFiliale = () => {

    //Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);

    //On enregistre que si le formulaire est valide
    if (this.form.valid) {

      //Filiale
      this.filiale.site = {id: this.formFiliale.controls['site'].value.id} as SiteDTO;
      this.filiale.libelle = this.formFiliale.controls['libelle'].value;
      this.filiale.code = this.formFiliale.controls['code'].value;
      this.filiale.siret = this.formFiliale.controls['siret'].value;
      this.filiale.actif = this.formFiliale.controls['actif'].value;
      this.filiale.francoDePortActif = this.formFraisPort.controls['francoDePortActif'].value;
      this.filiale.francoDePortMontant = this.formFraisPort.controls['francoDePortMontant'].value;
      this.filiale.francoDePortPoids = this.formFraisPort.controls['francoDePortPoids'].value;

      this.filiale.idFournisseur = null;

      // cout du franco de port, si null on le force à 0
      const francoDePortCout = this.formFraisPort.controls['francoDePortCout'].value;
      this.filiale.francoDePortCout = this.utils.isNullOrEmpty(francoDePortCout) ? 0 : francoDePortCout;

      // franco de port TVA par défaut est à 20%
      const francoDePortTva = this.formFraisPort.controls['francoDePortTva'].value;
      this.filiale.francoDePortTva = this.utils.isNullOrEmpty(francoDePortTva) ? 20 : francoDePortTva;

      //Adresse
      this.filiale.adresse = this.adressesSvc.prepareAdresseForSave(this.formAdresse, this.fournisseurSvc.getEntityName());
      this.filiale.adresse.id = this.adresse.id;
      this.filiale.adresse.site = this.adresse.site;

      //Secteurs de livraison
      this.filiale.secteurFournisseurList = [];
      let selectedSecteurs: SecteurDTO[] = this.formSecteurs.controls['secteur'].value;
      console.log('selectedSecteurs', selectedSecteurs);

      if (this.utils.isCollectionNullOrEmpty(selectedSecteurs)) {
        selectedSecteurs = [];
      }
      selectedSecteurs.map(secteur => {
        let secteurFournisseur = new SecteurFournisseurDTO(this.filiale.id, secteur);
        this.filiale.secteurFournisseurList.push(secteurFournisseur);
      });

      //Persistence en back
      this.saveFiliale();
    }
  };

  saveFiliale = () => {
    this.utils.announceSubjectBlockui(true);
    this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Cette opération peut prendre du temps`);

    this.gestionFournisseurSvc.saveFournisseur(this.filiale).subscribe(response => {
      this.utils.announceSubjectBlockui(false);
      if (!this.utils.isResponseSupplierError(response)) {
        this.secteursFournisseursService.getAllFromEnvironnement();
        //annoncer que la fiche d'identite a été sauvegardée. La grille des fournisseurs doit être abonnée pour faire la mise à jour
        this.filiale = response.one;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La filiale ${this.filiale.libelle} a bien été enregistrée`);
        this.gestionFournisseurSvc.announceRefreshGrid(this.filiale);
        this.closeDialog(true);
      }
    });
  };

  getErrorMessage = (messageList: String[]) => {
    let message: string = '';
    messageList.forEach(msg => message = message + msg);
    return message;
  };
}
