import {Component, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';

@Component({
  selector: 'yo-environnements',
  templateUrl: './environnements.component.html',
  styleUrls: ['./environnements.component.scss']
})
export class EnvironnementsComponent implements OnInit {

  @ViewChild('grilleenvironnements') grilleenvironnements;

  constructor(private utils : UtilsService) { }

  ngOnInit() {
    this.utils.setTitle('Gestion des Environnements');
  }

  help(){
    return undefined;
  }


}
