import {Component, OnInit, ViewChild} from '@angular/core';
import {EquipeSavedSupplier, EquipesService} from '../../core/services/entities/equipes.service';
import {Subscription} from "rxjs";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {EquipeDTO} from "../../core/dtos/equipe-dto";
import {saveAs} from "file-saver";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {OverlayPanel} from "primeng/overlaypanel";

@Component({
  selector: 'yo-equipes',
  templateUrl: './equipes.component.html',
  styleUrls: ['./equipes.component.scss']
})
export class EquipesComponent implements OnInit {

  @ViewChild('opImportXls') opImportXls: OverlayPanel;

  subLog: Subscription;
  subEquipeSaved: Subscription;
  subEquipes: Subscription;

  logged: boolean = false;

  equipes: EquipeDTO[] = [];

  selectedRows: number[] = [];
  errors: any[] = [];

  displayImportPopupErrors: boolean = false;
  displayDeletePopupErrors: boolean = false;

  pathHelpFile: string = HELP_FOLDERS.REFERENTIEL + '/equipes/equipes';

  constructor(private auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              private graphQlSvc: GraphQLService,
              public utilsSvc: UtilsService,
              public equipesSvc: EquipesService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initLoggedSubscription();
    this.initEquipesSubscription();
    this.initEquipeSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subLog);
    this.utilsSvc.unsubscribe(this.subEquipeSaved);
    this.utilsSvc.unsubscribe(this.subEquipes);
  }

  initLoggedSubscription = (): void => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.logged = res);
  }

  initEquipesSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subEquipes = this.graphQlSvc.sendQuery(`
      {
        allEquipes(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            actif,
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.equipes = response.allEquipes;
      });
  }

  initEquipeSavedSubscription = (): void => {
    this.subEquipeSaved = this.equipesSvc.equipeSaved$
      .subscribe((response: EquipeSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.equipes.findIndex(equipe => equipe.id === response.equipe.id);
          this.equipes[idx] = response.equipe;
        } else {
          this.equipes.push(response.equipe);
        }
      });
  };

  openDialogEdition = (equipe: EquipeDTO): void => {
    this.equipesSvc.announceOpenDialogEdition(equipe);
  };

  calculateSiteValue = (equipe: EquipeDTO): string => {
    return equipe?.site?.libelle
  };

  exportXslx = () => {
    this.equipesSvc.exportEquipeByFilters(this.selectedRows).subscribe(response => {
      const filename = `equipe-export-${new Date().getTime()}.xlsx`;
      const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      this.selectedRows = [];
      saveAs(blob, filename);
    });
  }

  importXslx = async ($event: any) => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.opImportXls.hide();
      this.equipesSvc.importEquipeFromXlsx(file)
        .subscribe(response => {
          const res: any = response.one;
          if (res.allElementsImported)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }
          for (const item of res.elementsImported) {
            let index = this.equipes.findIndex(equipe => equipe.id == item.id);
            if (index >= 0)
              this.equipes[index] = item;
            else
              this.equipes.push(item)
          }

          if (this.errors != null && this.errors.length > 0)
            this.displayImportErrors();
        });
    }
  }

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
    this.displayDeletePopupErrors = false;
  }

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_EQUIPE_DELETION_PLURAL" : "CONFIRM_EQUIPE_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.equipesSvc.deleteEquipeByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.equipes.find(equipe => equipe.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `EQUIPE_DELETION_COMPLETED_PLURAL` : `EQUIPE_DELETION_COMPLETED_SINGULAR`, null));
        }
        this.equipes = this.equipes.filter(equipe => !idsToDeleteInTable.find(id => id === equipe.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;
}
