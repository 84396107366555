import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {StocksService} from '../../core/services/gestion-stock/stocks.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'yo-mouvements',
  templateUrl: './mouvements.component.html',
  styleUrls: ['./mouvements.component.scss']
})
export class MouvementsComponent implements OnInit,OnDestroy {

  subRoute:Subscription;

  constructor(public utils: UtilsService,
              private stocksSvc: StocksService,
              private route: ActivatedRoute) { }

  ngOnInit() {

    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subRoute = this.route.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature, null);

    });

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

}
