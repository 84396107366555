import {catchError} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {UnitedemesureDTO} from "../dtos/unitedemesure-dto";
import {UtilsService} from "../utils/utils.service";
import {Observable, Subscription} from "rxjs";
import {SelectItem} from "primeng/api";
import {HttpClient} from "@angular/common/http";

/**
 * @deprecated use UnitesDeMesuresService instead
 */
@Injectable()
export class UnitedemesureService implements OnDestroy {


  listUniteDeMesures$: Observable<UnitedemesureDTO[]>;
  listUniteDeMesures: SelectItem[];
  sub: Subscription;

  constructor(private http: HttpClient,
              private utils: UtilsService) {
    this.listUniteDeMesures$ = this.getListUnitesDeMesures();
    this.sub = this.listUniteDeMesures$.subscribe(data => {
      this.listUniteDeMesures = [];
      for (let d of data) {
        this.listUniteDeMesures.push({label: d.libelle, value: d.id});
      }
    })
  }


  public getListUnitesDeMesures(): Observable<UnitedemesureDTO[]> {

    return this.http.get<UnitedemesureDTO[]>('dolrest/unitesdemesures/findBySitesOfUser?size=1000').pipe(
      catchError(error => this.utils.handleError(error)));
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.sub);
  }


}
