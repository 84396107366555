<form [formGroup]="formGroupCtrl" (ngSubmit)="save()" novalidate>

  <dx-toolbar class="yo-toolbar">
    <dxi-item location="before">
      <div class="d-flex flex-row align-items-center">

        <!--Nouvel environnement -->
        <ng-container *ngIf="yoEnvironnement.id === 0">
          <label>Nouvel environnement</label>

        </ng-container>

        <!--MODIFICATION ENVIRONNEMENT-->
        <ng-container *ngIf="yoEnvironnement.id != 0">
          <label>Environnement : {{yoEnvironnement.libelle}}</label>
        </ng-container>

      </div>
    </dxi-item>

    <dxi-item location="after">
      <div class="d-flex flex-row align-items-center">

      </div>
    </dxi-item>
  </dx-toolbar>

  <div class="d-flex flex-column">
    <div class="d-flex">

      <fieldset formGroupName="environnementGroup" class="mg-t-10">

        <div class="inputLibelle">
          <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
            <label>Libellé <span class="danger-color">*</span> : </label>
            <input
              formControlName="libelle"
              class="size-input"
              [required]="true"
              [(ngModel)]="yoEnvironnement.libelle"

              onfocus="removeAttribute('readonly')"
              pInputText/>
          </div>
        </div>

        <div class="inputLibelle">
          <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
            <label>Code <span class="danger-color">*</span> : </label>
            <input
              formControlName="code"
              class="size-input"
              [required]="true"
              [(ngModel)]="yoEnvironnement.code"

              onfocus="removeAttribute('readonly')"
              pInputText/>
          </div>
        </div>

      </fieldset>
    </div>
    <div class="d-flex overflow-auto" [style.height.px]="utils.getWindowAvailableHeight(250)">
      <table class="table">
        <tbody>
        <tr>
          <td rowspan="2">
            <div class="cellule autoscroll">

              <label for="listBoxSitesStatiques" class="bold">Tous les sites</label>

              <p-listbox id="listBoxSitesStatiques"
                         [options]="sitesStatiques"
                         filter="true"
                         pDraggable="sitesStatiques"
                         [listStyle]="{'max-height':'420px'}"
                         [style]="{'width':'300px'}"
                         [(ngModel)]="selectedsitesStatiques" [ngModelOptions]="{standalone: true}">
              </p-listbox>
            </div>

          </td>
          <td>
            <div class="cellule">
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesStatiques < 0)"
                      (click)="statiquesVersVisibles(true)"
                      [title]="messageAjouterAuxSitesLocaux()"

              >
                <i class="fa fa-plus white space"></i>
                Sites locaux
              </button>
              <br/>
              <br/>
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesPrincipaux < 0) "
                      (click)="visiblesVersStatiques(true)"
                      [title]="messageRetirerDesSitesLocauxOuReferents(true)"

              >
                <i class="fa fa-minus white space"></i>
                Sites locaux
              </button>
            </div>
          </td>
          <td>

            <div class="cellule autoscroll">
              <label class="bold">Sites locaux</label>
              <p-listbox
                [listStyle]="{'max-height':'250px'}"
                [style]="{'width':'300px'}"
                filter="true"
                [options]="sitesPrincipaux"
                pScrollableView="true"
                [(ngModel)]="selectedsitesPrincipaux"
                [ngModelOptions]="{standalone: true}"
                pDroppable="sitesStatiques">
              </p-listbox>
            </div>
          </td>
        </tr>
        <tr>

          <td>
            <div class="cellule">
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesStatiques < 0)"
                      (click)="statiquesVersVisibles(false)"
                      [title]="messageAjouterAuxSitesReferents()">
                <i class="fa fa-plus white space"></i>
                Sites référents
              </button>
              <br/>
              <br/>
              <button type="button" class="btn btn-primary"
                      [disabled]="(selectedsitesVisibles < 0)"
                      (click)="visiblesVersStatiques(false)"
                      [title]="messageRetirerDesSitesLocauxOuReferents(false)"

              >
                <i class="fa fa-minus white space"></i>
                Sites référents
              </button>
            </div>
          </td>
          <td>
            <div class="cellule autoscroll">
              <label class="bold">Sites référents</label>

              <p-listbox
                [listStyle]="{'max-height':'250px'}"
                [style]="{'width':'300px'}"
                [options]="sitesVisibles"
                filter="true"
                [(ngModel)]="selectedsitesVisibles"
                [ngModelOptions]="{standalone: true}">
              </p-listbox>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex mg-t-10">
      <div class="mr-auto"></div>
      <div class="d-flex">
        <yo-button type="submit" name="save" class="mg-r-5" buttonClass="cta-success"
                   tooltip="Enregistrer environnement" iconClass="fas fa-save"
                   [disabled]="!formGroupCtrl.valid"></yo-button>

        <yo-button name="delete" class="mg-r-30" buttonClass="cta-delete"
                   (onClick)="confirmDeleteEnvironnement(yoEnvironnement)"
                   tooltip="Supprimer l environnement {{yoEnvironnement.libelle}}" iconClass="fas fa-trash"
                   [disabled]="yoEnvironnement.id===0"></yo-button>
      </div>
    </div>

  </div>


</form>
