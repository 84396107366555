import {Injectable} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ProduitDTO} from '../../../core/dtos/produit-dto';

@Injectable()
export class TextMessageService {
  getProduitDeletionMessage = (produit: ProduitDeclinaisonDTO | ProduitDTO): string => {
    // if type is a Plat
    if (produit instanceof ProduitDeclinaisonDTO && produit?.fabrique || produit instanceof ProduitDTO && produit?.familleProduit.fabrique) {
      return `Confirmez-vous la suppression du produit ${produit?.libelle} ?<br/><br/>` +
        'Important : la suppression de ce plat est définitive. Cette suppression impactera aussi :<br/>' +
        '<ul>' +
        '    <li>les menus qui utilisent ce plat</li>' +
        '    <li>les commandes clients</li>' +
        '    <li>Les lignes du plan de production</li>' +
        '</ul>';
      // if type is a Denrée
    } else {
     return `Confirmez-vous la suppression du produit ${produit?.libelle} ?<br/><br/>` +
        'Important : la suppression de cette denrée est définitive. Cette suppression impactera aussi :<br/>' +
        '<ul>' +
        '    <li>les fiches techniques qui utilisent cette denrée</li>' +
        '    <li>l\'historique des lignes de commandes dans lesquelles sont utilisées les articles de cette denrée</li>' +
        '    <li>les listes de besoins</li>' +
        '    <li>les articles en stock</li>' +
        '    <li>l\'historique des sorties pour production</li>' +
        '    <li>l\'éventuelle liaison avec les lots marchés</li>' +
        '</ul>';
    }

  };
}
