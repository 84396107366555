import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DATEPICKER_FR, FP_ROUTES, FS_ROUTES, HELP_FOLDERS, UI_COLORS} from "../../../../core/constants";
import {PlanProductionDTO} from "../../../../core/dtos/plan-production-dto";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {PlanProductionDetailDTO} from "../../../../core/dtos/plan-production-detail-dto";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {ListeBesoinsDto} from "../../../../core/dtos/liste-besoins-dto";
import {ListeBesoinsService} from "../../../../core/services/entities/liste-besoins.service";
import {DATAGRID_ROW_TYPES} from "../../../../core/services/technique/devextreme.service";
import {UniteDeProduction__EquipeDTO} from "../../../../core/dtos/unite-de-production__equipe";
import {BulkDetailSupplier} from "../grid-details-pp/bulk-edition-details-dialog/bulk-edition-details-dialog.component";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {FamilleProduitDTO} from "../../../../core/dtos/famille-produit-dto";
import {DxTreeViewComponent} from "devextreme-angular";
import {ListeBesoinsDenreeDto} from "../../../../core/dtos/liste-besoins-denree-dto";

@Component({
  selector: 'yo-plan-production',
  templateUrl: './plan-production.component.html',
  styleUrls: ['./plan-production.component.scss']
})
export class PlanProductionComponent implements OnInit, OnDestroy {

  private subRoute: Subscription;

  localeFr = DATEPICKER_FR;

  planProduction: PlanProductionDTO;

  pathFile: string = HELP_FOLDERS.PLANS_PRODUCTION + '/plan-production';

  currentEffectif: string;

  selectedDetails: PlanProductionDetailDTO[] = [];

  displayOptionsGenerationListeBesoinDialog: boolean;

  newLabelListeBesoin: string;
  famillesPlats: FamilleProduitDTO[] = [];
  selectedFamillesPlats: number[] = [];

  filtersLbde: any;

  displayPrintSettingsFichesTechniques: boolean = false;
  datesConso: Date[] = [];
  datesFab: Date[] = [];
  teams: UniteDeProduction__EquipeDTO[] = [];
  dishesParents: any[] = [];

  displayOnlyMainRecipes: boolean = false;
  displayOptionsDisplayDialog: boolean = false;
  displayParentsDishesDialog: boolean = false;

  valueTeam: boolean = true;
  valuePlc: boolean = true;
  valueMeal: boolean = true;
  valueConsumptionDate: boolean = true;

  columnNameNbParts: string = 'nbParts';
  columnNameDateFab: string = 'dateFab';
  columnNameDateDlc: string = 'dlc';
  columnNameEquipe: string = 'equipe.libelle';
  private massiveTreatmentSub: Subscription;

  dataSourceTeams: any;

  idsFournisseursSelected: number[] = [];

  idsTachesSelected: number[] = [];

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  displayDenreesWithoutItems: boolean = false;

  displayDenreesWithRatiosMisconfigured: boolean = false;

  denreesWithoutItems: any[] = [];

  denreesWithRatiosMisconfigured: any[] = [];

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private prodSvc: ProductionService,
              private routeMapSvc: RoutemapService,
              private auth2Svc: Auth2Service,
              private lbSvc: ListeBesoinsService
              ) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.massiveTreatmentSub);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  onEditorPreparing = (event: any) => {
    if (event.parentType === 'dataRow' && event.editorName !== 'dxCheckBox') {
      const self = this;
      const currentRow = event.row.data;
      event.editorOptions.onValueChanged = function(args: any) {
        const dataField: string = event.dataField;
        const tasks: any = { numberDishesToUpdate: null, teamsDatesToUpdate: null };
        if (args.value !== args.previousValue) {
          if (dataField === self.columnNameNbParts) {
            const numberDishesToUpdate: any[] = [{ idLine: currentRow.id, subRecipe: currentRow.subRecipe, numbersDishes: args.value }];
            tasks.numberDishesToUpdate = numberDishesToUpdate;
          } else if (dataField === self.columnNameDateFab) {
            const teamsDatesToUpdate: any[] = [{ idLine: currentRow.id, subRecipe: currentRow.subRecipe, dateFab: args.value, dateDlc: null, idUdpEquipe: null }];
            tasks.teamsDatesToUpdate = teamsDatesToUpdate;
          } else if (dataField === self.columnNameDateDlc) {
            const teamsDatesToUpdate: any[] = [{ idLine: currentRow.id, subRecipe: currentRow.subRecipe, dateFab: null, dateDlc: args.value, idUdpEquipe: null }];
            tasks.teamsDatesToUpdate = teamsDatesToUpdate;
          }
          self.prodSvc.applyMassiveTreatment(self.planProduction.id, tasks)
            .subscribe(() => self.fetchPlanProductionSubscription());
        }
      };
    }
  }

  changeTeam = (value: number, idRow: number): void => {
    const data: PlanProductionDetailDTO = Object.assign({}, this.planProduction.rows.find(x => x.id === idRow));
    const teamsDatesToUpdate: any[] = [{ idLine: data.id, subRecipe: data.subRecipe, dateFab: null, dateDlc: null, idUdpEquipe: value }];
    const tasks: any = { teamsDatesToUpdate };
    this.prodSvc.applyMassiveTreatment(this.planProduction.id, tasks)
      .subscribe(() => this.fetchPlanProductionSubscription());
  }

  closeOptionsDisplayDialog = (): void => { this.displayOptionsDisplayDialog = false; }

  openOptionsDisplayDialog = (): void => { this.displayOptionsDisplayDialog = true; }

  closeParentsDishes = (): void => { this.dishesParents = []; this.displayParentsDishesDialog = false; }

  displayParentsDishes = (idSubDish: number, idDetailSubDishParent: number): void => {
    this.prodSvc.fetchParentsOfDish(this.planProduction.id, idSubDish, idDetailSubDishParent)
      .subscribe(response => {
        this.dishesParents = response.resultList;
        this.displayParentsDishesDialog = true;
      })
  }

  initSubscriptions = (): void => {
    this.fetchPlanProductionSubscription();
    this.massiveTreamtmentSubscription();
  }

  fetchFiltersListeBesoins = (): void => {
    this.lbSvc.filtersForListeBesoinFromPlanProduction(this.planProduction?.unitesDeProductions[0].id)
      .subscribe(response => {
        this.filtersLbde = response.one;
        this.famillesPlats = response.one.familles;
    });
  }

  applyGrouping = (): void => {
    this.fetchPlanProductionSubscription();
    this.closeOptionsDisplayDialog();
  }

  fetchPlanProductionSubscription = (): void => {
    const pathnameSplitted = window.location.pathname.split("/");
    const idPlanProduction = parseInt(pathnameSplitted[pathnameSplitted.length - 1].replace(")",""));
    if (!this.valueConsumptionDate && !this.valueMeal && !this.valuePlc && !this.valueTeam) {
      this.valueConsumptionDate = true;
      this.valueMeal = true;
      this.valuePlc = true;
      this.valueTeam = true;
    }
    const filters: any = { fetchOnlyMainRecipes: this.displayOnlyMainRecipes, groupByTeam: this.valueTeam, groupByPlc: this.valuePlc, groupByMeal: this.valueMeal, groupByConsumptionDate: this.valueConsumptionDate };
    this.prodSvc.findById(idPlanProduction, filters)
      .subscribe((response) => {
        this.planProduction = response.one;
        this.fetchUdpTeams();
        if(this.planProduction.typeEffectifEnum) {
          switch(this.planProduction.typeEffectifEnum) {
            case 'PREVISIONNEL':
              this.currentEffectif = 'Effectif prévisionnel';
              break;
            case 'FABRICATION':
              this.currentEffectif = 'Effectif de fabrication';
              break;
            case 'FACTURATION':
              this.currentEffectif = 'Effectif de facturation';
              break;
          }
        } else {
          this.currentEffectif = 'Effectif prévisionnel';
        }
        this.fetchFiltersListeBesoins();
      });
  }

  massiveTreamtmentSubscription = (): void => {
    this.massiveTreatmentSub = this.prodSvc.massiveTreatment$
      .subscribe((supplier: BulkDetailSupplier) => {
        const numberDishesToUpdate: any[] = [];
        const teamsDatesToUpdate: any[] = [];
        this.selectedDetails.forEach((detail: PlanProductionDetailDTO) => {
          if (supplier.nbParts !== undefined && supplier.nbParts !== null) numberDishesToUpdate.push({ idLine: detail.id, subRecipe: detail.subRecipe, numbersDishes: supplier.nbParts });
          if (supplier.dateFab || supplier.dlc || supplier.udpEquipe) teamsDatesToUpdate.push({ idLine: detail.id, subRecipe: detail.subRecipe, dateFab: supplier.dateFab, dateDlc: supplier.dlc, idUdpEquipe: supplier.udpEquipe?.id });
        });
        const tasks: any = { numberDishesToUpdate, teamsDatesToUpdate };
        this.prodSvc.applyMassiveTreatment(this.planProduction.id, tasks)
          .subscribe(() => this.fetchPlanProductionSubscription());
    })
  }

  dlcLessThanDateConsommation = (row: any): boolean => row.dlc < row.dateConso;

  fetchUdpTeams = (): void => {
    this.prodSvc.fetchUdpTeams(this.planProduction.id)
      .subscribe((res: ResponseWrapper<UniteDeProduction__EquipeDTO>) => {
        this.teams = res.resultList;
        this.dataSourceTeams = new DataSource({
          store: new ArrayStore({
            data: this.teams,
            key: 'id',
          }),
          group: 'zoneDeProductionLibelle',
        });
      });
  }

  genererListeBesoins = (): void => {
    this.displayOptionsGenerationListeBesoinDialog = true;
  }

  closeOptionsGenerationListeBesoin = (): void => {
    this.displayOptionsGenerationListeBesoinDialog = false;
  }

  applyFiltersForGenerateListeBesoins = (): void => {
    if (this.planProduction.id) {
      this.lbSvc.generateFromPlanProduction(this.planProduction.id, this.newLabelListeBesoin, { famillesProduitsIds: this.selectedFamillesPlats, fournisseursIds: this.idsFournisseursSelected, typesApprovisionnementIds: this.idsTachesSelected  })
        .subscribe((res: ResponseWrapper<ListeBesoinsDto>) => {
          if(res.one.denreesWithoutItems && res.one.denreesWithoutItems.length) {
            this.closeOptionsGenerationListeBesoin();
            this.displayDenreesWithoutItems = true;
            this.denreesWithoutItems = res.one.denreesWithoutItems.map(pd => ({ ...pd, url: `/gestion-production/pp/plans-de-production(secondary:gestionproduits/produit/false/${pd.produit.id}/articles)?idUdp=${pd.idUniteProduction}` }) );
          } else {
            this.routeMapSvc.goToSecondaryRoute(['gestion-processus', res.one.workflowInstance.id, 'lb-details', 'articles']);
          }
      });
    }
  }

  onChangeFournisseur = ($event: any): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.idsFournisseursSelected.filter(id => id === item.id).length) {
          this.idsFournisseursSelected.push(item.id);
        }
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.idsFournisseursSelected.filter(id => id === item.id).length) {
          this.idsFournisseursSelected = this.idsFournisseursSelected.filter(idSite => idSite !== item.id);
        }
      });
    }
  }

  onChangeTache = ($event: any): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.idsTachesSelected.filter(id => id === item.id).length) {
          this.idsTachesSelected.push(item.id);
        }
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.idsTachesSelected.filter(id => id === item.id).length) {
          this.idsTachesSelected = this.idsTachesSelected.filter(id => id !== item.id);
        }
      });
    }
  }

  onChangeParentFamilleProduitSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.selectedFamillesPlats = this.getIdFamilleProduitListSelected(selectedNodeKeys);
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.famillesPlats.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = [...result].concat(this.reflexionSearchId([...this.famillesPlats], fp.id));
        }
      })
    });

    result = [...result].concat(idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = [...idResultList].concat(this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  isEditable = (): boolean => this.auth2Svc.isSiteLocal(this.planProduction?.site?.id) && this.valueConsumptionDate && this.valueMeal && this.valuePlc && this.valueTeam;

  isColumnTeamVisible = (): boolean => this.planProduction?.rows[0]?.equipe !== undefined && this.planProduction?.rows[0]?.equipe !== null;

  isColumnPlcVisible = (): boolean => this.planProduction?.rows[0]?.pointDeLivraisonClient !== undefined && this.planProduction?.rows[0]?.pointDeLivraisonClient !== null;

  isColumnMealVisible = (): boolean => this.planProduction?.rows[0]?.repas !== undefined && this.planProduction?.rows[0]?.repas !== null;

  isColumnConsumptionDateVisible = (): boolean => this.planProduction?.rows[0]?.dateConso !== undefined && this.planProduction?.rows[0]?.dateConso !== null;

  getToolTipEffectifsZero = (cell: any): string => {
    return 'Attention, les effectifs sont à zéro';
  }

  getToolTipDlc = (cell: any): string => {
    let tooltip: string = 'Attention, la DLC est inférieure à la date de consommation';
    const offsetDay =  (Math.abs(cell.row.data.dateFab - cell.row.data.dlc))/(1000*60*60*24);
    if (offsetDay > cell.row.data.produitDeclinaison.dlc) {
      const datePreco = new Date(cell.row.data.dateFab);
      datePreco.setDate(datePreco.getDate() + cell.row.data.produitDeclinaison.dlc);
      tooltip += 'Attention, la DLC saisie est supérieure à la DLC autorisée par le plat (DLC préconisée : ' + datePreco.toLocaleDateString() + ')';
    }
    return tooltip;
  };

  goToProductPage = (idProduct: number) => this.routeMapSvc.goToSecondaryRoute([FP_ROUTES.GPR, 'produit', 'true', idProduct, 'fichetechnique']);

  openBulkEditionDetailDialog = () => {
    this.prodSvc.announceOpenMassiveTreatmentDialog({
      udpEquipeList: this.teams
    });
  };

  openPreparationConditionnement = (refresh?: boolean) => {
    if (this.planProduction.id)
      this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_PREPARATION_CONDITIONNEMENTS, this.planProduction.id, refresh ? 'true' : 'false', 'calcul']);
  };

  generateSortiesPlanProduction = (): void => {
    this.prodSvc.generateSortiesPlanProduction(this.planProduction.id)
      .subscribe((res) => {
        if(res.one.denreesWithoutItemsForSorties && res.one.denreesWithoutItemsForSorties.length) {
          this.displayDenreesWithoutItems = true;
          this.denreesWithoutItems = res.one.denreesWithoutItemsForSorties.map(pd => ({ ...pd, url: `/gestion-production/pp/plans-de-production(secondary:gestionproduits/produit/false/${pd.produit.id}/articles)?idUdp=${pd.idUniteProduction}` }) );
        } else if (res.one.denreesWithRatiosMisconfigured?.length) {
          this.displayDenreesWithRatiosMisconfigured = true;
          this.denreesWithRatiosMisconfigured = res.one.denreesWithRatiosMisconfigured.map(d => { d.url = `/gestionproduits(secondary:gestionproduits/produit/false/${d.produitId}/articles/infos)?idUdp=${d.uniteDeProductionId}`; return d; });
          console.log('denreesWithRatiosMisconfigured ', this.denreesWithRatiosMisconfigured);
        } else {
          this.routeMapSvc.goToSecondaryRoute(['steps', 'sorties-production', this.planProduction.id]);
        }
      });
  }

  previousStep = () => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'selection-filters', this.planProduction.id]);
  }

  onSelectionChanged = (event: any): void => this.selectedDetails = event.selectedRowsData;

  openPrintSettingsFichesTechniques = () => {
    this.displayPrintSettingsFichesTechniques = false;
    this.prodSvc.getPrintSettings(this.planProduction.id)
      .subscribe(response => {
      if (response) {
        const res: any = response.one;
        this.datesFab = res.datesFabrication;
        this.datesConso = res.datesConsommation;
        this.displayPrintSettingsFichesTechniques = true;
      }
    });
  }

  onEditingStart = (event: any) => {
    console.log(event.data, event.column.dataField);
  };

  onCellPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER && this.isEditable()) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }

    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnNameNbParts && event.data?.nbPartsModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnNameEquipe && event.data?.idUdpEquipeModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnNameDateFab && event.data?.dateFabModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnNameDateDlc && event.data?.dateDlcModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
  };

  closeDenreesWithoutItems() {
    this.displayDenreesWithoutItems = false;
  }

  closeDenreesWithRatiosMisconfigured() {
    this.displayDenreesWithRatiosMisconfigured = false;
  }

}
