import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {CommandesService} from '../../core/services/gestion-commandes/commandes.service';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {UnitesDeProductionService} from '../../core/services/entities/unites-de-production.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {BoncfService} from '../../core/services/entities/boncf.service';
import {BoncfStatutService} from '../../core/services/entities/boncf-statut.service';
import {UniteDeProductionDTO} from '../../core/dtos/unite-de-production-dto';
import {BoncfStatutDTO} from '../../core/dtos/boncf-statut-d-t-o';
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {AppellationDTO} from '../../core/dtos/appellations-dto';
import {TYPE_LIST_UDP} from "../../core/constants";

@Injectable({
  providedIn: 'root'
})
export class TableauDeBordResolverService implements Resolve<TableauDeBordSupplier>{

  constructor(private commandesSvc:CommandesService,
              private unitesDeProductionSvc:UnitesDeProductionService,
              private bcStatusSvc: BoncfStatutService,
              private bcfSvc: BoncfService,
              public appellationsSvc: AppellationsService,
              private gds:GenericDatagridService) { }


  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TableauDeBordSupplier> | Promise<TableauDeBordSupplier> | TableauDeBordSupplier => {


    const unitesDeProduction$ = this.unitesDeProductionSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal);
    const statutsCommandeList$ = this.gds.search(this.bcStatusSvc.filterStatus());
    const appellations$ = this.gds.getAll(this.appellationsSvc.getEntityName(), this.appellationsSvc.getSort(), true);
    // const montantAchatList$ = this.bcfSvc.getMontant();
    // const contrats$ = this.contratMenuSvc.getAllContratsMenus();

    const all$ = combineLatest([unitesDeProduction$,statutsCommandeList$, appellations$]);

    return all$.toPromise().then(response=>{

      const unitesDeProduction = response[0].resultList;
      const statuts = response[1].resultList;
      const appellations = response[2].resultList;


      const tbs = new TableauDeBordSupplier();
      tbs.unitesProduction = unitesDeProduction;
      tbs.statuts = statuts;
      tbs.appellations = appellations;



      return tbs;

    });

  };
}

export class TableauDeBordSupplier{

  contrats:ContratmenuDTO[];
  unitesProduction: UniteDeProductionDTO[];
  statuts: BoncfStatutDTO[];
  appellations: AppellationDTO[];

}
