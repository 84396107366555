import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FacturationService} from "../../../core/services/entities/facturation.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {DxDataGridComponent} from "devextreme-angular";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../core/constants";
import {Subscription} from "rxjs";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-details-invoice',
  templateUrl: './dialog-details-invoice.component.html',
  styleUrls: ['./dialog-details-invoice.component.scss']
})
export class DialogDetailsInvoiceComponent implements OnInit, OnDestroy {

  displayInvoice: boolean = false;

  currentInvoice: any;

  fullScreenInvoice: boolean = true;

  chooseDate: boolean = false;

  changeShippingFeesEnabled: boolean = false;

  @ViewChild("gridDetails") gridDetails: DxDataGridComponent;

  subOpenDialog: Subscription;

  subDetails: Subscription;

  constructor(public auth2Svc: Auth2Service,
             private facturationSvc: FacturationService,
              public utilsSvc: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subDetails);
  }

  ngOnInit(): void {
    this.displayInvoiceDialog();
  }

  displayInvoiceDialog = (): void => {
    this.subOpenDialog = this.facturationSvc.openDialogDetailsInvoice$
      .subscribe((currentInvoice) => {
        this.subDetails = this.facturationSvc.fetchById(currentInvoice.id)
          .subscribe((response) => {
            this.currentInvoice = response.one;
            this.displayInvoice = true;
            this.refreshDataSourceDetails();
          });
      });
  }

  closeInvoiceDialog = (): void => {
    this.displayInvoice = false;
    this.currentInvoice = undefined;
  }

  toggleFullScreenInvoice = (): void => {
    this.fullScreenInvoice = !this.fullScreenInvoice;
  }

  joinLitigesOrMotifs = (values: any[], isMotifNonConforme: boolean): string => {
    return isMotifNonConforme ? values?.map(l => l.libelleMotifNonConformite).join(',') : values?.map(l => l.libelleLitige).join(',');
  }

  refreshDataSourceDetails = (): void => {
    this.gridDetails?.instance?.refresh();
  }

  switchChooseDate = ($event): void => {
    this.chooseDate = !this.chooseDate;
    this.currentInvoice.dateBill = new Date($event.value).getTime();
    this.facturationSvc.update(this.currentInvoice)
      .subscribe((response) => this.facturationSvc.announceRefreshInvoices());
  }

  toggleShippingFeesState = (): void => {
    this.changeShippingFeesEnabled = !this.changeShippingFeesEnabled;
  }

  onCellPreparedDetails = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }

    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === 'realQuantityInvoiced.value' && event.data?.quantityInvoicedModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === 'realUnitPrice.value' && event.data?.unitPriceModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
  };

  updateInvoice = ($event: any, fieldname: string, idLine: number): void => {
    const newValue = parseInt($event.value);
    if (newValue !== $event.previousValue) {
      const idxLine = this.currentInvoice.rows.findIndex(row => row.id === idLine);
      if (fieldname === 'realQuantityInvoiced.value')
        this.currentInvoice.rows[idxLine].quantityInvoicedModifiedManually = true;
      if (fieldname === 'realUnitPrice.value')
        this.currentInvoice.rows[idxLine].unitPriceModifiedManually = true;
      if (fieldname === 'realShippingFees.value') {
        this.currentInvoice.shippingFeesModifiedManually = true;
        this.toggleShippingFeesState();
      }

      this.facturationSvc.update(this.currentInvoice)
        .subscribe(() => {
          this.facturationSvc.fetchById(this.currentInvoice.id)
            .subscribe((response) => {
              this.currentInvoice = response.one;
              this.facturationSvc.announceRefreshInvoices();
            });
        });
    }
  }

  chooseDateInput = (): void => {
    this.chooseDate = true;
  }

  printInvoice = (): void => {
    this.facturationSvc.printInvoices([this.currentInvoice.id]);
  }

  validInvoice = async(): Promise<void> => {
    const question: string = 'Voulez-vous vraiment valider la facture ?';
    const response = await confirm(question, 'Validation de la facture');
    if (response) {
      this.facturationSvc.validInvoices([this.currentInvoice.id]).subscribe((response) => {
        this.facturationSvc.announceRefreshInvoices();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'La validation a été effectuée avec succès');
      });
    }
  }

}
