import {Component, OnInit} from '@angular/core';
import {FamillesPlansAlimentairesService} from '../../core/services/entities/familles-plans-alimentaires.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-familles-plans-alimentaires',
  templateUrl: './familles-plans-alimentaires.component.html',
  styleUrls: ['./familles-plans-alimentaires.component.scss']
})
export class FamillesPlansAlimentairesComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/familles-plans-alimentaires';

  constructor(public famillePlanAlimentaireSvc: FamillesPlansAlimentairesService) { }

  ngOnInit() {
  }

}
