<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'750px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <p-listbox
          id="site"
          filter="true"
          [listStyle]="{'height':'150px'}"
          [style]="{'max-width':'100%'}"
          defaultLabel="Selectionner le site..."
          [options]="findAllLocalSites()"
          optionLabel="libelle"
          formControlName="site"
        >
        </p-listbox>
      </div>
      <ng-container
        *ngTemplateOutlet="tplError;context:{form:form,property:'site',label:'Site'}"></ng-container>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Conditionnement <span class="danger-color">*</span>
      </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="conditionnements"
                     [style.width]="'100%'"
                     [listStyle]="{'height':'150px'}"
                     [filter]="true"
                     checkbox="true"
                     formControlName="conditionnement"
                     optionLabel="libelle">
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'conditionnement',label:'Conditionnement'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Variante <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="variantes"
                     [multiple]="true"
                     [style.width]="'100%'"
                     [listStyle]="{'height':'150px'}"
                     [filter]="true"
                     checkbox="true"
                     formControlName="variante"
                     optionLabel="libelle">
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'variante',label:'Variante'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end" style="margin-top: 15px">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        label="Enregistrer"
        buttonClass="cta-success"
        [disabled]="!canSave()">
      </yo-button>
      <yo-button
        class="mg-l-5"
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        iconClass="fas fa-times"
      ></yo-button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
