import {Component, OnInit} from '@angular/core';
import {UnitesDeMesuresService} from '../../core/services/entities/unites-de-mesures.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-unites-de-mesures',
  templateUrl: './unites-de-mesures.component.html',
  styleUrls: ['./unites-de-mesures.component.scss']
})
export class UnitesDeMesuresComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/unites-de-mesure';

  constructor(public uniteDeMesureSvc : UnitesDeMesuresService) { }

  ngOnInit() {
  }

}
