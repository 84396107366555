<div class="d-flex flex-column">
  <div class="flex-grow-1" style="overflow-y:auto;overflow-x: hidden;">
    <dx-data-grid
      [dataSource]="allergeneList"
      keyExpr="id"
      [height]="catalogueAchatSvc.getMaxContentHeightDialogArticle(cads)"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [selectedRowKeys]="[]"
      [showBorders]="true"
      #grid
    >

      <dxo-paging [pageSize]="50"></dxo-paging>

      <dxo-selection
        [selectAllMode]="true"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      ></dxo-selection>

      <dxi-column dataField="id" caption="Logo" cellTemplate="logoCellTemplate" alignment="left"
                  width="80"></dxi-column>
      <dxi-column dataField="libelle" caption="Allergene" cellTemplate="allergeneCellTemplate" width="350"></dxi-column>
      <dxi-column dataField="code" caption="Code" cellTemplate="codeCellTemplate"></dxi-column>

      <!--  LOGO CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'logoCellTemplate'">
        <yo-img-entity [entityName]="allergeneSvc.getEntityName()"
                       [entityId]="cell.row.data.id"
                       [ordre]="1"
                       [height]="48"
                       format="thumb"
        ></yo-img-entity>
      </div>

      <!--  ALLERGENE CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'allergeneCellTemplate'">
        {{cell.row.data.libelle | uppercase}}
      </div>

      <!--  CODE CELL TEMPLATE-->
      <div *dxTemplate="let cell of 'codeCellTemplate'">
        {{cell.row.data.code | uppercase}}
      </div>
    </dx-data-grid>

  </div>

  <div>
    <hr>

  <div class="d-flex">

    <div class="mr-auto">
      <label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i>Lors de l'enregistrement, les allergenes
        seront remontés sur la denrée. Aussi, ils le seront lors de la mise à jour journalière des denrés.</label>
    </div>
    <div class="mg-r-5">
      <yo-button *ngIf="this.catalogueAchat && !this.catalogueAchat.fournisseurOwnerLibelle"
              iconClass="fas fa-save"
              (onClick)="saveAllergenes()"
              tooltip="Enregistrer"
              [disabled]="!auth2Svc.isSiteLocal(uniteDeProduction.site.id)"
              buttonClass="cta-success"></yo-button>
    </div>
    <div class="mg-r-5">
      <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
              buttonClass="cta-inner-delete"></yo-button>
    </div>

  </div>
  </div>

</div>
