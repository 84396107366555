<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-5"
        label="CRÉER"
        iconClass="fa fa-plus"
        tooltip="Créer une proposition de commande"
        (onClick)="createCommande.toggle($event)" disabled>
      </yo-button>
      <p-menu appendTo="body" #createCommande [popup]="true" [model]="createCommandeOptionList"></p-menu>

      <p-calendar
        firstDayOfWeek="1"
        [locale]="fr"
        (onSelect)="onChangeFilterDateLivraison()"
        (onTodayClick)="onChangeFilterDateLivraison()"
        (onClearClick)="onChangeFilterDateLivraison()"
        placeholder="Filtrer par dates de livraison..."
        title="Filtrer par dates de livraison"
        appendTo="body"
        showButtonBar="true"
        class="mg-r-5"
        selectionMode="range"
        [(ngModel)]="filterDatesLivraisons"
        dateFormat="dd/mm/yy"
        readonlyInput="true"
        [showWeek]="true">
      </p-calendar>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
        <dx-tag-box
          [width]="370"
          [multiline]="false"
          [dataSource]="filterItemsFournisseur"
          placeholder="Filtrer par fournisseur..."
          displayExpr="value"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeFilterFournisseurs($event)"
        >
        </dx-tag-box>
      </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="370"
        [multiline]="false"
        [dataSource]="filterItemsUdp"
        placeholder="Filtrer par unité de production..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        (onValueChanged)="onChangeFilterUdps($event)"
      >
      </dx-tag-box>
    </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="450"
        [dataSource]="filterItemsListeBesoin"
        placeholder="Filtrer par liste de besoins..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [multiline]="false"
        (onValueChanged)="onChangeFilterListesBesoins($event)"
      >
      </dx-tag-box>
    </span>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
     <span class="mg-r-5">
       <yo-button
         iconClass="fas fa-play"
         tooltip="Passer à l'étape suivante"
         [disabled]="isActionDisabled"
         (onClick)="menu.toggle($event)"></yo-button>
       <p-menu appendTo="body" #menu [popup]="true" [model]="nextSteps"></p-menu>
    </span>

      <span class="mg-r-5">
      <yo-button
        iconClass="fa fa-envelope"
        (onClick)="openPopupSendMail()"
        [disabled]="isActionDisabled"
        tooltip="Envoyer par mail les commandes aux fournisseurs sélectionnés">
      </yo-button>
    </span>

      <span class="mg-r-5">
      <yo-button
        iconClass="fas fa-print"
        (onClick)="printPDFCommandes()"
        [disabled]="isActionDisabled"
        tooltip="Imprimer les propositions sélectionnées au format pdf">
      </yo-button>
    </span>

      <span class="mg-r-5">
      <yo-button
        iconClass="fas fa-code-merge"
        (onClick)="prepareToMergeBonsCf()"
        [disabled]="isActionDisabled"
        tooltip="Fusionner les propositions sélectionnées">
      </yo-button>
    </span>

      <span class="mg-r-5">
      <yo-button
        iconClass="fa fa-trash"
        buttonClass="cta-inner-delete"
        (onClick)="deleteBonCfs()"
        [disabled]="isActionDisabled"
        tooltip="Supprimer les propositions sélectionnées">
      </yo-button>
    </span>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  (onSelectionChanged)="checkDisabledButtons()"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  #grid>
  <dxo-selection [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="120" cellTemplate="templateActions"
              [allowSorting]="false"></dxi-column>
  <dxi-column dataField="numeroBcf" caption="Bon de commande" cellTemplate="templateBonCf">

  </dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande">

  </dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditFournisseurDialog(cell.row.data,false)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'fas fa-edit':'fas fa-eye'"
                    [yoTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Modifier la commande':'Voir la commande'">
    </yo-cell-button>
    <yo-button
      buttonClass="cta-inner-edit-or-watch"
      iconClass="fas fa-clone"
      tooltip="Dupliquer la proposition sélectionnée"
      size="small"
      (onClick)="prepareToDuplicateBonCf(cell.row.data)">
    </yo-button>
    <yo-button
      buttonClass="cta-inner-edit-or-watch"
      iconClass="fas fa-truck"
      tooltip="Réassigner la proposition sélectionnée"
      size="small"
      (onClick)="openReassignDialog(cell.row.data)">
    </yo-button>
  </div>
  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a title="Aller sur la liste de besoins"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateBonCf'">
    <span class="mg-r-5">{{cell.row.data.numeroBcf}}</span>
    <span class="mg-r-5" *ngIf="cell.row.data.mailHisto">
      <label
        [title]="mailHistoSvc.getLabelStatut(cell.row.data.mailHisto.statut)"
        class="badge mg-r-5 cursor"
        [class.badge-warning]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
        [class.badge-primary]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.ENVOYE"
        [class.badge-success]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.LU"
        [class.badge-danger]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_ERREUR"
        (click)="openHistoriqueMails(cell.row.data)">
        <span class="fa-layers fa-fw">
        <i class="fas fa-envelope mg-r-5"></i>
        <span class="fa-layers-counter">{{mailHistoSvc.getShortLabel(cell.row.data.mailHisto.statut)}}</span>
        </span>
      </label>
    </span>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} proposition(s)">
  </dxo-pager>

</dx-data-grid>

<!--DIALOG MODIFIER UNE PROPOSITION DE COMMANDE-->
<yo-bc-edit-fournisseur [displayDialog]="displayEditDialog" (onSave)="onPropositionAdded()"
                        (onClose)="displayEditDialog=$event" [bonCf]="bonCf"
                        [lignesBonCf]="lignesBonCf"
                        [bonCfs]="propositionsCommandes">
</yo-bc-edit-fournisseur>


<!--DIALOG CRÉER UNE NOUVELLE PROPOSITION DE COMMANDE PAR FOURNISSEUR-->
<yo-create-proposition-fournisseur [displayDialog]="displayAddDialog" [uniteDeProductionList]="uniteDeProductionList"
                                   (onSave)="newPropositionCommande($event)"
                                   (onClose)="displayAddDialog=$event"></yo-create-proposition-fournisseur>


<yo-create-proposition-denree></yo-create-proposition-denree>

<yo-merge-boncf
  #mergeComponent
  [displayDialog]="displayMergeDialog"
  [selectedBonCfsToMerge]="selectedBonCfsToMerge"
  [uniteDeProduction]="selectedUniteDeProductionToMerge"
  (onSave)="mergeBonsCf($event)"
  (onClose)="onCloseMerge($event)"></yo-merge-boncf>

<yo-duplication-boncf
  #duplicationComponent
  [displayDialog]="displayDuplicationDialog"
  [selectedBonCfToDuplicate]="selectedBonCfToDuplicate"
  [uniteDeProduction]="selectedUniteDeProductionToDuplicate"
  (onSave)="duplicateBNonCf($event)"
  (onClose)="onCloseDuplication($event)"></yo-duplication-boncf>

<yo-reassignBcFournisseurDialog></yo-reassignBcFournisseurDialog>

<dx-popup
  [showTitle]="true"
  title="Éléments sans correspondance "
  [visible]="displayNoCorrespondancePopup"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeNoCorrespondancePopup(false)"
>
  <label>Les éléments non présents dans cette liste ont bien été réassignés.</label>
  <dx-data-grid
    [dataSource]="noCorrespondanceList"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="referenceArticle" caption="Réf. article" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxi-column dataField="description" caption="Description" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
  <div class="d-flex mg-t-10 justify-content-end">
    <yo-button
      class="mg-l-5 cta-edit-or-watch"
      label="Ouvrir le nouveau bon de commande"
      (onClick)="closeNoCorrespondancePopup(true)"
      iconClass="fas fa-external-link">
    </yo-button>
  </div>
</dx-popup>

<yo-send-mail
  [displayDialog]="displayPopupSendMail"
  (onSave)="sendEmail($event)"
  (onClose)="onCloseSendMail($event)"
></yo-send-mail>
