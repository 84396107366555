import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {FormControl, FormGroup} from "@angular/forms";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {EquipeDTO} from "../../../core/dtos/equipe-dto";
import {EquipesService} from "../../../core/services/entities/equipes.service";

@Component({
  selector: 'yo-equipeDialog',
  templateUrl: './equipe-dialog.component.html',
  styleUrls: ['./equipe-dialog.component.scss']
})
export class EquipeDialogComponent implements OnInit {

  subOpenDialog: Subscription

  equipe: EquipeDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle: String = 'Modification d\'une équipe';

  sitePlaceholder: String = 'Selectionner le site...';

  displayDialog = false;

  idSelectedSite: number;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public equipesSvc: EquipesService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.equipesSvc.openDialogEdition$
      .subscribe((cdt: EquipeDTO) => {
        this.displayDialog = true;
        if (!cdt) {
          this.equipe = new EquipeDTO();
          this.equipe.id = 0;
          this.dialogTitle = 'Création d\'une équipe';
        } else {
          this.equipe = cdt;
          this.dialogTitle = 'Modification d\'une équipe';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let newEquipe: EquipeDTO = {...this.equipe};
      newEquipe.site = {id: this.idSelectedSite} as SiteDTO;
      newEquipe.libelle = this.form.controls['libelle'].value;
      newEquipe.code = this.form.controls['code'].value;
      newEquipe.actif = this.form.controls['actif'].value;
      this.equipesSvc.save(newEquipe)
        .subscribe(response => {
          const isUpdate = newEquipe.id !== null && newEquipe.id !== undefined && newEquipe.id !== 0;
          this.equipesSvc.announceEquipeSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de l'équipe réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.equipe?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.equipe?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.equipe?.libelle),
      code: new FormControl(this.equipe?.code),
      actif: new FormControl(this.equipe?.actif != null ? this.equipe.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.equipesSvc.canModify(this.equipe);
  }

}
