<dx-toolbar class="yo-toolbar">
  <dxi-item location="before" *ngIf="canEdit()">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-15"
        label="AJOUTER"
        tooltip="Ajouter un ou plusieurs plat(s)"
        iconClass="fa fa-plus"
        (onClick)="openDialogAjoutPlats()">
      </yo-button>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canEdit() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="platsList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate"></dxi-column>
  <dxi-column dataField="produit.site.libelle" caption="Site (lié au produit)"
              cellTemplate="siteCellTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="udp__modeleConditionnementPlat.libelleUniteDeProduction"
              caption="Unité de production"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="produit.libelle" caption="Plat"
              [filterOperations]="['contains']"
              cellTemplate="produitCellTemplate"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="produit.produitDeclinaisons"
              caption="Déclinaison(s)"
              cellTemplate="pdCellTemplate"
              [allowFiltering]="false">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">

    <yo-cell-button (yoNavigation)="visualize(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fas fa-calculator"
                    yoTooltip="Visualiser l'implémentation du modèle">
    </yo-cell-button>

  </div>

  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteId]="cell.row.data.produit.site.id" [siteLibelle]="cell.row.data.produit.site.libelle"
             [yoDelayMsTooltip]="1500"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'produitCellTemplate'">
    <a title="Aller sur la fiche technique"
       (click)="linkToFicheTechnique(cell.row.data.produit.id, cell.data.udp__modeleConditionnementPlat)">
      <span class="cursor save-color">{{ cell.row.data.produit.libelle }}</span>
    </a>
  </div>

  <div class="flex-wrap" *dxTemplate="let cell of 'pdCellTemplate'">
    <label *ngFor="let pd of cell.row.data.produit.produitDeclinaisons" class="badge badge-secondary zoom cursor">
      {{ pd?.declinaison?.libelle }}
    </label>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-mcp-plats></yo-dialog-ajout-mcp-plats>

<dx-popup
  [showTitle]="true"
  [title]="'Plats déjà utilisés par un modèle de conditionnement plat'"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [fullScreen]="true"
  container=".dx-viewport"
  [(visible)]="openDialogDisplayProductsAlreadyUsed"
>
  <dx-data-grid
    [dataSource]="productsAlreadyUsed"
    keyExpr="id"
    width="100%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true">
    <dxi-column dataField="libelle" caption="Libellé" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
  </dx-data-grid>
</dx-popup>
