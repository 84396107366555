<dx-data-grid
  [dataSource]="customStore"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  [remoteOperations]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="produitArticle.libelle" caption="Article" [width]="700"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <dxi-column dataField="referenceArticle"
              caption="Réf.Fournisseur"
              [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="uniteDeCommande.libelle" caption="Unité de commande"
              [width]="350"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <dxo-filter-row
    [visible]="true"></dxo-filter-row>

  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[20, 50, 100]"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Articles">
  </dxo-pager>

  <!--    TEMPLATE-->

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="deleteArticle(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoDisabled]="isDisabledDeleteBtn(cell.row.data)"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer l'article">
    </yo-cell-button>
  </div>

</dx-data-grid>
