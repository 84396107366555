import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DeclinaisonDTO} from '../../dtos/declinaison-dto';
import {DialogMsgSupplier, Paragraphe} from '../../suppliers/dialog-msg-supplier';
import {TypesProduitService} from './types-produit.service';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldDropdownSupplierSiteDTO} from '../../suppliers/form-field-dropdown-supplier-siteDTO';
import {startCase as _startCase} from 'lodash';


@Injectable()
export class DeclinaisonsService extends GenericHandler<DeclinaisonDTO> {


  static sTypeProduitSvc ;

  declinaisonEnvironnement: DeclinaisonDTO[];

  constructor(utils: UtilsService, private gds: GenericDatagridService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private typeProduitSvc: TypesProduitService) {
    super(utils, auth2Svc, router, http, title);


    DeclinaisonsService.sTypeProduitSvc= this.typeProduitSvc;
    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });
  }


  /**
   * Filtrer les declinaisons de type plat ou denree
   * @param declinaisons
   * @param isFabrique 0=denree, 1=plat
   */
  getDeclinaisonList(declinaisonList: DeclinaisonDTO[], isFabrique: boolean): DeclinaisonDTO[] {

    let declinaisons = [];

    if (!this.utils.isCollectionNullOrEmpty(declinaisonList)) {
      const arr = declinaisonList.filter(item => item.typeProduit.fabrique === isFabrique);
      if (!this.utils.isCollectionNullOrEmpty(arr)) {
        declinaisons = arr;
      }
    }

    return declinaisons;
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {

    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des déclinaisons`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Les déclinaisons pour les plats`;
    p1.lines = [
      ` On y affecte généralement les types de convive : Maternelle, Adolescent, Adultes, Senior, ...`,
    ];


    let p2: Paragraphe = new Paragraphe();
    p2.title = `Les déclinaisons pour les denrées`;
    p2.lines = [
      `On y affecte parfois le conditionnement.`,
      `Exemple : la denrée 'haricots verts' peut être déclinée en 'haricots verts surgelés'...`,
    ];

    dms.content = {
      intro: `Les déclinaisons se divisent en 2 parties.`,
      paragraphes: [p1]

    };
    return dms;

  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES DÉCLINAISONS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE DÉCLINAISON';
  }

  getEntityName(): string {
    return 'declinaison';
  }

  formFieldSite: FormFieldBaseSupplier<any> ;
  formFieldTypeProduit: FormFieldDropdownSupplier;

  getFields(dto: DeclinaisonDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Declinaison est active
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new DeclinaisonDTO();
      dto.actif = true;
    }

    // DeclinaisonDTO en création
    if (dto.id == 0) {
      this.formFieldSite = new FormFieldDropdownSupplierSiteDTO({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        // type: 'linkedField',
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        // value: this.getSelectedItem(dto.site,        this.initArray(this.auth2Svc.utilisateur.siteListLocaux),
        order: 1,
        options: this.optionsSites,
        required: true,
      });
      // DeclinaisonDTO en modification ou consultation
    } else {
      this.formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      });

    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.formFieldSite,

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType: 'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType: 'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),

      this.formFieldTypeProduit = new FormFieldDropdownSupplier({
        key: 'typeProduit',
        label: 'Type de produit',
        readonly: !this.canModify(dto),
        type: 'externalRef',
        value:  this.utils.preSelectSingleList(this.typeProduitSvc.typeProduitList,dto.typeProduit),
        order: 5,
        options: this.typeProduitSvc.typeProduitList,
        required: true,
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType: 'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getAllFromEnvironnement(): void {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.declinaisonEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.declinaisonEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.declinaisonEnvironnement);

      });
  }

  createEmptyDTO(): DeclinaisonDTO {
    return new DeclinaisonDTO();
  }


  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA DÉCLINAISON '${data.libelle.toUpperCase()}'`;
  }


}
