import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {catchError, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {StocksService} from '../../core/services/gestion-stock/stocks.service';

@Component({
  selector: 'yo-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit, OnDestroy {

  subFeature: Subscription;
  stockFeatures: MenuItem[] = [];
  feature: string;

  constructor(public utils: UtilsService,
              private stocksSvc: StocksService) {
  }

  ngOnInit() {
    // fonctionnalité du stock (inventaire, historique...)
    this.featureSubscription();
  }

  featureSubscription() {
    this.subFeature = this.stocksSvc.feature$
      .pipe(
        map(value => {this.feature = value;}),
        catchError(err => this.utils.handleError(err)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFeature);
  }
}
