import {Component} from '@angular/core';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {AppellationsService} from '../../core/services/entities/appellations.service';
import {UtilsService} from '../../core/utils/utils.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-appellations',
  templateUrl: './appellations.component.html',
  styleUrls: ['./appellations.component.scss']
})
export class AppellationsComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/appellations';

  constructor(public appellationsService: AppellationsService,
              public utils:UtilsService,
              public gds: GenericDatagridService) {
  }

}
