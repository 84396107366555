import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {DecoupagesRepasService} from '../../../core/services/entities/decoupages-repas.service';
import {RepasService} from '../../../core/services/entities/repas.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {GestionGemrcnService, PlanningSupplier} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {combineLatest, Observable} from 'rxjs';
import {sortBy as _sortBy} from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class PlanningResolverService implements Resolve<PlanningSupplier> {

  constructor(private gds: GenericDatagridService,
              private decoupageRepasSvc: DecoupagesRepasService,
              private repasSvc: RepasService,
              private utils: UtilsService,
              private ggSvc: GestionGemrcnService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlanningSupplier> | Promise<PlanningSupplier> | PlanningSupplier => {

    const vns = new PlanningSupplier();

    const idModeleNutri: number = +route.parent.paramMap.get('idModeleNutri');
    const repasList$ = this.gds.getAll(this.repasSvc.getEntityName(), this.repasSvc.getSort(), true);
    const composantesRepas$ = this.gds.getAll(this.decoupageRepasSvc.getEntityName(), this.decoupageRepasSvc.getSort(), true);
    const grillePlanning$ = this.gds.search(this.ggSvc.filterGrillePlanning(idModeleNutri));
    const grilleGemrcn$ = this.gds.search(this.ggSvc.filterGrilleGemrcn(idModeleNutri));

    const initAll$ = combineLatest([repasList$, composantesRepas$, grillePlanning$,grilleGemrcn$]);
    return initAll$.toPromise().then(response => {
      vns.repasList = response[0].resultList;
      vns.composantesRepas = response[1].resultList;
      vns.grillePlanning = response[2].resultList;
      vns.grilleGemrcn = response[3].resultList;
      vns.famillesGemrcn = [];

      if(!this.utils.isCollectionNullOrEmpty(vns.grilleGemrcn)){
        vns.grilleGemrcn.forEach(item=>vns.famillesGemrcn.push(item.familleGemrcn));
        vns.famillesGemrcn = _sortBy(vns.famillesGemrcn,'ordre');
      }
      // preselectionner les listes deroulantes repas, composante repas, familles gemrcn
      this.ggSvc.preselectPlanningElements(vns);
      return vns;
    });
    return undefined;
  };
}
