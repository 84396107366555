<div class="d-flex mg-b-10">

  <div class="mg-r-5">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
  </div>


  <div class="mg-r-5">
    <yo-button
      label="AJOUTER"
      iconClass="fa fa-plus"
      [tooltip]="'Ajouter un nouveau fournisseur au groupe'"
      (onClick)="openAddFournisseur()"
      [disabled]="!canAdd()">
    </yo-button>
  </div>

  <div class="mg-r-5">
    <yo-button iconClass="fas fa-trash " buttonClass="cta-delete"
               [disabled]="utils.isCollectionNullOrEmpty(selectedFournisseurs)"
               tooltip="Enlever des fournisseurs"
               (onClick)="removeFournisseurs(selectedFournisseurs)">
    </yo-button>
  </div>


</div>

<dx-data-grid
  [dataSource]="groupeAchatFournisseurList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(260)"
  width="1100"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [(selectedRowKeys)]="selectedFournisseurs"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>

  <dxo-selection
    [selectAllMode]="true"
    [showCheckBoxesMode]="'always'" mode="multiple">
  </dxo-selection>


  <dxi-column dataField="fournisseur.site" alignment="left" caption="Site" [width]="300"
              [allowFiltering]="false" [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="fournisseur.libelle" caption="Fournisseur" [width]="300" [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="fournisseur.code" caption="Code" [width]="150" [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="ratioPrixUF" caption="Ratio Prix UF" [width]="100" [allowFiltering]="false">
  </dxi-column>


  <!--    TEMPLATE ENGAGEMENT PRIX-->
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell?.data?.fournisseur?.site?.libelle"
             [siteId]="cell?.data?.fournisseur?.site?.id">
    </yo-site>
  </div>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="false"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Fournisseur(s)">
  </dxo-pager>

</dx-data-grid>

<yo-dialog-add-fournisseur></yo-dialog-add-fournisseur>



