<div class="d-flex">
  <div class="mg-t-5" *ngIf="modificationManuelle">
    <i class="fas fa-square col-edited-color mg-r-5 border"></i>modification manuelle
  </div>
  <div class="mg-t-5" *ngIf="colonneModifiable">
    <i class="fas fa-square col-editable-color mg-l-20 mg-r-5 border"></i>colonne modifiable
  </div>
  <div class="mg-t-5" *ngIf="colonneInformations">
    <i class="fas fa-square col-info-color mg-l-20 mg-r-5 border"></i>colonne avec informations supplémentaires
  </div>
  <div class="mg-t-5" *ngIf="colonneObligatoire">
    <i class="fas fa-square col-madatory-color mg-l-20 mg-r-5 border"></i>colonne obligatoire
  </div>
  <div class="mg-t-5" *ngIf="celluleActive">
    <i class="fas fa-square col-cell-checked mg-l-20 mg-r-5 border"></i>actif
  </div>
  <div class="mg-t-5" *ngIf="celluleInactive">
    <i class="fas fa-square col-cell-unchecked mg-l-20 mg-r-5 border"></i>inactif
  </div>
</div>
