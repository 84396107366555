import {combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AllergeneDTO} from '../../../core/dtos/allergene-dto';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProduitAllergeneDTO} from '../../../core/dtos/produit-allergene-dto';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {AllergenesService, CODE_INFO_INCOMPLETE} from '../../../core/services/entities/allergenes-service.service';
import {
  GenericRequestSupplier,
  Predicat,
  Search,
  Sort
} from '../../../core/suppliers/generics/generic-request-supplier';
import {ProduitAllergeneService} from '../../../core/services/entities/produit-allergene.service';
import {ProduitsService} from '../../../core/services/entities/produits.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from "../../../core/constants";

@Injectable()
export class AllergeneResolverService implements Resolve<AllergeneSupplier> {

  constructor(private allergenesSvc: AllergenesService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private produitsSvc:ProduitsService,
              private produitAllergeneSvc:ProduitAllergeneService,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AllergeneSupplier> | Promise<AllergeneSupplier> | AllergeneSupplier => {

    const idProduit: number = +route.parent.paramMap.get('idProduit');
    const fabrique: boolean = route.parent.paramMap.get('isFabrique')==='true'?true:false;

    const allergeneSupplier = new AllergeneSupplier();

    allergeneSupplier.idProduit=idProduit;
    allergeneSupplier.isProduitFabrique =fabrique;
    let infoIncomplete :AllergeneDTO;

    const idsSites =this.auth2Svc.utilisateur.sites.map(site=>site.id);
    const produit$ = this.gds.getOne(this.produitsSvc.getEntityName(),idProduit);
    const allergenes$ = this.gds.search(this.filterAllergenes(this.allergenesSvc.getEntityName(),idsSites));
    const produitsAllergenes$ = this.gds.search(this.filterProduitsAllergenes(idProduit,this.produitAllergeneSvc.getEntityName(),idsSites));
    const produitsDeclinaisons$ = this.gds.search(this.filterProduitsDeclinaisons(idProduit,this.produitDeclinaisonSvc.getEntityName(),idsSites));

    const all$ = combineLatest([allergenes$, produitsAllergenes$, produitsDeclinaisons$, produit$]);
    return all$.toPromise().then(response => {

      allergeneSupplier.allergenes = response[0].resultList;

      allergeneSupplier.allergenes= allergeneSupplier.allergenes.filter(item=>{
        if(item.code===CODE_INFO_INCOMPLETE){
          // mettre en premier l'info incomplete
          infoIncomplete = item;
          return false;
        }
        return true;
      });
      allergeneSupplier.allergenes.unshift(infoIncomplete);
      allergeneSupplier.produitsAllergenes = response[1].resultList;
      allergeneSupplier.produitsDeclinaisons = response[2].resultList;
      allergeneSupplier.produitSiteId = response[3].one.site.id;
      allergeneSupplier.produit = response[3].one;

      return allergeneSupplier;
    });
  };


  /**
   * Récupérer tous les produits allergenes du produit qui ont des allergenes actives et dans les sites de l'utilisateur
   * @param idProduit
   */
  filterProduitsAllergenes = (idProduit:number, entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(entityName);
    const search = new Search();

    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'produitallergene.produitDeclinaison.produit.id'
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idProduit + '';

    search.predicats.push(predicat1);

    const predicat2 = new Predicat();
    predicat2.path = 'produitallergene.allergene.actif'
    predicat2.operator = PREDICAT_OPERATOR.Equals;
    predicat2.type = PREDICAT_TYPE.Boolean;
    predicat2.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat2);

    const predicatSites = new Predicat();

    predicatSites.path = 'produitallergene.allergene.site.id';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type = PREDICAT_TYPE.Integer;
    predicatSites.ids=idsSite;

    search.predicats.push(predicatSites);

    grs.search = search;

    return grs;
  };

  /**
   * Récupérer tous les allergenes  qui sont actifs et dans les sites de l'utilisateur
   * @param entityName
   * @param idsSite
   * @return {GenericRequestSupplier}
   */
  filterAllergenes = (entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(entityName);
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'allergene.actif';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Boolean;
    predicat1.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat1);

    const predicatSites = new Predicat();

    predicatSites.path = 'allergene.site.id';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type =  PREDICAT_TYPE.Integer;

    // toujours voir les allergenes du site par defaut (la où est stocké "information incomplete")
    const siteDefautExists = !!idsSite.filter(idSite=>idSite===1);
    if(!siteDefautExists){
      idsSite.push(1);
    }

    predicatSites.ids=idsSite;

    search.predicats.push(predicatSites);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = 'allergene.libelle';
    search.sorts = [];
    search.sorts.push(sort);

    grs.search = search;

    return grs;
  };


  /**
   * Récupérer tous les produits déclinaisons du produit, les trier par libelle déclinaison et dans les sites de l'utilisateur
   * @param idProduit
   */
  filterProduitsDeclinaisons = (idProduit:number,entityName:string, idsSite:number[]): GenericRequestSupplier => {

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'produitdeclinaison.produit.id'
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type =  PREDICAT_TYPE.Integer;
    predicat1.value = idProduit + '';

    search.predicats.push(predicat1);

    const predicatSites = new Predicat();

    predicatSites.path = 'produitdeclinaison.site.id';
    predicatSites.operator = PREDICAT_OPERATOR.In;
    predicatSites.type =  PREDICAT_TYPE.Integer;
    predicatSites.ids=idsSite;

    search.predicats.push(predicatSites);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = 'produitdeclinaison.declinaison.libelle';
    search.sorts = [];
    search.sorts.push(sort);

    const grs = new GenericRequestSupplier(entityName);
    grs.search = search;
    return grs;
  };
}

export class AllergeneSupplier {
  allergenes: AllergeneDTO[];
  produitsAllergenes: ProduitAllergeneDTO[];
  produitsDeclinaisons: ProduitDeclinaisonDTO[];
  idProduit:number;
  produit: ProduitDTO;
  isProduitFabrique:boolean;
  produitSiteId:number;
}


