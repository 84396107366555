<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        label="CRÉER"
        iconClass="fa fa-plus"
        (onClick)="openGroupeAchat(undefined)"
        [disabled]="!canCreate()"
        tooltip="Créer un groupe">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="groupeAchatList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(150)"
  [width]="utils.getWindowAvailableWidth(65)"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #gridDenree>
  <dxi-column dataField="id" alignment="left" caption="Actions" [width]="80"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" alignment="left" caption="Site" [width]="300"
              [allowFiltering]="false" [allowSorting]="false"
              cellTemplate="siteCellTemplate">
  </dxi-column>
  <dxi-column dataField="libelle" alignment="left" caption="Libellé" [width]="200"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="descriptif" alignment="left" caption="Descriptif" [width]="300"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" alignment="left" caption="Code" [width]="150"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="actif" caption="Actif" [width]="60"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openGroupeAchat(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoViewMode]="false"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="gds.canModify(cell.row.data) ? 'fa fa-edit' : 'fa fa-eye'"
                    [yoTooltip]="(gds.canModify(cell.row.data) ? 'Modifier' : 'Visualiser') + ' l\'unité de production'">
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="deleteGroupeAchat(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    [yoHiddenMode]="!gds.canModify(cell.row.data)"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    yoTooltip="Supprimer l'unité de production">
    </yo-cell-button>
  </div>
  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteLibelle]="cell.data.site.libelle"
             [siteId]="cell.data.site.id">
    </yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-pager
    [showPageSizeSelector]="false"
    [showNavigationButtons]="true"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} Groupe(s) achats">
  </dxo-pager>
</dx-data-grid>
