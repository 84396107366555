<dx-popup
  [showTitle]="true"
  title="Produits déclinés & articles sans ratios"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  fullScreen="true"
  (onHiding)="closeDialog()">
  <label>
    Pour chaque article et chaque produit décliné, la liste des ratios non renseignés sont affichés. En les modifiant, la prise en compte
    des nouvelles valeurs est immédiate.
  </label>
  <dx-tab-panel>
    <dxi-item title="Produits déclinés">
      <div class="wrap">
        <dx-data-grid
          [dataSource]="produitsDeclines"
          keyExpr="id"
          width="100%"
          height="100%"
          [allowColumnResizing]="true"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          columnResizingMode="widget">
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxi-column dataField="libelle" caption="Libellé" allowFiltering="true"></dxi-column>
          <dxi-column dataField="declinaison.libelle" caption="Déclinaison" allowFiltering="true"></dxi-column>
          <dxi-column dataField="ratioUniteTechnique" alignment="left" [allowSearch]="false" headerCellTemplate="headTemplateRatioUT" [allowFiltering]="false" cellTemplate="templateRatioUT"></dxi-column>
          <div *dxTemplate="let cell of 'headTemplateRatioUT'">
            <i class="fas fa-industry mg-r-5"></i> Fabrication (Ratio UT (UT poids net / Kg) - Ratio Universel (Kg / UT))
          </div>
          <div *dxTemplate="let cell of 'templateRatioUT'">
            <div class="row vertical-center-flex">
              <div class="col-3" [title]="'1 ' + cell.row.data?.uniteDeMesureUT.libelle + ' équivaut à ' ">
                1 {{ cell.row.data?.uniteDeMesureUT.abreviation }} équivaut à
              </div>
              <div class="col-1">
                <dx-number-box width="50" min="0.0001" (onValueChanged)="announceRatioUTChanged({ $event, cell })" [value]="cell.value"></dx-number-box>
              </div>
              <div class="col-1">
                Kg,
              </div>
              <div class="col-2">
                1 Kg équivaut à
              </div>
              <div class="col-1">
                <dx-number-box width="50" min="0.0001" (onValueChanged)="announceRatioUniverselChanged({ $event, cell })" [value]="inversesRatiosUT[cell.row.data.id]"></dx-number-box>
              </div>
              <div class="col-1" [title]="cell.row.data?.uniteDeMesureUT.libelle">
                {{ cell.row.data?.uniteDeMesureUT.abreviation }}
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>
    <dxi-item title="Articles">
      <div class="wrap">
        <dx-data-grid
          [dataSource]="articles"
          keyExpr="id"
          width="100%"
          height="100%"
          [allowColumnResizing]="true"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          columnResizingMode="widget">
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxi-column dataField="produitArticleLibelle" caption="Libellé" allowFiltering="true"></dxi-column>
          <dxi-column dataField="libelleFournisseur" caption="Fournisseur" allowFiltering="true" width="200"></dxi-column>
          <dxi-column dataField="ratioUF" alignment="left" [allowSearch]="false" [allowFiltering]="false" headerCellTemplate="headTemplateRatioUF" cellTemplate="templateRatioUF"></dxi-column>
          <dxi-column dataField="ratioUS" alignment="left" [allowSearch]="false" [allowFiltering]="false" headerCellTemplate="headTemplateRatioUS" cellTemplate="templateRatioUS"></dxi-column>
          <dxi-column dataField="ratioUC" [allowFiltering]="false" [allowSearch]="false" headerCellTemplate="headTemplateRatioUC" cellTemplate="templateRatioUC"></dxi-column>
          <div *dxTemplate="let cell of 'headTemplateRatioUF'">
            <i class="fa-euro-sign fas mg-r-5"></i> Facturation (Unité Factu. / Kg)
          </div>
          <div *dxTemplate="let cell of 'templateRatioUF'">
            <div class="row vertical-center-flex">
              <div class="col-3" [title]="'1 ' + cell.row.data?.uniteMesureUniteFacturation.libelle + ' équivaut à '">
                1 {{ cell.row.data?.uniteMesureUniteFacturation.abreviation }} équivaut à
              </div>
              <div class="col-2">
                <dx-number-box min="0.0001" width="50" (onValueChanged)="announceRatioUFChanged({ $event, cell })" [value]="cell.value"></dx-number-box>
              </div>
              <div class="col-1">
                Kg
              </div>
            </div>
          </div>
          <div *dxTemplate="let cell of 'headTemplateRatioUS'">
            <i class="fa-cubes fas mg-r-5"></i> Stockage (Unité stockage / UT) - (Unité stockage / Kg)
          </div>
          <div *dxTemplate="let cell of 'templateRatioUS'">
            <div class="row vertical-center-flex">
              <div class="col-3" [title]="'1 ' + cell.row.data?.uniteMesureUniteStockage.libelle + ' équivaut à '">
                1 {{ cell.row.data?.uniteMesureUniteStockage.abreviation }} équivaut à
              </div>
              <div class="col-2">
                <dx-number-box min="0.0001" width="50" (onValueChanged)="announceRatioUSUTChanged({ $event, cell })" [value]="ratiosUsUt[cell.row.data.id]"></dx-number-box>
              </div>
              <div class="col-1" [title]="cell.row.data?.uniteMesureUniteTechnique.libelle">
                {{ cell.row.data?.uniteMesureUniteTechnique.abreviation }},
              </div>
              <div class="col-3" [title]="'1 ' + cell.row.data?.uniteMesureUniteStockage.libelle + ' équivaut à '">
                1 {{ cell.row.data?.uniteMesureUniteStockage.abreviation }} équivaut à
              </div>
              <div class="col-2">
                <dx-number-box min="0.0001" width="50" (onValueChanged)="announceRatioUSChanged({ $event, cell })" [value]="cell.value"></dx-number-box>
              </div>
              <div class="col-1">
                Kg
              </div>
            </div>
          </div>
          <div *dxTemplate="let cell of 'headTemplateRatioUC'">
            <i class="fa-cube fas mg-r-5"></i> Commande (Unité commande / Unité Factu.) - (Unité commande / Kg)
          </div>
          <div *dxTemplate="let cell of 'templateRatioUC'">
            <div class="row vertical-center-flex">
              <div class="col-3" [title]="'1 ' + cell.row.data?.uniteMesureUniteCommande.libelle + ' équivaut à '">
                1 {{ cell.row.data?.uniteMesureUniteCommande.abreviation }} équivaut à
              </div>
              <div class="col-1">
                <dx-number-box min="0.0001" width="50" (onValueChanged)="announceRatioUCUFChanged({ $event, cell })" [value]="ratiosUcUf[cell.row.data.id]"></dx-number-box>
              </div>
              <div class="col-2" [title]="cell.row.data?.uniteMesureUniteFacturation.libelle">
                {{ cell.row.data?.uniteMesureUniteFacturation.abreviation }},
              </div>
              <div class="col-3 pl-0" [title]="'1 ' + cell.row.data?.uniteMesureUniteCommande.libelle + ' équivaut à '">
                1 {{ cell.row.data?.uniteMesureUniteCommande.abreviation }} équivaut à
              </div>
              <div class="col-1">
                <dx-number-box min="0.0001" width="50" (onValueChanged)="announceRatioUCChanged({ $event, cell })" [value]="cell.value"></dx-number-box>
              </div>
              <div class="col-2">
                Kg
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </dxi-item>
  </dx-tab-panel>
</dx-popup>
