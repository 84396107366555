import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Observable, Subject, Subscription} from 'rxjs';
import {UtilsService} from './utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {GrilleUtilisateur} from './grille-utilisateurs';
import {UtilisateurRoutemapService} from './services/entities/utilisateur-routemap.service';
import {UtilisateurDTO} from './dtos/utilisateur-dto';
import {ResponseWrapper} from './suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from './constants';
import {SelectItem} from 'primeng/api';
import {HttpService} from './services/technique/http.service';
import {ToastService} from "./services/technique/toast.service";

export const URL_GET_PROFIL_LIST = `dolrest/utilisateur/get-profil-list`;
export const URL_GET_ROLE_LIST = `dolrest/utilisateur/get-role-list`;
export const URL_GET_ALL_UTILISATEURS = `dolrest/utilisateur/all`;

@Injectable()
export class UtilisateurService {

  listFamillesProduits: SelectItem[];
  listFamillesPlansAlimentaires: SelectItem[];
  listTypeProduitsFabrique: SelectItem[];
  listTypeProduitsNonFabrique: SelectItem[];

  private userAnnouncedSource = new Subject<any>();
  // Observable user streams
  userAnnounced$ = this.userAnnouncedSource.asObservable();

  searchTerms = new Subject<string>();

  searchConnexionTerms = new Subject<string>();

  utilisateurSubjectToDelete = new Subject<UtilisateurDTO>();
  utilisateurSubjectToDelete$ = this.utilisateurSubjectToDelete.asObservable();

  utilisateurSubjectToCreate = new Subject<UtilisateurDTO>();
  utilisateurSubjectToCreate$ = this.utilisateurSubjectToCreate.asObservable();

  // Service message commands
  announceSearch(term: string) {
    this.searchTerms.next(term);
  }

  // Service message commands
  announceSearchConnexion(term: string) {
    this.searchConnexionTerms.next(term);
  }


  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils: UtilsService,
              private utilisateurRoutemapService: UtilisateurRoutemapService,
              private toastSvc: ToastService
              ) {

  }


  getAvailableListTypeProduits = (isFabrique: boolean): SelectItem[] => {
    if (isFabrique) {
      return this.listTypeProduitsFabrique;
    } else {
      return this.listTypeProduitsNonFabrique;
    }
  };

  searchUtilisateur = (endUrl: string): Observable<UtilisateurDTO[]> => this.httpSvc.get(this.utilisateurRoutemapService.getSearchUtilisateursLink(endUrl), null);

  searchUtilisateurConnexion = (endUrl: string): Observable<GrilleUtilisateur> => {

    return this.http.get<GrilleUtilisateur>(this.utilisateurRoutemapService.getSearchUtilisateursConnexionsLink(endUrl)).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  /**
   * Raffraichissement du tableau de UtilisateurDTO passé en paramètre.
   * @param {UtilisateurDTO[]} utilisateursForGrille
   * @param {UtilisateurDTO} utilisateur
   * @returns {UtilisateurDTO[]}
   */
  refreshUtilisateurGrille = (utilisateursForGrille: UtilisateurDTO[], utilisateurDTO: UtilisateurDTO): UtilisateurDTO[] => {

    const arr = [];
    const isFoundSiteArr = utilisateursForGrille.filter(item => utilisateurDTO && item.id === utilisateurDTO.id);


    if (isFoundSiteArr && isFoundSiteArr.length > 0) {
      utilisateursForGrille.map(item => {
        if (utilisateurDTO && item.id === utilisateurDTO.id) {
          item = utilisateurDTO;
        }
        arr.push(item);
      });
    } else {

      utilisateursForGrille.unshift(utilisateurDTO);
      utilisateursForGrille.map(item => arr.push(item));


    }

    return arr;

  };

  /**
   * cf.
   * @param {UtilisateurDTO} utilisateurDTO
   * @returns {Observable<ResponseWrapper<UtilisateurDTO>>}
   */
  save = (utilisateurDTO: UtilisateurDTO): Observable<ResponseWrapper<UtilisateurDTO>> => this.httpSvc.post(`dolrest/utilisateur/saveone`, utilisateurDTO);

  findAll = () => this.httpSvc.get(URL_GET_ALL_UTILISATEURS);

  private delete = (utilisateurID: number): Observable<boolean> => {
    return this.http.delete<boolean>(this.utilisateurRoutemapService.getDeleteUtilisateurSLink(utilisateurID)).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  announceUtilisateurToDelete = (utilisateurDTO: UtilisateurDTO) => {
    this.utilisateurSubjectToDelete.next(utilisateurDTO);
  };

  /**
   * Annonce de la création d'un Utilisateur.
   */
  announceUtilisateurToCreate = () => {
    this.utilisateurSubjectToCreate.next();
  };


  deleteUtilisateur = (utilisateurDTO: UtilisateurDTO): Subscription => {
    return this.delete(utilisateurDTO.id).subscribe(
      data => {
        this.announceUtilisateurToDelete(utilisateurDTO);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Utilisateur ${utilisateurDTO.login} supprimé avec succès`);
      },
      error => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible de supprimer l'utilisateur ${utilisateurDTO.login}`);
      }
    );
  };

  getProfilList = () => this.httpSvc.get(URL_GET_PROFIL_LIST);

  getRoleList = () => this.httpSvc.get(URL_GET_ROLE_LIST);


  createEmptyDTO = () => {
    const user = new UtilisateurDTO();
    user.actif = true;
    return user;
  };
}
