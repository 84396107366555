<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'width':'600px'}"
  [(visible)]="displayDialog">


  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="far fa-lg fa-file-pdf mg-r-5"></i>Impression bilan allergène
    </div>
  </ng-template>

  <div class="wrapper">
    <div class="first-column"> </div>
    <div class="first-line">
      <div class="first-item-first-line">
        <label class="mg-r-5">Du : </label>
          <p-calendar title="Selectionner la date de début"
                      placeholder="date de début"
                      [showOnFocus]="false"
                      [(ngModel)]="startDate"
                      [showIcon]="true"
                      firstDayOfWeek="1"
                      dateFormat="dd/mm/yy"
                      [readonlyInput]="false"
                      [showWeek]="true"
                      [locale]="fr"
                      appendTo="body">
          </p-calendar>
      </div>
      <div class="second-item-first-line">
        <label class="mg-r-5"> au : </label>
        <p-calendar title="Selectionner la date de fin"
                    placeholder="date de fin"
                    [showOnFocus]="false"
                    [(ngModel)]="endDate"
                    [showIcon]="true"
                    firstDayOfWeek="1"
                    dateFormat="dd/mm/yy"
                    [readonlyInput]="false"
                    [showWeek]="true"
                    [locale]="fr"
                    appendTo="body">
        </p-calendar>
      </div>
    </div>

    <div class="second-line">
      <label>Commentaire : </label><br/>
      <textarea class="textarea" [(ngModel)]="comment"></textarea>
    </div>
    <div class="last-column"> </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button label="Imprimer" [disabled]="this.isValidForm()" iconClass="fas fa-print"
            (onClick)="downloadBilanAllergene()">
    </yo-button>

    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>
</p-dialog>
