<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>

      <p-calendar
        firstDayOfWeek="1"
        [locale]="fr"
        (onSelect)="onChangeFilterDateLivraison()"
        (onTodayClick)="onChangeFilterDateLivraison()"
        (onClearClick)="onChangeFilterDateLivraison()"
        placeholder="Filtrer par dates de livraison..."
        title="Filtrer par dates de livraison"

        appendTo="body"
        showButtonBar="true"
        class="mg-r-5"
        selectionMode="range"
        [(ngModel)]="filterDatesLivraisons"
        dateFormat="dd/mm/yy"
        readonlyInput="true"
        [showWeek]="true">
      </p-calendar>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
        <dx-tag-box
          [width]="370"
          [multiline]="false"
          [dataSource]="filterItemsFournisseur"
          placeholder="Filtrer par fournisseur..."
          displayExpr="value"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeFilterFournisseurs($event)"
        >
        </dx-tag-box>
      </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="370"
        [multiline]="false"
        [dataSource]="filterItemsUdp"
        placeholder="Filtrer par unité de production..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        (onValueChanged)="onChangeFilterUdps($event)"
      >
      </dx-tag-box>
    </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="450"
        [dataSource]="filterItemsListeBesoin"
        placeholder="Filtrer par liste de besoins..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [multiline]="false"
        (onValueChanged)="onChangeFilterListesBesoins($event)"
      >
      </dx-tag-box>
    </span>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
  <span class="mg-r-5">
     <yo-button iconClass="fas fa-play"
                tooltip="Passer à l'étape suivante"
                [disabled]="isActionDisabled" (onClick)="menu.toggle($event)">
     </yo-button>
     <p-menu appendTo="body" #menu [popup]="true" [model]="nextSteps"></p-menu>
  </span>
      <span class="mg-r-5">
      <yo-button
        iconClass="fa fa-envelope"
        (onClick)="sendMail()"
        [disabled]="isActionDisabled"
        tooltip="Envoyer par mail les commandes aux fournisseurs sélectionnés">
      </yo-button>
   </span>
      <span class="mg-r-5">
      <yo-button
        iconClass="fas fa-print" (onClick)="menuOptionsPrint.toggle($event)" [disabled]="isActionDisabled"
        tooltip="Imprimer les commandes sélectionnées au format pdf">
      </yo-button>
      <p-menu appendTo="body" #menuOptionsPrint [popup]="true" [model]="optionsPrint"></p-menu>
   </span>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  (onSelectionChanged)="checkDisabledButtons()"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  #grid>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="100" cellTemplate="templateActions"

              [allowSorting]="false"></dxi-column>
  <dxi-column dataField="numeroBcf" caption="Bon de commande" cellTemplate="templateBonCf"
              alignement="left"></dxi-column>
  <dxi-column dataField="bonReceptionList" caption="Bon de réception" cellTemplate="templateBonReception"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'fas fa-edit':'fas fa-eye'"
                    [yoTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Modifier la commande':'Voir la commande'"
    ></yo-cell-button>
  </div>
  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a title="Aller sur la liste de besoins"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateBonCf'">
    <span class="mg-r-5">{{cell.row.data.numeroBcf}}</span>
    <span class="mg-r-5" *ngIf="cell.row.data.mailHisto">
      <label

        [title]="mailHistoSvc.getLabelStatut(cell.row.data.mailHisto.statut)"
        class="badge mg-r-5 cursor"
        [class.badge-warning]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
        [class.badge-primary]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.ENVOYE"
        [class.badge-success]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.LU"
        [class.badge-danger]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_ERREUR"
        (click)="openHistoriqueMails(cell.row.data)">
        <span class="fa-layers fa-fw">
        <i class="fas fa-envelope mg-r-5"></i>
        <span class="fa-layers-counter">{{mailHistoSvc.getShortLabel(cell.row.data.mailHisto.statut)}}</span>
        </span>
      </label>
    </span>
  </div>
  <div *dxTemplate="let cell of 'templateBonReception'">
    <td style="text-align: left;">
      <div class="p-grid">
        <div class="p-col-fixed width-50">
          <yo-button tooltip="Créer un bon de réception"
                     *ngIf="isEditableBonReception(auth2Svc.isSiteLocal(cell.row.data.site.id), cell.row.data.bonReceptionList)"
                     buttonClass="cta-inner-secondary"
                     iconClass="fas fa-plus"
                     (onClick)="createBonReception($event, cell.row.data)"></yo-button>
        </div>
        <div class="p-col">
          <div *ngFor="let bonReception of cell.row.data.bonReceptionList">
            <ng-container
              *ngTemplateOutlet="tplBonReception;context:{bonReception:bonReception}"></ng-container>
          </div>
        </div>
      </div>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} commande(s) à réceptionner">
  </dxo-pager>

</dx-data-grid>

<!--DIALOG MODIFIER UNE COMMANDE  À RÉCEPTIONNER-->
<yo-bc-edit-fournisseur [displayDialog]="displayEditDialog" (onSave)="onBonCommandSaved()"
                        (onClose)="displayEditDialog=$event" [bonCf]="bonCf" [lignesBonCf]="lignesBonCf"
                        [bonCfs]="commandesAReceptionner"></yo-bc-edit-fournisseur>

<!--DIALOG DE CREATION DE BON DE RECEPTION-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"

  [style]="{'width':'900px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayCreateBonReception">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-plus"></i> Créer un bon de réception pour la
      commande {{selectedBonCf?.numeroBcf}}
    </div>
  </ng-template>

  <!--  CONTENT -->
  <div class="mg-t-10">

    <form [formGroup]="formBonReception" novalidate>
      <ng-container *ngFor="let field of fieldsBonReception">
        <yo-dynamic-form-field-bs [fields]="fieldsBonReception" [field]="field"
                                  [form]="formBonReception"></yo-dynamic-form-field-bs>
      </ng-container>
    </form>

  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-plus" tooltip="Créer le bon de réception" class="mg-r-5"
                   (onClick)="initBonReception()"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayCreateBonReception=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>


  </ng-template>


</p-dialog>

<!--TEMPLATE COLONNE BON DE RECEPTION DANS GRILLE COMMANDES À RÉCEPTIONNER-->
<ng-template #tplBonReception let-bonReception="bonReception">

  <!--  <p-contextMenu [global]="true" [target]="divBonReception" [model]="items" ></p-contextMenu>-->
  <p-menu appendTo="body" #menuActions [model]="brlActions" [popup]="true"></p-menu>

  <div (click)="chooseBrlAction(menuActions,$event,bonReception)" class="cursor">

    <span class="mg-r-5">
    <yo-statuts-reception [bonReception]="bonReception"></yo-statuts-reception>
    </span>

    <span class="mg-r-5">
      <label class="genlabel cursor">{{bonReception?.numeroBonReception | uppercase}}</label>
    </span>

    <yo-litige-warning [bonReception]="bonReception"></yo-litige-warning>

  </div>

</ng-template>


<!--TEMPLATE NUMERO BON COMMANDE-->
<ng-template #tplNumeroBcf let-bonCf="bonCf">

  <span class="mg-r-5 ">{{bonCf.numeroBcf}}</span>
  <span class="mg-r-5" *ngIf="bonCf.mailHisto">

        <!--statut du bon de réception-->
    <label

      [title]="mailHistoSvc.getLabelStatut(bonCf.mailHisto.statut)"
      class="badge mg-r-5 cursor"
      [class.badge-warning]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
      [class.badge-primary]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.ENVOYE"
      [class.badge-success]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.LU"
      [class.badge-danger]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.EN_ERREUR"
      (click)="openHistoriqueMails(bonCf)">
       <span class="fa-layers fa-fw">
    <i class="fas fa-envelope mg-r-5"></i>
    <span class="fa-layers-counter">{{mailHistoSvc.getShortLabel(bonCf.mailHisto.statut)}}</span>
  </span>


    </label>

  </span>
</ng-template>


<yo-dialog-article-reception-grid (onClose)="displayDialogArticlesReception=$event"
                                  [displayEdit]=true
                                  (onSave)="updateCommandesFournisseurs($event)"
                                  [displayDialog]="displayDialogArticlesReception">
</yo-dialog-article-reception-grid>



