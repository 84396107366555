import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogueAchatDTO} from '../../../core/dtos/catalogue-achat-dto';
import {
  CataloguesAchatsService,
  FK_CatalogueAchat,
  PROVENANCE
} from '../../../core/services/entities/catalogues-achats.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {concat as _concat} from 'lodash';
import {Subscription, timer} from 'rxjs';
import {FamilleProduitDTO} from '../../../core/dtos/famille-produit-dto';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {saveAs} from 'file-saver';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {ImportRequestDTO} from '../../../core/dtos/importexport/import-request-dto';
import {ImportPropertyDTO} from '../../../core/dtos/importexport/import-property-dto';
import {SecteurDTO} from '../../../core/dtos/secteur-dto';
import {FournisseurDTO} from '../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../core/services/entities/fournisseur.service';
import {
  FP_ROUTES,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  PREDICAT_OPERATOR,
  PREDICAT_TYPE
} from '../../../core/constants';
import {ActivatedRoute} from '@angular/router';
import {ArticleSupplier} from './catalogue-achat-resolver.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {Table} from 'primeng/table';
import {OverlayPanel} from 'primeng/overlaypanel';
import {
  GenericRequestSupplier,
  Predicat,
  Search,
  Sort
} from '../../../core/suppliers/generics/generic-request-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import CustomStore from 'devextreme/data/custom_store';
import {DevextremeService, FilterItem} from '../../../core/services/technique/devextreme.service';
import {GestionFournisseurCatalogueAchatService} from '../../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {GroupeAchatCaViewModel} from "../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {ProgressService} from "../../../core/services/progress/progress.service";
import {ProcessLockedEnum} from "../../../core/enums/process-locked-enum";
import {ProgressStatusDTO} from "../../../core/dtos/progress/progress-status-dto";
import {UniteDeProductionCatalogueAchatService} from "../../../core/services/entities/unite-de-production__catalogue-achat.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";


@Component({
  selector: 'yo-maj-catalogue-achat',
  templateUrl: './catalogue-achat.component.html',
  styleUrls: ['./catalogue-achat.component.scss']
})
export class CatalogueAchatComponent implements OnInit, AfterViewInit, OnDestroy {
  get onEditingStart(): ($event: any) => void {
    return this._onEditingStart;
  }

  set onEditingStart(value: ($event: any) => void) {
    this._onEditingStart = value;
  }

  @ViewChild(DxDataGridComponent, {static: false}) grid: DxDataGridComponent;

  readonly sheetname = 'articles';
  readonly filename = 'maj-articles.xlsx';

  customStore: CustomStore;
  exportOptionList: any[] = [];
  selectExportOption: any;
  isVisibleCaInherited: boolean = true;

  exportBtnDisabled: boolean;

  pageSize: number;
  page: number;
  searchFilters: any;

  subFournisseursItems: Subscription;
  subFamillesItems: Subscription;
  subSecteursItems: Subscription;
  subFilialesItems: Subscription;
  subSavedCatalogueAchat: Subscription;
  subRouteInit: Subscription;
  subLoadingXls: Subscription;

    familles: FamilleProduitDTO[];

  filiales: FournisseurDTO[];
  secteurs: SecteurDTO[];
  sites: SiteDTO[];
  articleSupplier: ArticleSupplier;

  @ViewChild('opExcel') opExcel: OverlayPanel;
  @ViewChild('opImportXls') opImportXls: OverlayPanel;
  @ViewChild('dt') dt: Table;

  totalRecords = 0;
  articles: CatalogueAchatDTO[] = [];

  loading: boolean = false;
  traitementImportXlsTermine: boolean = false;

  grs: GenericRequestSupplier;

  selectedUniteDeProduction: UniteDeProductionDTO;
  progressStatusCurrent: ProgressStatusDTO;

  operationProcessing: boolean = false;
  progression: number = 0;
  timeSpend: number = 0;
  timeToDisplay: string = '';
  isTimeRunning: boolean = false;

  treeBoxValue: string[];
  treeDataSource: any;
  selectedFamillesPlats: number[] = [];
  famillesPlats: FamilleProduitDTO[] = [];

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_ARTICLES + '/fournisseurs-articles';

  errors: any[] = [];
  displayImportPopupErrors: boolean = false;

  constructor(private catalogueAchatSvc: CataloguesAchatsService,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private dxSvc: DevextremeService,
              private gestionFournisseurCaSvc: GestionFournisseurCatalogueAchatService,
              public utils: UtilsService,
              private fournisseurSvc: FournisseurService,
              private famillesSvc: FamillesProduitService,
              private gfs: GenericFormService,
              public auth2Svc: Auth2Service,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private udpCaSvc: UniteDeProductionCatalogueAchatService,
              private progressSvc: ProgressService,
              private fpSvc: FamillesProduitService,
              private toastSvc: ToastService,
              private i8nSvc: InternationalizationService) {
  }

  ngOnInit() {

    // preparer la vue, récupérer les unites de productions locales et sélectionner la première par defaut
    this.routeInitSubscription();

    //Abonnement à la réponse d'enregistrement retournée par le back
    this.savedCatalogueAchatSubscription();

    //Abonnement à la réponse de suppression retournée par le back
    this.deleteCatalogueAchatSubscription();

    this.loadingXlsSubscription();

    this.exportOptionList = [
      {libelle: 'Visible à l\'écran', value: -1},
      {libelle: '500', value: 500},
      {libelle: '1000', value: 1000},
      {libelle: '10000', value: 10000},
      {libelle: '30000', value: 30000}
    ];
    this.selectExportOption = {libelle: 'Visible à l\'écran', value: -1};

    timer(0, 1000).subscribe(() => {
      if(this.isTimeRunning) {
        this.timeSpend++;
        this.timeToDisplay = this.getTimeSpend();
      }
    });

  }

  ngAfterViewInit(): void {

    this.utils.unsubscribe(this.subFamillesItems);
    this.subFamillesItems = this.gds.getAll(this.famillesSvc.getEntityName(), this.famillesSvc.getSort(), true).subscribe(response => {

      if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {

        // enlever tous les sous niveaux de famille
        // enlever les familles winapro incoherentes A000...
        this.familles = this.famillesSvc.removeChildren(response.resultList);
      }

    });

    this.utils.unsubscribe(this.subFilialesItems);
    this.subFilialesItems = this.gds.getAll(this.fournisseurSvc.getEntityName(), this.fournisseurSvc.getSort(), true).subscribe(response => {
      this.filiales = response.resultList;
    });


    this.sites = this.auth2Svc.utilisateur.siteListLocaux;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFournisseursItems);
    this.utils.unsubscribe(this.subFamillesItems);
    this.utils.unsubscribe(this.subSecteursItems);
    this.utils.unsubscribe(this.subFilialesItems);
    this.utils.unsubscribe(this.subSavedCatalogueAchat);
    this.utils.unsubscribe(this.subRouteInit);
    this.utils.unsubscribe(this.subLoadingXls);
  }

  routeInitSubscription = () => {

    this.subRouteInit = this.route.data
      .subscribe((data: { articlesSupplier: ArticleSupplier }) => {
        this.articleSupplier = data.articlesSupplier;
        this.selectedUniteDeProduction = data.articlesSupplier.selectedUniteDeProduction;
        this.gds.getAll(this.fpSvc.getEntityName(), this.fpSvc.getSort(), true)
          .subscribe(response => this.famillesPlats = response.resultList.filter(fp => !fp.fabrique));

        //Lazy loading grid devEx
        this.customStore = this.initCustomStore();
      });
  };

  getTimeSpend = (): string => `${this.timeSpend} seconde(s) écoulée(s) depuis le début du traitement...`;

  formatProgression = (value): string => `Progression de l'import du fichier Excel (${this.progressStatusCurrent?.comment || ''}): ${value*100}%`;

  onChangeParentFamilleProduitSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.selectedFamillesPlats = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.grid.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.famillesPlats.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = _concat([...result], this.reflexionSearchId([...this.famillesPlats], fp.id))
        }
      })
    });

    result = _concat([...result], idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = _concat([...idResultList], this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  initCustomStore = () => {

    if (!this.utils.isNullOrEmpty(this.articleSupplier.selectedUniteDeProduction)) {

      this.selectedUniteDeProduction = this.articleSupplier.selectedUniteDeProduction;

      const cs = new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {

          // page
          this.pageSize = loadOptions.take || this.grid.instance.pageSize();
          this.page = this.grid.instance.pageIndex();

          // filters
          const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

          // sorts
          const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);

          const filterProvider = filters.find(filter => filter.column === 'fournisseur.libelle');
          const filterCodeApi = filters.find(filter => filter.column === 'codeApi');
          const filterDenree = filters.find(filter => filter.column === 'denreeLibelle');
          const filterArticle = filters.find(filter => filter.column === 'produitArticle.libelle');
          const filterRefArticle = filters.find(filter => filter.column === 'referenceArticle');

          const sortProvider = sorts.find(filter => filter.path === 'fournisseur.libelle');
          const sortCodeApi = sorts.find(filter => filter.path === 'codeApi');
          const sortDenree = sorts.find(filter => filter.path === 'denreeLibelle');
          const sortArticle = sorts.find(filter => filter.path === 'produitArticle.libelle');
          const sortRefArticle = sorts.find(filter => filter.path === 'referenceArticle');
          const sortFamilleProduit = sorts.find(filter => filter.path === 'libelleFamilleProduit');
          const sortPrix = sorts.find(filter => filter.path === 'groupeAchatCaList');

          this.searchFilters = {
            idsUniteProduction: [this.articleSupplier.selectedUniteDeProduction.id],
            idsSites: this.auth2Svc.utilisateur.sites.map(site => site.id),
            onlyParent: !this.isVisibleCaInherited,
            labelProvider: filterProvider ? filterProvider.value : '',
            codeApi: filterCodeApi ? filterCodeApi.value : '',
            produitDeclineLabel: filterDenree ? filterDenree.value : '',
            articleLabel: filterArticle ? filterArticle.value : '',
            refArticle: filterRefArticle ? filterRefArticle.value : '',
            idsFamilyProduct: this.selectedFamillesPlats || [],
            page: this.page + 1,
            size: this.pageSize,
            sortByDenreeLabel: sortDenree ? sortDenree.dir : '',
            sortByArticleLabel: sortArticle ? sortArticle.dir : '',
            sortByProviderLabel: sortProvider ? sortProvider.dir : '',
            sortByPriceUF: sortPrix ? sortPrix.dir : '',
            sortByFamilyProduct: sortFamilleProduit ? sortFamilleProduit.dir : '',
            sortByCodeApi: sortCodeApi ? sortCodeApi.dir : '',
            sortByRefProvider: sortRefArticle ? sortRefArticle.dir : ''
          };

          return this.catalogueAchatSvc.getCatalogueAchatByFilters(this.searchFilters).toPromise().then(response => {
            this.articles = response.resultList;
            return {
              data: this.articles,
              totalCount: response.totalElements
            }
          });
        },
        update: (key, values) => {
          // Callback défaillant, qui ne fonctionne pas du tout (this.customStore.update(id, ...))
          return new Promise((resolve, reject) => resolve({
            data: values,
            totalCount: this.articles.length
          }));
        }
      });

      return cs;
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Erreur de configuration : Votre environnement ne dispose d'aucune unité de production`);
    }
  };

  initPredicatSearchForExportXls = (filters: FilterItem[]) => {
    const grs = new GenericRequestSupplier(this.catalogueAchatSvc.getEntityName());
    const search = new Search();
    search.predicats = [];

    const rootPath = this.catalogueAchatSvc.getEntityName().toLowerCase();

    if (!this.utils.isCollectionNullOrEmpty(filters)) {
      for (const filter of filters) {
        let path = '';
        switch (filter.column) {
          case FK_CatalogueAchat.produitArticleLibelle:
            path = `${rootPath}.produitArticle.libelle`;
            break;
          case FK_CatalogueAchat.referenceArticle:
            path = `${rootPath}.referenceArticle`;
            break;
          case FK_CatalogueAchat.prix:
            path = `${rootPath}.prix`;
            break;
          case FK_CatalogueAchat.codeApi:
            path = `${rootPath}.codeApi`;
            break;
          case FK_CatalogueAchat.fournisseurLibelle:
            path = `${rootPath}.fournisseur.libelle`;
            break;
          case FK_CatalogueAchat.denreeLibelle:
            path = `${rootPath}.produitArticle.produitDeclinaison.libelle`;
            break;
          case FK_CatalogueAchat.familleProduit:
            path = `${rootPath}.produitArticle.produitDeclinaison.produit.familleProduit.libelle`;
            break;
        }

        const predicat: Predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;
    return grs;
  }

  onchangeUdp = async (event) => {
    this.articleSupplier.selectedUniteDeProduction.id = event.value.id;
    await this.customStore.load();
    await this.grid.instance.refresh();
  };


  /**
   * Abonnement à la réponse d'enregistrement retournée par le back
   */
  savedCatalogueAchatSubscription = () => {
    this.subSavedCatalogueAchat = this.catalogueAchatSvc.savedDto$.subscribe(async () => {
      await this.customStore.load();
      await this.grid.instance.refresh();
    });
  };

  loadingXlsSubscription = () => {
    this.subLoadingXls = this.catalogueAchatSvc.loadingXlsProgressBar$
      .subscribe(id => {
        this.traitementImportXlsTermine = false;
        timer(15000).pipe(
          filter(response => this.traitementImportXlsTermine === false),
          switchMap(response => {
            this.traitementImportXlsTermine = true;
            return this.progressSvc.getById(id)
          }),
          tap((response: ResponseWrapper<ProgressStatusDTO>) => {
            this.progressStatusCurrent = response.one;
            this.progression = response.one.progression;
            if (this.progression !== null && this.progression !== undefined && this.progression < 100) {
              this.traitementImportXlsTermine = false;
            }
          }),
          filter(response => this.progression !== null && this.progression !== undefined && this.progression < 100),
          tap(response => {
            this.catalogueAchatSvc.announceLoadingXlsProgressBar(id);
          }),
          take(3),
        ).subscribe();
      });
  };

  /**
   * Abonnement à la réponse de suppression retournée par le back
   */
  deleteCatalogueAchatSubscription = () => {
    this.subSavedCatalogueAchat = this.gfs.deletedId$.subscribe(async (response) => {
      if (!this.utils.isResponseSupplierError(response)) {
        await this.customStore.load();
        await this.grid.instance.refresh();
      }
    });
  };

  /**
   * Exporter les fichiers au format xlsx
   */
  exportXslx = () => {
    this.opExcel.hide();
    this.exportBtnDisabled = true;

    if (this.selectExportOption.value !== -1) this.searchFilters.size = this.selectExportOption.value;

    this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Export en cours de réalisation`);

    try {
      this.catalogueAchatSvc.exportCatalogueAchatByFilters(this.searchFilters).subscribe(response => {
        this.exportBtnDisabled = false;
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        saveAs(blob, 'articles-' + new Date().getTime() + '.xlsx');
      });
    } catch (e) {
      this.exportBtnDisabled = false;
    }
  };

  importXslx = async ($event: any) => {

    if ($event.target.files && $event.target.files.length) {
      const file = $event.target.files[0];
      const importRequest = new ImportRequestDTO(this.catalogueAchatSvc.getEntityName());
      importRequest.filename = this.filename;
      importRequest.sheetname = this.sheetname;

      const importProperties: ImportPropertyDTO[] = [];
      // CHAMPS TECHNIQUES
      importProperties.push(new ImportPropertyDTO(0, 'codeApi', 'string'));
      // CHAMPS FONCTIONNELS
      importProperties.push(new ImportPropertyDTO(5, 'produitArticleLibelle', 'string'));
      importProperties.push(new ImportPropertyDTO(11, 'prix', 'bigdecimal'));

      importProperties.push(new ImportPropertyDTO(28, 'produitDeclinaisonLibelle', 'string'));
      importProperties.push(new ImportPropertyDTO(27, 'produitDeclinaisonCode', 'string'));

      importRequest.importProperties = importProperties;

      const res: boolean = await confirm("Souhaitez-vous réaliser la mise à jour des articles préférés après l'import du fichier (pour l'unité de production liée au fichier) ?", "Mise(s) à jour");
      this.startImportXls(importRequest, file, res);
    }
  };

  private startImportXls = (importRequest: ImportRequestDTO, file, doUpdate: boolean) => {
    this.progressSvc.startProgressStatus(ProcessLockedEnum.IMPORT_XLS_CATALOGUE_ACHAT, "Récupération des articles en cours...")
      .subscribe(progress => {
        this.operationProcessing = true;
        this.isTimeRunning = true;
        this.progressStatusCurrent = progress.one;
        this.catalogueAchatSvc.announceLoadingXlsProgressBar(this.progressStatusCurrent.id);

        this.catalogueAchatSvc.updatePrixAchatsFromXlsx(importRequest, file, doUpdate, this.progressStatusCurrent.id)
          .subscribe((response: any) => {
            this.progressSvc.deleteProgressStatus(this.progressStatusCurrent.id)
              .subscribe(response => {
                this.operationProcessing = false;
                this.isTimeRunning = false;
                this.timeSpend = 0;
                this.progression = 0;
                this.progressStatusCurrent = null;
                this.traitementImportXlsTermine = true;

                this.grid.instance.refresh();
                this.opImportXls.hide();

                const input: any = document.getElementById("importExcelFile");
                input.value = '';
              });

            const res: any = response.one;
            if (res.allElementsImported)
              this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
            else {
              for (const item of Object.entries(res.reports)) {
                let values: any[] = Array.of(item[1]);
                const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(","))).join(', '));
                this.errors.push({infosLine: item[0], labelsError});
              }
            }
            for (const item of res.elementsImported) {
              let index = this.articles.findIndex(article => article.id == item.id);
              if (index >= 0)
                this.articles[index] = item;
              else
                this.articles.push(item)
            }

            if (this.errors != null && this.errors.length > 0)
              this.displayImportErrors();
          });
      });
  };

  displayImportErrors = (): void => {
    this.displayImportPopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayImportPopupErrors = false;
  }

  save = () => {
    this.catalogueAchatSvc.saveCatalogueAchatList(this.articles).subscribe(async (response) => {

      if (!this.utils.isResponseSupplierError(response)) {
        this.articles = response.resultList;
        this.successMessage();
        await this.customStore.load();
        await this.grid.instance.refresh();
      }
    });
  };

  successMessage = () => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Mise à jour des prix effectuée avec succès`);

  /**
   * Ouverture d'un article (en fait un {@link CatalogueAchatDTO} pour édition.
   * @param catalogueAchat
   */
  openArticle = (catalogueAchat: CatalogueAchatDTO) => {

    const route = 'infos';
    let isNew = false;
    const produit = new ProduitDTO();
    produit.id = catalogueAchat.produitId;
    this.gds.search(this.produitDeclinaisonSvc.filterProduitDeclinaisonList(produit.id, this.produitDeclinaisonSvc.getEntityName())).subscribe(response => {

      produit.produitDeclinaisons = response.resultList;

      // on demande l'ouverture du dialogue
      this.catalogueAchatSvc.announceOpenDialogArticle({
        catalogueAchat,
        produit: produit,
        uniteDeProduction: this.articleSupplier.selectedUniteDeProduction,
        route,
        rootRoute: [`${FP_ROUTES.GFO}`, 'articles', 'article-affecte', produit.id, route],
        isNew,
        bodyHeight: 1000,
        routeProvenance: PROVENANCE.FOURNISSEUR,
        allProduitsDeclines: response.resultList
      });

    });


  };

  help = () => undefined;


  openCreateArticle = (param: any) => {
    this.articleSupplier.event = param;
    this.catalogueAchatSvc.announceOpenMajCreatArticle(this.articleSupplier);
  };


  updatePrefere = (groupeAchatCa: GroupeAchatCaViewModel, catalogueAchatList: CatalogueAchatDTO[], rowData: CatalogueAchatDTO, prefere: boolean) => {

    if (this.canModify(rowData) && prefere && !this.utils.isCollectionNullOrEmpty(catalogueAchatList)) {
      // récuperation des catalogue acaht rattaché au meme produit déclinaison
      const codeProduitDeclinaison = rowData.produitDeclinaisonCode;
      let elts = catalogueAchatList.filter(item => item.produitDeclinaisonCode === codeProduitDeclinaison);
      if (elts) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.INFO, `Mise à jour de l'article préféré en cours de réalisation`);
        elts = elts.map((item: CatalogueAchatDTO) => {

          item.groupeAchatCaList.forEach((gca: GroupeAchatCaViewModel) => {
            gca.prefere = false;
            if (gca.id === groupeAchatCa.id) {
              gca.prefere = true;
            }
          });
          return item;
        });
        this.udpCaSvc.updatePrefere(groupeAchatCa.id, this.selectedUniteDeProduction.id).subscribe(data => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'article préféré pour la denrée ${rowData.produitDeclinaisonCode} ${rowData.produitDeclinaisonLibelle} a été modifié avec succès`);
        });
      }
    }
  };

  canModify = (ca: CatalogueAchatDTO): boolean => {
    if (this.utils.isNullOrEmpty(this.selectedUniteDeProduction)) {
      return false;
    } else {
      return this.auth2Svc.isSiteLocal(this.selectedUniteDeProduction.site.id);
    }
  };

  canModifyActionButton = (cell: CatalogueAchatDTO) => {
    if (this.utils.isNullOrEmpty(cell.fournisseurOwnerLibelle)) {
      return this.auth2Svc.isSiteLocal(cell.site.id);
    }
    return false;
  };

  onChangeIsVisibleCaInherited = () => {
    this.customStore.load();
    this.grid.instance.refresh();
  };

  disabledPriceNumberBox = (ca: CatalogueAchatDTO) => !this.auth2Svc.isSiteLocal(ca.site.id) || ca.caParentId !== 0;

  deleteArticle = async (catalogueAchat: CatalogueAchatDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer l'article ${catalogueAchat.produitArticle.libelle} du fournisseur ${catalogueAchat.fournisseur.libelle} ?`,
      'Suppression d\'article');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.catalogueAchatSvc.deleteCatalogueAchat(catalogueAchat.id).subscribe(async (response: ResponseWrapper<IsDeletableObject>) => {
        this.utils.deleteMessage(response, MSG_KEY.ROOT);
        await this.grid.instance.refresh();
      });
    }
  };

  isDisabledDeleteBtn = (cell: CatalogueAchatDTO) => {
    const isLocal = this.auth2Svc.isSiteLocal(cell.site.id);
    const isheritedCa = cell.caParentId;
    if (isheritedCa) {
      return true;
    } else {
      return !isLocal;
    }
  };

  private _onEditingStart = ($event: any): void => {

  }

  onChangePrixValueEditCellTemplate = (event, cell): void => {
    if(!this.utils.isNullOrEmpty(cell.data) && cell.data.groupeAchatCaList.length){
    const newValue = event.target.value;
    const gaca: GroupeAchatCaViewModel  = cell.data.groupeAchatCaList.find( item => item.codeGroupeAchat === 'DEFAULT');
    this.catalogueAchatSvc.updatePrixGroupeAchatCatalogueAchat(newValue, gaca.idCatalogueAchat, this.articleSupplier?.selectedUniteDeProduction?.id, cell.data.fournisseur?.id).subscribe(data =>{
      this.grid.instance.refresh();
    });
    }
  }

  getPrixValueEditCellTemplate = (cell: any) :number => {
    if(!this.utils.isNullOrEmpty(cell.data) && cell.data.groupeAchatCaList.length){
      const gaca = cell.data.groupeAchatCaList[0];
      return gaca.prixUF.value;
    }
  }
}

