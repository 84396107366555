import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {combineLatest, Subject, Subscription} from "rxjs";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {BesoinsService} from "../../../../../core/services/gestion-commandes/besoins.service";
import {debounceTime} from "rxjs/operators";
import {AppellationDTO} from "../../../../../core/dtos/appellations-dto";
import {AllergeneDTO} from "../../../../../core/dtos/allergene-dto";
import {AppellationsService} from "../../../../../core/services/entities/appellations.service";
import {AllergenesService} from "../../../../../core/services/entities/allergenes-service.service";
import {concat as _concat} from 'lodash'
import {Chips} from "primeng/chips";
import {ProduitDeclinaisonService} from "../../../../../core/services/entities/produit-declinaison.service";
import {DxDataGridComponent, DxTreeViewComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {FamillesProduitService} from "../../../../../core/services/entities/familles-produit.service";
import {FamilleProduitDTO} from "../../../../../core/dtos/famille-produit-dto";
import {DeclinaisonsService} from "../../../../../core/services/declinaisons/declinaisons.service";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import {CatalogueAchatDTO} from "../../../../../core/dtos/catalogue-achat-dto";

@Component({
  selector: 'yo-ajout-denree-proposition-commande',
  templateUrl: './ajout-denree-proposition-commande.component.html',
  styleUrls: ['./ajout-denree-proposition-commande.component.scss']
})
export class AjoutDenreePropositionCommandeComponent implements OnInit, OnDestroy {

  @ViewChild('basketProduitDeclinaison') basketProduitDeclinaison: Chips;
  @ViewChild('treeViewProduitDeclinaison', {static: false}) treeView: DxTreeViewComponent;
  @ViewChild('gridAjoutDenreePropositionCommande') gridAjoutDenree: DxDataGridComponent;

  displayDialog: boolean = false;
  fullScreen: boolean = true;
  customStore: CustomStore;
  deltaGrid: number = 400;

  catalogueAchatList: CatalogueAchatDTO[] = [];
  appellationList: AppellationDTO[] = [];
  appellationListSelected: number[] = [];
  allergeneList: AllergeneDTO[] = [];
  allergeneListSelected: number[] = [];
  familleProduitList: FamilleProduitDTO[] = [];
  idFamilleProduitListSelected: number[] = [];
  selectedRowKeys: number[] = [];
  declinaisonList: DeclinaisonDTO[] = [];
  declinaisonListSelected: number[] = [];
  treeBoxValue: string[];
  treeDataSource: any;

  denreeSearchValue: string = '';

  private subjectDenreeLibelleSearch = new Subject<String>();
  denreeLibelleSearch$ = this.subjectDenreeLibelleSearch.asObservable();

  subOpenDialog: Subscription;
  subDenreeLibelleSearch: Subscription;

  isCollapsedSearchPanel: boolean;
  idsUniteProduction: number[] = [];

  /**
   * Produits déclinaison sélectionnés
   */
  selectedCatalogueAchat: CatalogueAchatDTO[] = [];

  constructor(public utils: UtilsService,
              private cd: ChangeDetectorRef,
              private besoinSvc: BesoinsService,
              private appelationSvc: AppellationsService,
              private allergeneSvc: AllergenesService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private familleProduitSvc: FamillesProduitService,
              private declinaisonSvc: DeclinaisonsService) {
  }

  async ngOnInit() {
    await this.initData();
    this.subscriptionOpenDialog();
    this.subscriptionDenreeLibelle();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subDenreeLibelleSearch);
  }

  initData = (): Promise<any> => {
    const all$ = combineLatest([
      this.appelationSvc.findAll(),
      this.allergeneSvc.findAll(),
      this.familleProduitSvc.findAll(false),
      this.declinaisonSvc.findAllByParams(false)
    ]);

    return all$.toPromise().then(response => {
      this.appellationList = response[0].resultList;
      this.allergeneList = response[1].resultList;
      this.familleProduitList = response[2].resultList;
      this.declinaisonList = response[3].resultList;
    });
  }

  subscriptionOpenDialog = (): void => {
    this.subOpenDialog = this.besoinSvc.openAjoutDenreePropositionCommande$.subscribe((data: any) => {
      this.idsUniteProduction = data.idsUniteProduction;
      this.displayDialog = true;
      this.customStore = this.initCustomStore();
    });
  }

  subscriptionDenreeLibelle = (): void => {
    this.subDenreeLibelleSearch = this.denreeLibelleSearch$.pipe(debounceTime(500))
      .subscribe(async (data: string) => {
        this.denreeSearchValue = data;
        await this.gridAjoutDenree.instance.refresh();
      });
  }

  announceDenreeLibelleSearch = (data?: any): void => {
    this.subjectDenreeLibelleSearch.next(data.value);
  }

  closeDialog = (): void => {
    this.selectedCatalogueAchat = [];
    this.customStore = null;

    this.displayDialog = false;
    this.allergeneListSelected = [];
    this.appellationListSelected = [];
    this.declinaisonListSelected = [];
    this.denreeSearchValue = '';

    //reinitialisation du treeview
    this.idFamilleProduitListSelected = [];
    this.treeBoxValue = [];
    this.treeView?.instance.unselectAll();

    this.gridAjoutDenree.instance.refresh();
  }

  sendProduitDeclinaisonList = (): void => {
    this.produitDeclinaisonSvc.announceProduitDeclinaisonListPropCommande(this.selectedCatalogueAchat);
    this.closeDialog();
  }

  onToggleSearchPanel = (event: any): void => {
    this.isCollapsedSearchPanel = event.collapsed;
  }

  onChangeAppellation = (event: any): void => {
    this.appellationListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeAllergene = (event: any): void => {
    this.allergeneListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeDeclinaison = (event: any): void => {
    this.declinaisonListSelected = event.value;
    this.gridAjoutDenree.instance.refresh();
  }

  onChangeParentDenreeSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.treeBoxValue = selectedNodeKeys;
    this.idFamilleProduitListSelected = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.gridAjoutDenree.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.familleProduitList.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = _concat([...result], this.reflexionSearchId([...this.familleProduitList], fp.id))
        }
      })
    });

    result = _concat([...result], idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = _concat([...idResultList], this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  initCustomStore = (): CustomStore => {
    return new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        return this.produitDeclinaisonSvc.searchProduitDeclinaisonByParams({
          onlyProductsMade: false,
          produitDeclineLabel: this.denreeSearchValue || '',
          idsDeclinaison: this.declinaisonListSelected || [],
          idsFamilyProduct: this.idFamilleProduitListSelected || [],
          idsAllergenes: this.allergeneListSelected || [],
          idsAppellations: this.appellationListSelected || [],
          idsUniteProduction: this.idsUniteProduction || [],
          page: this.gridAjoutDenree.instance.pageIndex() + 1,
          size: this.gridAjoutDenree.instance.pageSize()
        }).toPromise()
          .then(response => {
            this.catalogueAchatList = response.resultList;
            return {
              data: this.catalogueAchatList,
              totalCount: response.totalElements
            }
          });
      },
      update: (key, values) => {
        return new Promise((resolve, reject) => resolve({
          data: values,
          totalCount: this.catalogueAchatList.length
        }));
      }
    });
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  removeProduitDeclinaison = (catalogueAchat: CatalogueAchatDTO): void => {
    if (catalogueAchat.quantite) {
      catalogueAchat.quantite--;
      if (catalogueAchat.quantite < 0) {
        catalogueAchat.quantite = 0;
      }
    } else {
      catalogueAchat.quantite = 0;
    }
    this.selectProduitDeclinaison(catalogueAchat);
  };

  addProduitDeclinaison = (catalogueAchat: CatalogueAchatDTO): void => {
    if (!catalogueAchat.quantite) {
      catalogueAchat.quantite = 0;
    }
    catalogueAchat.quantite++;
    catalogueAchat.prix = catalogueAchat.groupeAchatCaList && catalogueAchat.groupeAchatCaList.length
    && catalogueAchat.groupeAchatCaList[0].prixUF ? catalogueAchat.groupeAchatCaList[0].prixUF.value : catalogueAchat.prix
    this.selectProduitDeclinaison(catalogueAchat);
  };

  isSelectedProduitDeclinaison = (catalogueAchat: CatalogueAchatDTO): boolean => catalogueAchat.quantite && catalogueAchat.quantite > 0;

  selectProduitDeclinaison = (catalogueAchat: CatalogueAchatDTO): void => {
    let i = 0;
    while (i < this.selectedCatalogueAchat.length && this.selectedCatalogueAchat[i].id !== catalogueAchat.id) {
      i++;
    }
    if (i >= this.selectedCatalogueAchat.length) {
      if (this.isSelectedProduitDeclinaison(catalogueAchat)) {
        this.selectedCatalogueAchat.push(catalogueAchat);
      }
    } else {
      if (!this.isSelectedProduitDeclinaison(catalogueAchat)) {
        this.selectedCatalogueAchat.splice(i, 1);
      }
    }
  };

}
