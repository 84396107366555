import {ObjectDTO} from '../object-dto';
import {SecteurFournisseurDTO} from '../secteurfournisseur-dto';
import {UnitedemesureDTO} from '../unitedemesure-dto';

export class CatalogueAchatNonAffecteDTO extends ObjectDTO {

  // info general
  libelle: string;
  reference: string;
  referenceArticle: string;
  codeApi : string;

  filialeLibelle: string;
  filialeId: number
  filialeCode: string;
  filialeRefSite: string;

  secteurFournisseur: SecteurFournisseurDTO;
  secteurLibelle: string;
  secteurId: string;

  fournisseurLibelle: string;

  prix: number;
  prixMoyen:number;

  // unites de mesure
  uniteDeCommande: UnitedemesureDTO;
  uniteDeFacturation: UnitedemesureDTO;
  uniteDeStockage:UnitedemesureDTO;

  ratioUniteCommandeUniteBase: number;
  ratioUniteFacturationUniteBase: number;
  ratioUniteStockageUniteBase : number;

  minimumDeCommande : number;

  actif: boolean;
  bio: boolean;
  circuitCourt: boolean;

}
