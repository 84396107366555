import {ObjectDTO} from "./object-dto";

export class ContratMenuConvive__ContrainteAlimDTO extends ObjectDTO {

  referent:boolean;

  //    contrat menu convive
  cmcId: number;
  cmcLibelle: string;
  cmcCode: string;
  cmcActif: boolean;

  // regime
  regimeCode: string;
  regimeLibelle: string;
  regimeId: number;
  regimeActif: boolean;

  // texture
  textureCode: string;
  textureLibelle: string;
  textureId: number;
  textureActif: boolean;

  // Contrat Menu
  cmLibelle: string;
  cmSiteLibelle: string;
  cmSiteId:number;



  // transient
  libelle:string;

}
