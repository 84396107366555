import {Component} from '@angular/core';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ConvivesService} from '../../core/services/entities/convives.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-convives',
  templateUrl: './convives.component.html',
  styleUrls: ['./convives.component.scss']
})
export class ConvivesComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/convives';

  constructor(public conviveSvc: ConvivesService,
              public gds: GenericDatagridService) {
  }

}
