import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DxTreeViewComponent} from "devextreme-angular";
import {DATEPICKER_FR, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {PlanProductionDTO} from "../../../../core/dtos/plan-production-dto";
import {MenusPlanning2Service} from "../../../../core/services/gestionmenus/menus-planning2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {Calendar} from "primeng/calendar";
import {RoutemapService} from "../../../../core/services/routemap.service";

@Component({
  selector: 'yo-selection-filters-plan-de-production',
  templateUrl: './selection-filters-plan-production.component.html',
  styleUrls: ['./selection-filters-plan-production.component.scss']
})
export class SelectionFiltersPlanProductionComponent implements OnInit, OnDestroy, AfterViewInit {

  private subRoute: Subscription;

  treeBoxValue: string[];
  treeDataSource: any;

  localeFr = DATEPICKER_FR;

  selectedDates: Date[] = [];

  treeFilters: any[] = [];
  effectifs: any[] = [];
  idsSelected: number[];
  idxEffectif: number = 0;

  startMonth: number = null;
  currentYear: number= null;

  planProduction: PlanProductionDTO;
  validDatesFromMenu: Date[] = [];

  pathFile: string = HELP_FOLDERS.PLANS_PRODUCTION + '/select-filters';

  idsPlcForMenuFromSearch: string[] = [];

  @ViewChild('treeView') treeView: DxTreeViewComponent;
  @ViewChild('calendar') calendar: Calendar;
  currentEffectif: string;
  displayPopupErrors: boolean = false;
  dishesWithoutConfigurationTeams: any[] = [];

  teamsDisabled: any[] = [];

  offresAlimentairePrestationIncompleteConfiguration: any[] = [];

  excludedDishesWithoutEffectif: boolean = false;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private prodSvc: ProductionService,
              private menuPlanning: MenusPlanning2Service,
              private toastSvc: ToastService,
              private routeMapSvc: RoutemapService
              ) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  updateStartMonth = ($event): void => {
    this.startMonth = $event.month;
    this.currentYear = $event.year;
    this.updateMenuPlanning(null);
  }

  updateMenuPlanning = ($event): void => {
    this.idsPlcForMenuFromSearch = [];
    const idsCmcForMenu: number[] = [];
    const idsRepasForMenu: number[] = [];
    const idsPlcForMenu: number[] = [];

    const idsSelected: string[] = this.treeView.instance.getSelectedNodeKeys().filter(key => key.includes("_plc"));
    idsSelected.map(idSelected => {
      let tmp: number[] = idSelected.split('_')[0].split('-').map(id => parseInt(id));
      idsCmcForMenu.push(tmp[0]);
      idsRepasForMenu.push(tmp[1]);
      idsPlcForMenu.push(tmp[2]);
    });
    const now: Date = new Date();
    const month = this.startMonth || now.getMonth() + 1;
    const year = this.currentYear || now.getFullYear();
    if (idsCmcForMenu.length && idsRepasForMenu.length && idsPlcForMenu.length) {
      this.menuPlanning.findValidDates(month, year, 2, idsCmcForMenu, idsRepasForMenu, idsPlcForMenu)
        .toPromise()
        .then((dates) => this.validDatesFromMenu = dates.resultList.length ? dates.resultList.map(date => new Date(date)) : []);
    } else {
      this.validDatesFromMenu = [];
    }
  }

  onEffectifChanged = ($event): void => {
    this.idxEffectif = this.effectifs.indexOf($event.value);
  }

  saveCurrentStep = (): void => {
    const filters = this.commonFiltersCurrentStep();
    this.prodSvc.saveFilters(this.planProduction.id, filters)
      .subscribe(() => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'étape a été sauvegardée avec succès`));
  }

  generatePlanProduction = (): void => {
    const filters = this.commonFiltersCurrentStep();
    this.prodSvc.generateRowsPlanProduction(this.planProduction.id, filters, this.excludedDishesWithoutEffectif)
      .subscribe((response) => {
        if ((!response.one.rowsWithoutConfigurationTeams || !response.one.rowsWithoutConfigurationTeams.length) && (!response.one.offresAlimentairePrestationIncompleteConfiguration || !Object.keys(response.one.offresAlimentairePrestationIncompleteConfiguration).length) &&  (!response.one.teamsDisabled || !Object.keys(response.one.teamsDisabled).length)) {
          this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', this.planProduction.id]);
          this.prodSvc.announceRefreshItems(response.one);
        } else {
          this.displayPopupErrors = true;

          this.dishesWithoutConfigurationTeams = response.one.rowsWithoutConfigurationTeams.map(row => ({ dish: row.produitDeclinaison.libelle,
            tache: row.produitDeclinaison.tache.libelle,
            urlUdpEquipe: `/gestion-unitesproduction(secondary:gestion-unitesproduction-uniteDeProduction/${row.uniteDeProduction.id}/equipes/parametrage)`,
            urlOffreAlimPresta: `/gestioncontrats(secondary:gestioncontrats-contratmenuconvive/${row.offreAlimentairePrestation.offreAlimentaire
              .id}/${row.offreAlimentairePrestation.id}/equipes)` })
          );
          Object.entries(response.one.teamsDisabled).forEach(([key, value]) => {
            let tmp = key.split(" ");
            let teamLabel = '';
            for (let i = 0; i < tmp.length - 1; ++i) teamLabel += (tmp[i] + ' ');
            let idUdp = tmp[tmp.length - 1];

            const elt = this.teamsDisabled.find(x => x.team === teamLabel);
            if (elt)
              elt.days.push(this.utils.convertDayOfWeekFrenchToString(parseInt(`${value}`)));
            else
              this.teamsDisabled.push({
                team: teamLabel,
                days: this.utils.convertDayOfWeekFrenchToString(parseInt(`${value}`)),
                urlUdpEquipe: `/gestion-unitesproduction(secondary:gestion-unitesproduction-uniteDeProduction/${idUdp}/equipes/planning-fabrications)`,
              });
          });

          Object.entries(response.one.offresAlimentairePrestationIncompleteConfiguration).forEach(([key, value]) => {
            let tmp = key.split("%@@%");
            let offreAlimentationPrestationLabel: string = tmp[0];
            let tacheLabel: string = tmp[1];
            let idOffreAlimentaire: number = parseInt(tmp[2]);
            if (!this.offresAlimentairePrestationIncompleteConfiguration.length || !this.offresAlimentairePrestationIncompleteConfiguration.filter(o => o.offreAlimentationPrestationLabel === offreAlimentationPrestationLabel && o.tacheLabel === tacheLabel).length)
            this.offresAlimentairePrestationIncompleteConfiguration.push({
              offreAlimentationPrestationLabel,
              tacheLabel,
              id: `${offreAlimentationPrestationLabel}${tacheLabel}`,
              url: `/gestioncontrats(secondary:gestioncontrats-contratmenuconvive/${idOffreAlimentaire}/${value}/equipes)`
            });
          });
        }
      });
  }

  private commonFiltersCurrentStep() {
    const idsSelected: string[] = this.treeView.instance.getSelectedNodeKeys().filter(key => key.includes("_plc"));
    if (this.idsPlcForMenuFromSearch.length)
      this.idsPlcForMenuFromSearch.forEach(id => idsSelected.push(id));

    const treeFilters = idsSelected.map(idSelected => {
      let tmp: number[] = idSelected.split('_')[0].split('-').map(id => parseInt(id));
      const idUniteProduction: number = parseInt(idSelected.split('_')[2].split('-')[1]);
      return ({
        idUniteProduction,
        idContratMenuConvive: tmp[0],
        idRepas: tmp[1],
        idPointDeLivraison: tmp[2],
      });
    });
    const filters: any = {
      treeFilters,
      typeEffectif: this.idxEffectif,
      startDate: this.selectedDates[0],
      endDate: this.selectedDates[this.selectedDates.length - 1] || this.selectedDates[0]
    };
    return filters;
  }

  initSubscriptions = (): void => {

    const pathnameSplitted = window.location.pathname.split("/");
    const idPlanProduction = parseInt(pathnameSplitted[pathnameSplitted.length - 1].replace(")",""));
    this.prodSvc.findById(idPlanProduction)
      .subscribe((response) => {
        this.planProduction = response.one;

        if (this.planProduction.dateDebutProduction && this.planProduction.dateFinProduction) {
          this.selectedDates.push(new Date(this.planProduction.dateDebutProduction));
          this.selectedDates.push(new Date(this.planProduction.dateFinProduction));
          this.calendar.updateInputfield();
        }

        this.prodSvc.getFiltersPlanProduction()
          .subscribe(response => {
            const data = response.one;
            const allUdpForPlanProd: any[] = data.tree.map(udp => udp.id);
            const collapse: boolean = allUdpForPlanProd.length === 1;

            data.tree.forEach(nodeUdp => {

              let udpSelected = this.planProduction.unitesDeProductions.filter(udp => udp.id === nodeUdp.id);
              let cmcSelected = [];
              udpSelected.forEach(udp => {
                cmcSelected.push(...udp.offresAlimentairesPrestations);
              });

              let udp = ({ id: `${nodeUdp.id}_udp`, label: nodeUdp.label, icon: 'fa-industry fas', expanded: collapse, items: [] }) as any;
              let idxOffreAlim: number = 1; let idxCmc: number = 1; let idxRepas: number = 1; let idxPlc: number = 1; // DevExtreme ne veut pas de doublons d'identifiants
              nodeUdp.children.forEach(nodeOffreAlim => {
                let offreAlim = ({ id: `${nodeOffreAlim.id}_${idxOffreAlim++}_oa-${nodeUdp.id}`, label: nodeOffreAlim.label, icon: 'fa-handshake fas', expanded: collapse, items: [] }) as any;
                udp.items.push(offreAlim);
                nodeOffreAlim.children.forEach(nodeCmc => {
                  let cmc = ({ id: `${nodeCmc.id}_${idxCmc++}_cmc-${nodeUdp.id}`, label: nodeCmc.label, icon: 'fa-users fas', expanded: false, items: [] }) as any;
                  offreAlim.items.push(cmc);

                  nodeCmc.children.forEach(nodeRepas => {
                    let repas = ({ id: `${nodeCmc.id}-${nodeRepas.id}_${idxRepas++}_repas-${nodeUdp.id}`, label: nodeRepas.label, icon: 'fa-hamburger fas', expanded: false, items: [] }) as any;
                    cmc.items.push(repas);
                    nodeRepas.children.forEach(nodePlc => {
                      let currentCmc = cmcSelected?.find(cmc => cmc.id === nodeCmc.id && cmc.convive.repas.id === nodeRepas.id && cmc.convive.repas.pointLivraison.id === nodePlc.id);
                      let repasSelected = currentCmc?.convive?.repas;
                      let plcSelected = repasSelected?.pointLivraison;
                      if (nodePlc) repas.items.push({
                        id: `${nodeCmc.id}-${nodeRepas.id}-${nodePlc.id}_${idxPlc++}_plc-${nodeUdp.id}`,
                        label: nodePlc.label,
                        icon: 'fa-map-pin fas',
                        expanded: false,
                        selected: ((!udpSelected || !udpSelected.length) && nodeUdp.idSite === this.planProduction.site.id) || (currentCmc !== undefined && repasSelected !== undefined && plcSelected !== undefined && currentCmc.id === nodeCmc.id && nodeRepas.id === repasSelected.id && plcSelected.id === nodePlc.id)
                      });
                    });
                  });
                });
              });
              this.treeFilters.push(udp);
            });
            setTimeout(() => this.updateMenuPlanning(null), 2500);

            this.effectifs = data.effectifs.map(eff => {
              if (eff === 'PREVISIONNEL') return 'Effectif prévisionnel';
              if (eff === 'FABRICATION') return 'Effectif de fabrication';
              if (eff === 'FACTURATION') return 'Effectif de facturation';
              return 'Effectif prévisionnel';
            });

            if(this.planProduction.typeEffectifEnum) {
              switch(this.planProduction.typeEffectifEnum) {
                case 'PREVISIONNEL':
                  this.currentEffectif = 'Effectif prévisionnel';
                  break;
                case 'FABRICATION':
                  this.currentEffectif = 'Effectif de fabrication';
                  break;
                case 'FACTURATION':
                  this.currentEffectif = 'Effectif de facturation';
                  break;
              }
            } else {
              this.currentEffectif = 'Effectif prévisionnel';
            }

          });

      });

    // Ne détecte pas mon resolver (route.parent.data)
  }

  ngAfterViewInit(): void {
    if (this.treeFilters) this.updateMenuPlanning(null);
    this.calendar.updateInputfield();
    this.calendar.updateUI();
  }

  closeErrors = (): void => {
    this.displayPopupErrors = false;
  }

  handleValueChange = (e: any) => {
    this.excludedDishesWithoutEffectif = e.value;
  };
}
