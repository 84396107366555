import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {ListeBesoinsDto} from '../../core/dtos/liste-besoins-dto';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {Sort} from '../../core/suppliers/generics/generic-request-supplier';
import {ListeBesoinsService} from '../../core/services/entities/liste-besoins.service';
import {TreeNode} from 'primeng/api';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {cloneDeep as _cloneDeep} from 'lodash';
import {WorkflowInstanceDTO} from '../../core/dtos/workflow-instance-dto';
import {Subscription} from 'rxjs';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {OverlayPanel} from 'primeng/overlaypanel';
import {GenericManageDialogService} from "../../core/services/generics/generic-manage-dialog.service";
import {DxDataGridComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {DevextremeService, FilterItem} from "../../core/services/technique/devextreme.service";
import {ToastService} from "../../core/services/technique/toast.service";


@Component({
  selector: 'yo-grille-listes-besoins',
  templateUrl: './grille-listes-besoins.component.html',
  styleUrls: ['./grille-listes-besoins.component.scss']
})
export class GrilleListesBesoinsComponent implements OnInit, OnDestroy {

  subWorkflowInstance: Subscription;
  subInfosListeBesoins: Subscription;
  subRefreshGridListeBesoins: Subscription;

  totalRecords = 0;
  loading: boolean;
  listeBesoinsList: ListeBesoinsDto[] = [];
  selectedListeBesoin: ListeBesoinsDto;
  selectedListeBesoinDateDebut: Date;
  selectedListeBesoinDateFin: Date;
  selectedListeBesoinNodes: TreeNode[];
  selectedListeBesoinsItems: ListeBesoinsDto[] = [];

  @ViewChild('opInfosListeBesoin') opInfosListeBesoin: OverlayPanel;
  opInfosListeBesoinHasToBeVisible = false;

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  isActionDisabled: boolean = true;
  allMode: string;
  checkBoxesMode: string;

  pathFile: string = HELP_FOLDERS.LISTE_BESOINS + '/listes-besoins';

  displayDialog: boolean = false;

  constructor(public utils: UtilsService,
              public listeBesoinsSvc: ListeBesoinsService,
              public workflowSvc: WorkflowsService,
              public auth2Svc: Auth2Service,
              private gfs: GenericFormService,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private gmdSvc: GenericManageDialogService,
              private cd: ChangeDetectorRef,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.workflowInstanceMouvementSubscription();
    this.refreshGridListeBesoinsSubcription();
    this.infosListeBesoinsSubscription();
    this.initCustomStore();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subWorkflowInstance);
    this.utils.unsubscribe(this.subInfosListeBesoins);
    this.utils.unsubscribe(this.subRefreshGridListeBesoins);
  }

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        let pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        let page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort); // On trie par une seule colonne à la fois
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          page = null;
          pageSize = null;
        }

        return this.listeBesoinsSvc.getListesBesoins(page, pageSize, sorts && sorts.length ? sorts[0] : null).toPromise().then(response => {
          this.listeBesoinsList = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  workflowInstanceMouvementSubscription = (): void => {
    this.subWorkflowInstance = this.workflowSvc.workflowInstance$.subscribe(workflowInstance => {
      this.refreshListeBesoins(workflowInstance, this.listeBesoinsList);
    });
  };

  refreshGridListeBesoinsSubcription = (): void => {
    this.subRefreshGridListeBesoins = this.workflowSvc.workflowInstanceSaved$.subscribe((response: any) => {
      let lb: ListeBesoinsDto = this.listeBesoinsSvc.createListeBesoinsFromWorkflowInstance(response);
      this.gfs.saveOne(lb, this.listeBesoinsSvc.getEntityName()).subscribe(response => {
        const lb = response.one;
        this.gds.refreshDatagridPrimengAfterSave(this.listeBesoinsList, response, this.listeBesoinsSvc.getEntityName());
        this.selectedListeBesoinsItems = [lb];
        this.totalRecords = this.listeBesoinsList.length;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La liste des besoins '${lb.libelle.toUpperCase()}' est initialisée avec succès`);
        this.cd.markForCheck();
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', lb.workflowInstance.id, lb.workflowInstance.codeWorkflowStatut])
      });
    });
  };

  infosListeBesoinsSubscription = (): void => {
    let eventOpInfosListeBesoin = null;
    this.subInfosListeBesoins = this.listeBesoinsSvc.infosListeBesoins$
      .pipe(
        tap(response => eventOpInfosListeBesoin = response),
        debounceTime(300),
        filter(response => this.opInfosListeBesoinHasToBeVisible),
        switchMap(response => this.listeBesoinsSvc.getInfosListeBesoin(this.selectedListeBesoin.id)),
      )
      .subscribe(response => {
        this.opInfosListeBesoin.show(eventOpInfosListeBesoin);
        if (response) {
          this.selectedListeBesoinDateDebut = response.additionalProperties['dateDebut'];
          this.selectedListeBesoinDateFin = response.additionalProperties['dateFin'];
          this.selectedListeBesoinNodes = response.additionalProperties['selectionNodes'];
        }
      });
  };

  /**
   * Rafraichir l'instance de workflow d'une liste de besoins
   * @param newWorkflowInstance
   * @param listeBesoins
   */
  refreshListeBesoins = (newWorkflowInstance: WorkflowInstanceDTO, listeBesoins: ListeBesoinsDto[]): void => {
    this.grid.instance.refresh();
  };

  /**
   * supprimer une commande fournisseur
   * @param {ListeBesoinsDto} cf  Commande Fournisseur
   */
  openDeleteDialog = (cf: ListeBesoinsDto): void => {
    const cfToDelete = _cloneDeep(cf);
    cfToDelete.libelle = `${cfToDelete.code}-${cfToDelete.libelle}`;
    this.gmdSvc.deleteValues([], 0, [cfToDelete], this.listeBesoinsSvc.getEntityName());
  };

  /**
   * Indique si la {@link ListeBesoinsDto} passée en paramètre est modifiable.
   * @param listeBesoinDTO
   */
  canModify = (listeBesoinDTO: ListeBesoinsDto): boolean => this.gds.canModify(listeBesoinDTO);

  updateErrors = ($event: any): void => {
    if ($event) {
      let finalError = '';
      $event.forEach((erreur, idx) => {
        finalError += erreur.message;
        if (idx < $event.length - 1) finalError += ', ';
      });
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, finalError);
    }
  };

  deleteListeBesoinsItems = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
      this.gfs.deleteList(this.listeBesoinsSvc.getEntityName(), rowsSelected).subscribe(response => {
        this.grid.instance.refresh();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les listes de besoins sélectionnées ont été supprimées`);
      });
    });
  };

  openInitProcessus = (): void => {
    this.displayDialog = true;
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  }

  onEditComplete = ($event: any): void => {
    const lb: ListeBesoinsDto = $event.data;
    this.gfs.saveOne(lb, this.listeBesoinsSvc.getEntityName()).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response))
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La liste des besoins '${response.one.libelle}' est enregistrée avec succès`);
    });
  };

  showInfosListeBesoin = (listeBesoin: ListeBesoinsDto, $event: MouseEvent): void => {
    this.opInfosListeBesoinHasToBeVisible = true;
    this.selectedListeBesoin = listeBesoin;
    this.listeBesoinsSvc.announceInfosListeBesoin($event);
  };

  help = () => undefined;

  changeValueLibelle = (newData, newText, currentRowData) => {
    const idx: number = this.listeBesoinsList.findIndex(p => p.id === currentRowData.id);
    if (idx >= 0)
      this.listeBesoinsList[idx].libelle = newText;
  }

  onEditorPreparing = ($event: any): void => {
    if ($event.dataField === "libelle" && $event.parentType === "dataRow") {
      const lb: ListeBesoinsDto = $event.row.data;
      this.gfs.saveOne(lb, this.listeBesoinsSvc.getEntityName()).subscribe(response => {
      });
    }
  };

}
