import {Component, Input, OnInit} from '@angular/core';
import {StockDTO} from "../../../../core/dtos/stock-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ModelViewMatierePremiere} from "../model-view-matiere-premiere";
import {StocksService} from "../../../../core/services/gestion-stock/stocks.service";
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {DATAGRID_ROW_TYPES} from "../../../../core/services/technique/devextreme.service";


@Component({
  selector: 'yo-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss']
})
export class LotsComponent implements OnInit {

  _stocks: StockDTO[] = [];
  showEmpty = false;
  selectedStock: StockDTO;
  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'zdsLibelle', header: 'Emplacement'},
    {field: 'lotArticle', header: 'Lot'},
    {field: 'produitArticleLibelle', header: 'Article'},
    {field: 'fournisseur', header: 'Fournisseur'},
    {field: 'dateEntree', header: 'Date Entrée'},
    {field: 'dlc', header: 'DLC'},
    {field: 'quantite', header: 'Quantité en unité de stock'},
    {field: 'quantiteEnKilo', header: 'Quantité en kilo'},
    {field: 'prixUnitaireKilo', header: 'PU H.T au kilo'},
    {field: 'prixUnitaireUS', header: 'PU H.T en US'},
    {field: 'prixUnitaire', header: 'PU H.T en UF'},
    {field: 'modifiePar', header: 'Modifié par'},
    {field: 'dateDerniereModification', header: 'Dernière modification'}
  ];

  @Input() matierePremiere: ModelViewMatierePremiere;

  // criteres de recherche
  @Input() filters: any;

  @Input() set stocks(value: StockDTO[]) {
    this._stocks = value;
    this.selectedStock = this.utils.preSelectSingleList(this._stocks, this.selectedStock);
  }

  constructor(public utils: UtilsService,
              public stockSvc: StocksService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
  }

  onRowSelect($event: any) {
  }

  onCellPrepared = ($event: any) => {
    if ($event.rowType === DATAGRID_ROW_TYPES.DATA && $event.column.dataField === 'dlc' && $event.data && this.stockSvc.isPerime($event.data.dlc, this.filters)) {
      $event.cellElement.style.backgroundColor = '#e44555';
      $event.cellElement.style.color = '#fff';
      $event.cellElement.style.fontWeight = 'bold';
    }
  }

  openDialogSortie(selectedStock: StockDTO) {
    this.selectedStock = selectedStock;
    // on ouvre le dialog que si un lot est sélectionné
    if (this.utils.isNullOrEmpty(this.selectedStock)) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Pour réaliser une sortie, il faut dans un premier temps sélectionner un lot`);
    } else {
      this.stockSvc.announceOpenDialogSortie(this.filters, this.selectedStock, this.matierePremiere);
    }
  }

  openDialogEditLot(selectedStock: any) { console.log(this.matierePremiere, selectedStock);
    if (this.matierePremiere && selectedStock) {
      this.stockSvc.announceOpenDialogEditLot(this.matierePremiere, selectedStock);
    }
  }

}
