<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="null" [width]="800"></yo-help>
    </div>
  </dxi-item>
  <dxi-item location="after" class="mg-r-16">
    <yo-button
      iconClass="fas fa-print"
      class="mg-r-5"
      (onClick)="printInvoices()"
      [disabled]="isActionDisabled"
      tooltip="Imprimer les éléments sélectionnés"
    ></yo-button>
    <yo-button
      iconClass="fas fa-check"
      class="mg-r-5"
      (onClick)="validBills()"
      [disabled]="isActionDisabled"
      tooltip="Valider les éléments sélectionnés">
    </yo-button>
    <yo-button
      iconClass="fas fa-trash"
      buttonClass="cta-delete"
      (onClick)="deleteValues()"
      [disabled]="isActionDisabled"
      tooltip="Supprimer les éléments sélectionnés">
    </yo-button>
  </dxi-item>
</dx-toolbar>
<div class="filters">
  <div class="row">
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-tag-box
        [multiline]="false"
        [items]="auth2Svc.utilisateur.siteListLocaux"
        [(value)]="sitesSelected"
        placeholder="Filtrer par site..."
        displayExpr="libelle"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        applyValueMode="useButtons"
        (onValueChanged)="refreshDataSource()"
      >
      </dx-tag-box>
    </div>
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-tag-box
        [multiline]="false"
        [items]="filtersSearch?.numbers"
        [(value)]="numbersBillSelected"
        placeholder="Filtrer par numéro de facture..."
        [searchEnabled]="true"
        [showSelectionControls]="true"
        applyValueMode="useButtons"
        (onValueChanged)="refreshDataSource()"
      >
      </dx-tag-box>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-tag-box
        [multiline]="false"
        [items]="filtersSearch?.providers"
        [(value)]="providersSelected"
        placeholder="Filtrer par fournisseur..."
        displayExpr="libelle"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        applyValueMode="useButtons"
        (onValueChanged)="refreshDataSource()"
      >
      </dx-tag-box>
    </div>
    <div class="col-lg-6 col-md-12 mg-b-15">
      <dx-tag-box
        [multiline]="false"
        [items]="status"
        [(value)]="statusSelected"
        placeholder="Filtrer par statut..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        applyValueMode="useButtons"
        (onValueChanged)="refreshDataSource()"
      >
      </dx-tag-box>
    </div>
  </div>
</div>
<div class="container-table">
  <dx-data-grid
    [dataSource]="datasource"
    keyExpr="id"
    height="100%"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onSelectionChanged)="checkDisabledButtons()"
    #grid>
    <dxi-column dataField="id" caption="Actions"

                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="false"
                [alignment]="'left'"
                [width]="90"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="site.libelle" caption="Site"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="false"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="number" caption="Numéro de facture" cellTemplate="numFactureTemplate"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="provider.libelle" caption="Fournisseur"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="totalHT" caption="Total HT"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [alignment]="'left'"
                cellTemplate="totalHTCellTemplate">
    </dxi-column>
    <dxi-column dataField="shippingFees" caption="Frais de port"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [width]="100"
                [alignment]="'left'"
                cellTemplate="shippingFeesCellTemplate">
    </dxi-column>
    <dxi-column dataField="realShippingFees" caption="Frais de port ajustés"
                [allowEditing]="true"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [width]="100"
                [alignment]="'left'"
                editCellTemplate="editRealShippingFeesCellTemplate"
                cellTemplate="realShippingFeesCellTemplate">
    </dxi-column>
    <dxi-column dataField="totalTTC" caption="Total TTC"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [alignment]="'left'"
                cellTemplate="totalTTCCellTemplate">
    </dxi-column>
    <dxi-column dataField="status" caption="Statut"
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="statusCellTemplate">
    </dxi-column>
    <dxi-column dataField="dateBill" caption="Date émission" cellTemplate="dateTemplate"
                dataType="date"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowEditing]="true">
    </dxi-column>

    <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                   mode="multiple"></dxo-selection>
    <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container" >
      <yo-button
        size="small"
        [iconClass]="auth2Svc.isSiteLocal(cell.row.data.site?.id) ? 'fa fa-edit' : 'fa fa-eye'"
        buttonClass="cta-inner-edit-or-watch"
        [tooltip]="auth2Svc.isSiteLocal(cell.row.data.site?.id) ? 'Modifier la facture' : 'Consulter la facture'"
        (click)="displayInvoiceDialog(cell.row.data)">
      </yo-button>
      <yo-button
        *ngIf="auth2Svc.isSiteLocal(cell.row.data.site?.id) && cell.row.data.status === 'EN_ATTENTE_VALIDATION'"
        size="small"
        iconClass='fa fa-plus'
        buttonClass="cta-inner-secondary"
        [tooltip]="'Joindre un ou plusieurs bon(s) de réception'"
        (onClick)="joinBonReception(cell.row.data)">
      </yo-button>
    </div>
    <div class="total text-center" *dxTemplate="let cell of 'totalHTCellTemplate'">
      {{ cell.row.data.totalHT.value }} {{ cell.row.data.totalHT.uniteMonetaire }}
    </div>
    <div class="total text-center" *dxTemplate="let cell of 'totalTTCCellTemplate'">
      {{ cell.row.data.totalTTC.value }} {{ cell.row.data.totalHT.uniteMonetaire }}
    </div>
    <div class="status text-center" *dxTemplate="let cell of 'statusCellTemplate'">
      <ng-container *ngIf="cell.row.data.status === 'EN_ATTENTE_VALIDATION'">
        <i class="fas fa-hourglass-half mg-r-15"></i>
      </ng-container>
      <ng-container *ngIf="cell.row.data.status !== 'EN_ATTENTE_VALIDATION'">
        <i class="fas fa-check mg-r-15"></i>
      </ng-container>
      {{ cell.row.data.status === 'EN_ATTENTE_VALIDATION' ? 'En attente de validation' : 'Validée' }}
    </div>
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.site.libelle"></yo-site>
    </div>
    <div [class]="auth2Svc.isSiteLocal(cell.row.data.site?.id) ? 'changeDateBill' : 'forbidden'"
         *dxTemplate="let cell of 'dateTemplate'">
      <ng-container *ngIf="auth2Svc.isSiteLocal(cell.row.data.site?.id)">
        {{ !cell.value ? 'Cliquez ici pour choisir une date' : utilsSvc.formatToDDMMYYYY(cell?.value) }}
      </ng-container>
      <ng-container *ngIf="!auth2Svc.isSiteLocal(cell.row.data.site?.id)">
        {{ utilsSvc.formatToDDMMYYYY(cell?.value) }}
      </ng-container>
    </div>
    <div *dxTemplate="let cell of 'numFactureTemplate'">
      <i *ngIf="containsLitigesOrMotifsNonConformes(cell.row.data)" (click)="displayLitigesMotifsDialog(cell.row.data)"
         title="Présence de litige(s) et/ou de motif(s) de non conformité"
         class="mg-r-5 pointer fas fa-exclamation-triangle danger-color"></i>
      <i *ngIf="containsComments(cell.row.data)" (click)="displayCommentsDialog(cell.row.data)"
         title="Présence de remarque(s)" class="mg-r-5 pointer fas fa-comments text-warning"></i>
      {{ cell?.value }}
    </div>
    <div *dxTemplate="let cell of 'shippingFeesCellTemplate'">
      {{ cell.row.data.shippingFees.value }} {{ cell.row.data.totalHT.uniteMonetaire }}
    </div>
    <div *dxTemplate="let cell of 'realShippingFeesCellTemplate'">
      {{ cell.row.data.realShippingFees.value }} {{ cell.row.data.totalHT.uniteMonetaire }}
    </div>
    <div *dxTemplate="let cell of 'editRealShippingFeesCellTemplate'">
      <dx-text-box (onValueChanged)="updateRealShippingFees($event, cell.row.data)"
                   [(value)]="cell?.row.data.realShippingFees.value"></dx-text-box>
    </div>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} facture(s)">
    </dxo-pager>

  </dx-data-grid>
</div>

<dx-popup
  [showTitle]="true"
  [title]="'Commentaires'"
  [(visible)]="displayComments"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeCommentsDialog()"
>
  <dx-data-grid
    [dataSource]="currentInvoice?.comments"
    keyExpr="lotArticle.lotArticle"
    [height]="'auto'"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true">
    <dxi-column dataField="lotArticle.lotArticle" caption="Lot article"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="lotArticle.lotArticleFournisseur" caption="Lot article fournisseur"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="itemLabel" caption="Article"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>
    <dxi-column dataField="comment" caption="Remarque"
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowGrouping]="false">
    </dxi-column>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} remarque(s)">
    </dxo-pager>

  </dx-data-grid>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="'Litiges et motifs de non conformité'"
  [(visible)]="displayLitigesMotifs"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeLitigesMotifsDialog()"
>
  <div class="row">
    <div class="col-lg-6">
      <label class="bold">Motif(s) de non confirmité</label>
      <dx-data-grid
        [dataSource]="currentInvoice?.motifsNonConformes"
        keyExpr="id"
        [height]="'auto'"
        width="100%"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true">
        <dxi-column dataField="libelle" caption="Libellé"
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowGrouping]="false">
        </dxi-column>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} motif(s)">
        </dxo-pager>

      </dx-data-grid>
    </div>
    <div class="col-lg-6">
      <label class="bold">Litige(s)</label>
      <dx-data-grid
        [dataSource]="currentInvoice?.litiges"
        keyExpr="id"
        [height]="'auto'"
        width="100%"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true">
        <dxi-column dataField="libelle" caption="Libellé"
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowGrouping]="false">
        </dxi-column>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} litige(s)">
        </dxo-pager>

      </dx-data-grid>
    </div>
  </div>
</dx-popup>

<yo-dialog-details-invoice></yo-dialog-details-invoice>
<yo-dialog-join-bon-reception></yo-dialog-join-bon-reception>
