import {Component, OnDestroy, OnInit} from "@angular/core";
import {GroupeAchatDTO} from "../../../core/dtos/unite-de-production/groupe-achat-dto";
import {MenuItem} from "primeng/api";
import {Subscription} from "rxjs/index";
import {UtilsService} from "../../../core/utils/utils.service";
import {RoutemapService} from "../../../core/services/routemap.service";
import {ActivatedRoute} from "@angular/router";
import {GroupeAchatService, TAB_NAME_GR} from "../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {GroupeAchatSidebarSupplier} from "./groupe-achat-sidebar-resolver.service";

@Component({
  selector: 'yo-groupe-achat-sidebar',
  templateUrl: './groupe-achat-sidebar.component.html',
  styleUrls: ['./groupe-achat-sidebar.component.scss']
})
export class GroupeAchatSidebarComponent implements OnInit, OnDestroy {

  groupeAchat : GroupeAchatDTO;

  tabMenuGroupeAchat: MenuItem[] = [];
  activeItem: MenuItem;

  subRoute: Subscription;
  subGroupeAchatDto: Subscription;

  constructor(public utils :UtilsService,
              private groupeAchatSvc :GroupeAchatService,
              private routeMapSvc: RoutemapService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initDataSupplierAndRoute();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subGroupeAchatDto)
  }

  initDataSupplierAndRoute = () => {
    //init component via la route
    this.subRoute = this.route.data
      .subscribe((data: { groupeAchatSidebarSupplier: GroupeAchatSidebarSupplier }) => {
        this.groupeAchat = data.groupeAchatSidebarSupplier.groupeAchat;
        this.initTabMenuGroupeAchat(this.groupeAchat, TAB_NAME_GR.info);
      });

    // refresh component via un 'announceMarcheDto'
    this.subGroupeAchatDto = this.groupeAchatSvc.groupeAchatDto$.subscribe( (response: any) =>{
      this.groupeAchat = response.groupeAchat;
      this.initTabMenuGroupeAchat(this.groupeAchat, response.tabName);
    });

    this.groupeAchatSvc.activeMenuItem$.subscribe( (response) =>{
      switch (response){

        case TAB_NAME_GR.info:
          this.activeItem = this.tabMenuGroupeAchat[0]
          break;
        case TAB_NAME_GR.fournisseurs:
          this.activeItem = this.tabMenuGroupeAchat[1]
          break;
        default:
          this.activeItem = this.tabMenuGroupeAchat[2]
      }
    });
  };

  initTabMenuGroupeAchat = (groupeAchat: GroupeAchatDTO, tabName: number) => {
    this.tabMenuGroupeAchat = [];
    this.tabMenuGroupeAchat.push({label: `Fiche d'identité`,
      command: () => {
        this.routeMapSvc.goToSecondaryRoute(['groupe-achat', this.groupeAchat.id, 'fiche-identite']);
        this.activeItem = this.tabMenuGroupeAchat[0];
      }
    });

    // Si le goupe achat existe
    if (this.utils.isNumberGt0(groupeAchat.id)) {

      this.tabMenuGroupeAchat.push({
        label: `Fournisseurs`,
        command: () => {
          this.routeMapSvc.goToSecondaryRoute(['groupe-achat', this.groupeAchat.id, 'fournisseurs']);
          this.activeItem = this.tabMenuGroupeAchat[1];
        }
      });

      this.tabMenuGroupeAchat.push({
        label: `Membres`,
        command: () => {
          this.routeMapSvc.goToSecondaryRoute(['groupe-achat', this.groupeAchat.id, 'membres']);
          this.activeItem = this.tabMenuGroupeAchat[2];
        }
      });

    }

    switch (tabName) {
      case TAB_NAME_GR.fournisseurs :
        this.activeItem = this.tabMenuGroupeAchat[1];
        break;
      case TAB_NAME_GR.membres :
        this.activeItem = this.tabMenuGroupeAchat[2];
        break;
      default:
        this.activeItem = this.tabMenuGroupeAchat[0];
    }
  };
}
