import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FournisseurSiderbarComponent} from './fournisseur/fournisseur-sidebar/fournisseur-siderbar.component';
import {
  FP_ROUTES,
  FS_ROUTES,
  HAS_GESTIONFOURNISSEUR,
  HAS_GESTIONPRODUITS,
  HAS_IAPPRO_ADMIN,
  OUTLET_SECONDARY
} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {FournisseurFicheIdentiteComponent} from './fournisseur/fournisseur-sidebar/fournisseur-fiche-identite/fournisseur-fiche-identite.component';
import {CatalogueAchatComponent} from './articles/catalogue-achat/catalogue-achat.component';
import {ArticlesComponent} from './articles/articles.component';
import {CatalogueAchatNonAffecteComponent} from './articles/catalogue-achat-non-affecte/catalogue-achat-non-affecte.component';
import {CatalogueAchatResolverService} from './articles/catalogue-achat/catalogue-achat-resolver.service';
import {InfosComponent} from '../gestion-produits/produit/article-produit/dialog/infos/infos.component';
import {ArticlesresolverService} from '../gestion-produits/produit/article-produit/articlesresolver.service';
import {ArticleAppellationsComponent} from '../gestion-produits/produit/article-produit/dialog/appellations/article-appellations.component';
import {ArticleAllergenesComponent} from '../gestion-produits/produit/article-produit/dialog/allergenes/article-allergenes.component';
import {FournisseurFilialeGridComponent} from './fournisseur/fournisseur-sidebar/fournisseur-filiale-grid/fournisseur-filiale-grid.component';
import {GridFournisseurComponent} from './grillefournisseurs/grid-fournisseur.component';
import {GridFournisseurResolverService} from './grillefournisseurs/grid-fournisseur-resolver.service';
import {FournisseurFicheIdentiteResolverService} from './fournisseur/fournisseur-sidebar/fournisseur-fiche-identite/fournisseur-fiche-identite-resolver.service';
import {FournisseurGridResolverService} from './fournisseur/fournisseur-sidebar/fournisseur-filiale-grid/fournisseur-grid-resolver.service';
import {FournisseurCatalogueArticleComponent} from './fournisseur/fournisseur-sidebar/fournisseur-catalogue-article/fournisseur-catalogue-article.component';
import {GridGroupeAchatComponent} from "./groupe-achat/grid-groupe-achat/grid-groupe-achat.component";
import {GridGroupeAchatResolverService} from "./groupe-achat/grid-groupe-achat/grid-groupe-achat-resolver.service";
import {GroupeAchatSidebarComponent} from "./groupe-achat/groupe-achat-sidebar/groupe-achat-sidebar.component";
import {FicheIdentiteGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/fiche-identite-groupe-achat/fiche-identite-groupe-achat.component";
import {GroupeAchatSidebarResolverService} from "./groupe-achat/groupe-achat-sidebar/groupe-achat-sidebar-resolver.service";
import {MembreGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/membre-groupe-achat/membre-groupe-achat.component";
import {FounisseurGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/founisseur-groupe-achat/founisseur-groupe-achat.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: FP_ROUTES.GGA,
        component: GridGroupeAchatComponent,
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        data: HAS_IAPPRO_ADMIN,
        resolve:{
          gridGroupeAchatSupplier : GridGroupeAchatResolverService
        }
      },
      {
        path: `${FS_ROUTES.GGA_GRA}/:idGroupeAchat`,
        component: GroupeAchatSidebarComponent,
        outlet: OUTLET_SECONDARY,
        data: HAS_IAPPRO_ADMIN,
        runGuardsAndResolvers: 'always',
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        resolve:{
          groupeAchatSidebarSupplier : GroupeAchatSidebarResolverService
        },
        children: [
          {
            path: `fiche-identite`,
            component: FicheIdentiteGroupeAchatComponent,
            data: HAS_IAPPRO_ADMIN,
          },
          {
            path: `membres`,
            component: MembreGroupeAchatComponent,
            data: HAS_IAPPRO_ADMIN,
          },
          {
            path: `fournisseurs`,
            component: FounisseurGroupeAchatComponent,
            data: HAS_IAPPRO_ADMIN,
          },
        ]
      },
      {
        path: FP_ROUTES.GFO,
        component: GridFournisseurComponent,
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        data: HAS_GESTIONFOURNISSEUR,
        resolve:{
          fournisseurSupplier : GridFournisseurResolverService
        }
      },
      {
        path: `${FP_ROUTES.GFO}/articles`,
        component: ArticlesComponent,
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        runGuardsAndResolvers:'always',
        data: HAS_GESTIONFOURNISSEUR,
        children: [
          {
            path: `article-affecte`,
            component: CatalogueAchatComponent,
            data: HAS_GESTIONFOURNISSEUR,
            runGuardsAndResolvers:'always',
            resolve:{
              articlesSupplier : CatalogueAchatResolverService
            },
            children:[
              {
                path: `:idProduit/infos`,
                data: HAS_GESTIONPRODUITS,
                component: InfosComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                  articlesSupplier: ArticlesresolverService
                },
              },
              {
                path: `:idProduit/appellations`,
                data: HAS_GESTIONPRODUITS,
                component: ArticleAppellationsComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                  articlesSupplier: ArticlesresolverService
                },
              },
              {
                path: `:idProduit/allergenes`,
                data: HAS_GESTIONPRODUITS,
                component: ArticleAllergenesComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                  articlesSupplier: ArticlesresolverService
                },
              }
            ]
          },
          {
            path: `article-non-affecte`,
            component: CatalogueAchatNonAffecteComponent,
            data:HAS_GESTIONFOURNISSEUR,
          }
        ]
      },
      {
        path: `${FS_ROUTES.GFO_FOU}`,
        component: FournisseurSiderbarComponent,
        data: HAS_GESTIONFOURNISSEUR,
        outlet: OUTLET_SECONDARY,
        children: [
          {
            path: `:idFournisseur/ficheidentite`,
            component: FournisseurFicheIdentiteComponent,
            data: HAS_GESTIONFOURNISSEUR,
            resolve: {
              fournisseurSupplier: FournisseurFicheIdentiteResolverService
            }
          },
          {
            path: `:idFournisseur/cataloguearticles`,
            component: FournisseurCatalogueArticleComponent,
            data: HAS_GESTIONFOURNISSEUR,
            resolve: {
              fournisseurSupplier: FournisseurFicheIdentiteResolverService
            }
          },
          {
            path: `:idFournisseur/filialesfournisseur`,
            component: FournisseurFilialeGridComponent,
            data: HAS_GESTIONFOURNISSEUR,
            resolve: {
              fournisseurSupplier: FournisseurGridResolverService
            }
          }
        ]
      }
    ])
  ],

  exports: [RouterModule],
  providers: []
})


export class GestionFournisseursRoutingModule {


}

