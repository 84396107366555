import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {Router} from "@angular/router";
import {WizardService} from "../../../core/services/wizard-service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {SiteService} from "../../../core/site.service";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {combineLatest, Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {UnitesDeMesuresService} from "../../../core/services/entities/unites-de-mesures.service";
import {UnitedemesureDTO} from "../../../core/dtos/unitedemesure-dto";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {ConvivesService} from "../../../core/services/entities/convives.service";
import {ConviveDTO} from "../../../core/dtos/convive-dto";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";

@Component({
  selector: 'yo-wizard-configuration-step-13',
  templateUrl: './wizard-configuration-step-13.component.html',
  styleUrls: ['./wizard-configuration-step-13.component.scss']
})
export class WizardConfigurationStepThirteenComponent implements OnInit, OnDestroy {

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  private sites: SiteDTO[] = [];

  private udps: UniteDeProductionDTO[] = [];

  private unitesMesure: UnitedemesureDTO[] = [];

  produits: any[] = [];

  declinaisons: any[] = [];

  prestations: ConviveDTO[] = [];

  pointsLivraisons: PointDeLivraisonDTO[] = [];

  private subDivers: Subscription;


  constructor(private readonly wizardSvc: WizardConditionnementService,
              private readonly wizardGlobalSvc: WizardService,
              private readonly router: Router,
              private readonly utilsSvc: UtilsService,
              private readonly siteSvc: SiteService,
              private readonly udpSvc: UnitesDeProductionService,
              private readonly gds: GenericDatagridService,
              private readonly unitesDeMesuresSvc: UnitesDeMesuresService,
              private readonly graphQlSvc: GraphQLService,
              private readonly csSvc: ConvivesService,
              private readonly plcSvc: PointDeLivraisonService) {
    this.currentStep = this.wizardSvc.END;
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subDivers);
  }

  ngOnInit(): void {
    const dataStepFive: any = this.wizardSvc.getDataStepFive();
    const dataStepSix: any = this.wizardSvc.getDataStepSix();
    const queryGraphQLProducts$ = this.graphQlSvc.sendQuery(`{
      allProduits(filters: { ids: [${dataStepSix.idsProducts}] }) {
          id,
          libelle,
          code,
          produitsDeclinaisons {
            id,
            totalPoidsNetRecetteUT
            declinaison {
              id,
              libelle
            }
          }
        }
      }`);
    this.subDivers = combineLatest([
      this.siteSvc.getListeSitesActifs(),
      this.udpSvc.findUnitesProductionByFilters({ enabled: true }),
      this.gds.getAll(this.unitesDeMesuresSvc.getEntityName()),
      queryGraphQLProducts$,
      this.csSvc.findAll(),
      this.plcSvc.getPlcWithoutMcPlcList(null, null, null)
    ])
      .subscribe(res => {
        this.sites = res[0];
        this.udps = res[1].resultList;
        this.unitesMesure = res[2].resultList;
        this.produits = res[3].allProduits;
        this.prestations = res[4].resultList || [];
        this.pointsLivraisons = res[5].resultList || [];
        this.declinaisons = this.produits.filter(p => p.produitsDeclinaisons.filter(pd => dataStepFive.declinaisonsSelected.includes(pd.declinaison.id) ))
          .map(p => p.produitsDeclinaisons.map(pd => pd.declinaison) )
          .reduce((item, res) => { res.push(...item); return res; }, []);
        this.declinaisons = this.declinaisons.filter((declinaison, idx) => this.declinaisons.findIndex((d) => d.id === declinaison.id) === idx);
        this.declinaisons = this.declinaisons.filter(d => dataStepFive.declinaisonsSelected.find(dd => dd === d.id));
      });
  }

  dataModelePlat = (): any => {
    let step1 = this.wizardSvc.getDataStepOne();
    let step2 = this.wizardSvc.getDataStepTwo();

    let unitesDeProductionSelected: string = step1.unitesDeProductionSelected.map(id => this.udps.find(udp => udp.id === id)?.libelle).join(",");
    let siteSelected: string = this.sites.find(s => s.id === step1.idSelectedSite)?.libelle;
    let uniteConditionnement: string = this.unitesMesure.find(u => u.id === step2.idUniteMesureSelected)?.libelle;
    let modeModelePlat: string;
    if (step2.modeModelePlatSelected === 'PIECE_PROPORTIONNELLE')
      modeModelePlat = 'Au poids en fonction de vos prestations';
    else if (step2.modeModelePlatSelected === 'PIECE_NON_PROPORTIONNELLE')
      modeModelePlat = 'En comptant des piéçages pour chacune de vos prestations';
    else
      modeModelePlat = 'Au poids indépendemment des prestations';
    return { modeModelePlat, labelModelPlat: step2.labelModelPlat, unitesDeProductionSelected, siteSelected, uniteConditionnement };
  }

  dataModelePlc = (): any => {
    let step8 = this.wizardSvc.getDataStepEight();
    let step9 = this.wizardSvc.getDataStepNine();
    let step10 = this.wizardSvc.getDataStepTen();
    let config = this.wizardSvc.getDataStepTwelve();

    let prestationsSelected: string = step9.prestationsSelected.map(id => this.prestations.find(p => p.id === id)?.libelle).join(",");
    let siteSelected: string = this.sites.find(s => s.id === step8.idSelectedSite)?.libelle;
    let plcsSelected: string = step10.pointsLivraisonsSelected.map(id => this.pointsLivraisons.find(plc => plc.id === id)?.libelle).join(",");
    return { labelModelPlc: step8.labelModelPlc, siteSelected, prestationsSelected, plcsSelected, config };
  }

  findTotalNetUT = (idProduit: number, idDeclinaison: number): number => {
    const produit: any = this.produits
      .find(produit => produit.id === idProduit);
    const produitDecline = produit.produitsDeclinaisons.map(pd => pd)
      .find(pd => pd.declinaison.id === idDeclinaison)
    return produitDecline ? produitDecline.totalPoidsNetRecetteUT : null;
  }

  listDeclinaisons = () => this.declinaisons.map(d => d.libelle).join(",");

  reset = () => {
    this.wizardSvc.clear();
    this.onEmitNextStep.emit({
      currentStep: this.wizardSvc.STEP_INITIALIZATION
    });
  }

  resetOnlyPlc = () => {
    this.wizardSvc.clearPartPlc();
    this.onEmitNextStep.emit({
      currentStep: this.wizardSvc.STEP_CHECK_RECIPES,
      containsValuesWithZero: false
    });
  }

  redirectToProduction = () => {
    this.closeDialog();
    this.wizardSvc.clear();
    this.router.navigate(['/gestion-production/pp/plans-de-production']);
  }

  closeDialog = () => {
    this.onEmitNextStep.emit({
      currentStep: this.currentStep
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
