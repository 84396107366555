<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fas fa-cog"
          label="GÉRER LA GRILLE"
          tooltip="Gérer la grille"
          [disabled]="!hasIDistri"
          (onClick)="openCreateRowParametrage()"
        >
        </yo-button>
      </div>
    </div>
  </dxi-item>

</dx-toolbar>

<div class="row" *ngIf="mode === 'PIECE_NON_PROPORTIONNELLE'">
  <div class="col-12">
    <label class="bold">Nombre de {{ uniteAConditionnerLibelle }} à servir au convive :</label>
    <dx-data-grid
      [dataSource]="[declinaisonList]"
      height="100px"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
      #gridUc>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  cellTemplate="poidsNetTemplate"
                  [width]="150"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <div *dxTemplate="let cell of 'poidsNetTemplate'">
        <dx-number-box [min]="1" (onValueChanged)="changeNbUc($event, cell)" [value]="declinaisonsNbUCList[cell.columnIndex].nbUc"></dx-number-box>
      </div>
    </dx-data-grid>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <dx-data-grid
      [dataSource]="gridParametrage"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(382)"
      width="100%"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="130"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="130"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  dataField="declinaison.id"
                  cellTemplate="declinaisonCellTemplate"
                  [width]="400"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <dxi-column dataField="tache" caption="Type de conditionnement"
                  cellTemplate="tacheCellTemplate"
                  [width]="250"
                  [allowFiltering]="false">
      </dxi-column>
      <dxi-column dataField="remarque" caption="Commentaires"
                  cellTemplate="tacheCellCommentaires"
                  [width]="200"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          *ngFor="let declinaison of declinaisonList"
          name="SelectedRowsSummary"
          showInColumn="declinaison">
        </dxi-total-item>
      </dxo-summary>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} conditionnements">
      </dxo-pager>

      <div *dxTemplate="let cell of 'declinaisonCellTemplate'">

        <div *ngIf="mode === 'PIECE_PROPORTIONNELLE' || mode === 'PIECE_NON_PROPORTIONNELLE' ">
          <span style="display: inline-block;width: 100px;">Effectif nourri :</span>
          <input style="width: 70px" type="number" [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri" />
        </div>

        <div *ngIf="mode === 'PIECE_NON_PROPORTIONNELLE'">
          <input style="width: 70px" type="number" [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].contient" />
          {{ cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].uniteAConditionner }} nourri(ssent) {{ cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri }} convive(s)
        </div>

        <div *ngIf="mode === 'PESEE'">
          Poids net : <input style="width: 70px" type="number" [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].poidsNet" /> Kg(s)
        </div>

      </div>

      <div *dxTemplate="let cell of 'tacheCellTemplate'">
        <dx-select-box
          [dataSource]="tachesList"
          displayExpr="libelle"
          valueExpr="id"
          [(ngModel)]="cell.row.data.idtache"
          [value]="cell.row.data.idtache || tachesList[0].id">
        </dx-select-box>
      </div>

      <div *dxTemplate="let cell of 'tacheCellCommentaires'">
        <div>
          <dx-text-area [(ngModel)]="cell.row.data.remarque"></dx-text-area>
        </div>
      </div>

    </dx-data-grid>
  </div>
</div>
<div class="row">
  <div class="col-12 container-table-footer">
    <dx-data-grid
      [dataSource]="[footerList]"
      height="100px"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="conditionnementLabel" caption="" [width]="130">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="" [width]="130">
      </dxi-column>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  cellTemplate="queueCellTemplate"
                  [width]="300"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <dxi-column dataField="tache" caption="" [width]="250">
      </dxi-column>
      <dxi-column dataField="remarque" caption="" [width]="200">
      </dxi-column>

      <div *dxTemplate="let cell of 'queueCellTemplate'">
        <dx-select-box
          [dataSource]="resteList"
          displayExpr="libelle"
          valueExpr="id"
          (onValueChanged)="changeQueue($event, cell.columnIndex)"
          [value]="queuesConfiguration[cell.columnIndex] && queuesConfiguration[cell.columnIndex].reste ? queuesConfiguration[cell.columnIndex].reste.id : resteList[0].id">
        </dx-select-box>
      </div>

    </dx-data-grid>
  </div>
</div>

<div class="d-flex mg-t-10">

  <div class="mr-auto ">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
  </div>

  <div id="ctas">
    <yo-button
      tooltip="Enregistrer"
      type="submit"
      iconClass="fas fa-save"
      buttonClass="cta-success"
      (onClick)="save()"
      [disabled]="!hasIDistri"
    >
    </yo-button>
  </div>
</div>
<yo-mp-add-cv-decli></yo-mp-add-cv-decli>
