<div class="row">

  <!--FILTRES-->
  <div class="col-md-auto">

    <h3 class="first">Cycle</h3>
    <div class="input-group input-group-sm ">
      <div class="input-group-prepend ">
        <span class="input-group-text">pour</span>
      </div>
      <input type="number" style="width: 42px;" pKeyFilter="pint" (ngModelChange)="changeCycleItems($event)"
             [ngModel]="cycle">
      <div class="input-group-append">
        <span class="input-group-text">semaine(s)</span>
      </div>
    </div>

    <h3 class="first">Jours ouvrés</h3>
    <p-multiSelect [options]="joursSemaine"
                   [(ngModel)]="selectedJoursSemaine"
                   optionLabel="viewValue"
                   [displaySelectedLabel]="true"
                   selectedItemsLabel="{0} jours"
                   (onChange)="refreshGrid($event)"
                   defaultLabel="Jours ouvrés..."
                   [panelStyle]="{minWidth:'12em'}">

      <ng-template let-jourSemaine pTemplate="item">

        <div style="display:inline;position: relative;height: 25px;">
          <span style="font-size:14px;margin-top:4px">{{jourSemaine.viewValue}}</span>
        </div>

      </ng-template>

    </p-multiSelect>


    <h3 class="first">Repas</h3>
    <p-multiSelect [options]="repasList"
                   [(ngModel)]="selectedRepasList"
                   optionLabel="libelle"
                   [displaySelectedLabel]="true"
                   (onChange)="refreshGrid($event)"
                   defaultLabel="Repas..."
                   selectedItemsLabel="{0} repas sélectionnés"
                   [panelStyle]="{minWidth:'12em'}">

      <ng-template let-repas pTemplate="item">

        <div style="display:inline;position: relative;height: 25px;">
          <span style="font-size:14px;margin-top:4px">{{repas.libelle}}</span>

          <span class="floatright"><yo-site [siteLibelleTooltip]="repas.site.libelle"
                                            [siteId]="repas.site.id"></yo-site></span>
        </div>

      </ng-template>

    </p-multiSelect>

    <h3 class="first">Composantes repas</h3>
    <p-multiSelect [options]="composantesRepas"
                   [(ngModel)]="selectedComposantesRepas"
                   optionLabel="libelle"
                   [displaySelectedLabel]="true"
                   (onChange)="refreshGrid($event)"
                   selectedItemsLabel="{0} composantes"
                   defaultLabel="Composantes repas..."
                   [panelStyle]="{minWidth:'12em'}">

      <ng-template let-composante pTemplate="item">

        <div style="display:inline;position: relative;height: 25px;">
          <span style="font-size:14px;margin-top:4px">{{composante.libelle}}</span>

          <span class="floatright"><yo-site [siteLibelleTooltip]="composante.site.libelle"
                                            [siteId]="composante.site.id"></yo-site></span>
        </div>

      </ng-template>

    </p-multiSelect>


    <h3 class="first">Fréquence GEMRCN </h3>
    <div title="Calculé par le cycle en semaine, les jours et les repas" >{{planAlimentaire.nbRepas}}
      repas
    </div>


    <div class="width-200 genlabel-color font-12 mg-t-10">
      <i class="fas fa-info-circle mg-r-5"></i>
      <ul>
        <li>
          Le planning structure vos menus. Vous pourrez alors le rattacher à vos prestations.
        </li>
      </ul>
    </div>

  </div>

  <!--GRILLE-->
  <div class="col">

    <ng-container *ngIf="!isGridDisplayable()">
      <em class="font-12 genlabel-color">
        <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucun planning paramétré. Veuillez
        sélectionner des repas, composantes repas et des jours ouvrés...
      </em>

    </ng-container>

    <ng-container *ngIf="isGridDisplayable()">
      <p-table
        [scrollHeight]="utils.scrollHeightContent(150)"
        styleClass="p-datatable-sm"
        [scrollable]="true"
        [frozenWidth]="(colRepasWidth*2)+'px'"
        [value]="rowsPlanning">

        <!--FROZEN COLUMNS HEADER-->
        <ng-template pTemplate="frozenheader">
          <tr>
            <th [style.width.px]="colRepasWidth">Repas</th>
            <th [style.width.px]="colRepasWidth">Composante</th>
          </tr>
        </ng-template>

        <!--FROZEN COLUMNS BODY-->
        <ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td *ngIf="rowGroupMetadata[rowData.repas.id].index === rowIndex"
                [attr.rowspan]="rowGroupMetadata[rowData.repas.id].size">
              <div class="no-overflow"  [title]="rowData.repas.libelle">
                <strong>{{rowData.repas.libelle | uppercase}}</strong>
              </div>
            </td>
            <td>
              <div [style.height.px]="getTailleComposante(rowData)" class="no-overflow"
                   [title]="rowData.composanteRepas.libelle">
                {{rowData.composanteRepas.libelle}}
              </div>
            </td>
          </tr>
        </ng-template>

        <!--CAPTION-->
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-md-7 text-left font-12">

                  <span class="mg-r-20">
                  <p-selectButton [options]="gridViews" (onChange)="refreshGrid($event)"
                                  [(ngModel)]="selectedGridView"></p-selectButton>
                    </span>

              <!--seulement si Vue familles gemrcn-->
              <ng-container *ngIf="selectedGridView===2">
                <p-selectButton [options]="cycleItems" (onChange)="refreshGrid($event)"
                                [(ngModel)]="selectedSemaineIdx"></p-selectButton>
              </ng-container>

            </div>
            <div class="col-md-5 text-right">
              🗣️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
              <yo-button
                label="Enregistrer"
                type="submit"
                iconClass="fas fa-save"
                buttonClass="cta-success"
                (onClick)="displayDialog()"
                [disabled]="!gds.canModify(planAlimentaire)">
              </yo-button>
            </div>
          </div>
        </ng-template>

        <!--HEADER-->
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let jour of selectedJoursSemaine ">
              <th [style.width.px]="cellWidth">
                {{jour.viewValue}}
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <!--BODY-->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>

            <!--CELLULES-->
            <ng-container *ngFor="let jourSemaine of selectedJoursSemaine ">

              <ng-container
                *ngTemplateOutlet="tplCell;context:{cell:getCell(rowData,jourSemaine.value), rowData:rowData}">
              </ng-container>

            </ng-container>

          </tr>

        </ng-template>

      </p-table>

    </ng-container>

  </div>

</div>


<!--TEMPLACE CELLULE-->
<ng-template #tplCell let-cell="cell" let-rowData="rowData">

  <td [style.width.px]="cellWidth">
    <div [style.height.px]="getTailleComposante(rowData)">

      <!--<pre>{{cell | json}}</pre>-->

      <ng-container *ngIf="selectedGridView===1 || selectedGridView===3">
        <select [style.max-width.px]="120"
                class="mg-b-10"
                [title]="tooltipPlat(cell.nbPlatsParComposanteRepas)"

                (ngModelChange)="changeNbPlatsParComposanteRepas(cell,$event)"
                [ngModel]="cell.nbPlatsParComposanteRepas">
          <option [ngValue]="0">Aucun plat. (désactivé)</option>
          <option *ngFor="let num of utils.getSlots(30)" [ngValue]="num+1">{{num + 1}} plat<span
            *ngIf="num+1 > 1">s</span></option>
        </select>
      </ng-container>

      <!--FAMILLES GEMRCN ASSOCIEES-->
      <ng-container *ngIf="selectedGridView===2 || selectedGridView===3">
        <ng-container *ngTemplateOutlet="tplFamillesGemrcn;context:{cell:cell}"></ng-container>
      </ng-container>

    </div>
  </td>

</ng-template>

<!--TEMPLATE FAMILLES GEMRCN-->
<ng-template #tplFamillesGemrcn let-cell="cell">

  <ng-container *ngFor="let slot of utils.getSlots(cell.nbPlatsParComposanteRepas); let ordre=index">

    <!--template famille gemrcn-->
    <ng-container
      *ngTemplateOutlet="tplFamilleGemrcn;context:{famille:getSelectedSemaineFamille(cell,ordre+1,selectedSemaineIdx),cell:cell,ordre:ordre+1}">
    </ng-container>

  </ng-container>

</ng-template>

<!--TEMPLATE FAMILLE GEMRCN (1 ligne de plat)-->
<ng-template #tplFamilleGemrcn let-famille="famille" let-cell="cell" let-ordre="ordre">

  <div class="no-overflow cursor empty-slot" (click)="openDialogSelectFamilleGemrcn(cell,selectedSemaineIdx,ordre)">
    <ng-container *ngIf="!utils.isNullOrEmpty(famille)">
      <yo-couleur [hexColor]="famille.couleurFamilleNutritionnelle" class="mg-r-5"></yo-couleur>
      <span [title]="famille.libelleFamilleNutritionnelle"
            >{{famille.libelleFamilleNutritionnelle}}</span>
    </ng-container>
  </div>

</ng-template>

<!--DIALOG DE SELECTION DES FAMILLES-->
<ng-container *ngIf="isDialogSelectFamilleDisplayable()">
  <p-dialog
    [modal]="true"
    [dismissableMask]="false"
    [style]="{'width':'600px'}"
    [contentStyle]="{'overflow':'visible'}"
    [(visible)]="displayDialogSelectFamilles">


    <!--TITRE-->
    <ng-template pTemplate="header">
      <div class="p-dialog-title">
        Ajouter / Modifier une famille GEMRCN pour la semaine {{selectedSemaineIdx}} / {{selectedJourSemaine}}
        / {{selectedCell.repas.libelle}} / {{selectedCell.decoupageRepas.libelle}} / plat {{selectedOrdre}}
      </div>
    </ng-template>


    <!--CONTENT-->
    <div (click)="removeSelectedFamilleGemrcn($event)" class="cursor mg-b-10 removeItem"
         *ngIf="!utils.isNullOrEmpty(selectedFamilleGemrcn)">
      <i class="fas fa-times fa-xs mg-r-5 danger-color"></i>Enlever la famille GEMRCN
    </div>

    <p-listbox [(ngModel)]="selectedFamilleGemrcn"
               (onChange)="onChangeSelectedFamilleGemrcn($event)"
               [style]="{'width':'556px'}"
               [listStyle]="{'max-height':'650px','width':'550px','min-width':'550px'}"
               [options]="famillesGemrcn" optionLabel="libelle" checkbox="checkbox">

      <ng-template let-item let-i="index" pTemplate="item">
        <span class="mg-r-5">
          <yo-couleur [hexColor]="item.couleur.hexa"></yo-couleur>
        </span>
        {{item.libelle}}
      </ng-template>

    </p-listbox>


  </p-dialog>
</ng-container>


<!--DIALOG DE SAUVEGARDE DU PLANNING-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'600px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displaySave">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Enregistrer le planning {{planAlimentaire.libelle | uppercase}}
    </div>
  </ng-template>

  <!--CONTENT-->
  <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>Prestations utilisant ce modèle de menu :
  <div class="row mg-t-10" style="max-height: 300px;overflow: auto;">
    <div class="col-md-12">
      <ng-container *ngFor="let prestation of prestations">
        <div class="row mg-t-10">
          <div class="col-md-12 ">
            <span class="genlabel mg-r-5">Prestation</span><strong>{{prestation.contratMenuLibelle }}
            - {{prestation.libelle}}</strong>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-save" class="mg-r-5" buttonClass="cta-success" (onClick)="save()"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displaySave=false" buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>


</p-dialog>
