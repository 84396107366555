<h5>
  <div *ngIf="groupeAchat?.id !== 0">
    <span *ngIf="groupeAchat.site && groupeAchat.site.id" class="space">
    <label class="badge badge-secondary">
      <yo-site [siteId]="groupeAchat?.site?.id" [siteLibelle]="groupeAchat?.site?.libelle"></yo-site>
    </label>
    </span>

    <label class="badge badge-secondary mg-r-20">
      <i class="fas fa-handshake mg-r-5"></i>
      <yo-viewmode
        [yoLabelFab]="'GROUPE ACHAT'" [libelle]="groupeAchat?.libelle"
        [siteId]="groupeAchat?.site?.id">
      </yo-viewmode>
    </label>
  </div>
  <div *ngIf="groupeAchat?.id === 0">
    <label class="badge badge-secondary">
      <i class="fas fa-handshake"></i>
      <span class="mg-l-5">Création d'un groupe achat</span>
    </label>
  </div>
</h5>

<p-tabMenu [model]="tabMenuGroupeAchat" [activeItem]="activeItem"></p-tabMenu>

<p-card>
  <div class="card-size">
    <router-outlet></router-outlet>
  </div>
</p-card>
