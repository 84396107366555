<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        label="Créer"
        iconClass="fa fa-plus"
        (onClick)="createFiliale()"
        [disabled]="isDisabledCreateBtn()"
        tooltip="Créer un fournisseur">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>


<dx-data-grid
  [dataSource]="fournisseurList"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(170)"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="id" caption="Actions" alignment="center" width="100"
              [allowEditing]="false" cellTemplate="actionCellTemplate"></dxi-column>
  <dxi-column dataField="libelle" caption="Filiale" alignment="center" width="300"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="code" caption="Code" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="adresse" caption="Adresse" alignment="center" width="400"
              [allowEditing]="false"
              cellTemplate="adresseCellTemplate"></dxi-column>
  <dxi-column dataField="adresse" caption="Téléphone" alignment="center"
              [allowEditing]="false"
              cellTemplate="telephoneCellTemplate"></dxi-column>

  <dxi-column dataField="secteurFournisseurList"
              caption="Secteurs"
              [cellTemplate]="cellTemplate"
              [width]="350">
    <dxo-lookup
      [dataSource]="secteurList"
      displayExpr="libelle"
      valueExpr="id">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="francoDePortActif" caption="Franco port (FP)" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="francoDePortMontant" caption="Montant minimum FP" alignment="center"
              [allowEditing]="false"></dxi-column>
  <dxi-column dataField="francoDePortPoids" caption="Poid minimum FP" alignment="center"
              [allowEditing]="false"></dxi-column>


  <!--    TEMPLATE ADRESSE -->
  <div *dxTemplate="let cell of 'adresseCellTemplate'">
    <div>
      <span> {{getTooltipAdresse(cell.row.data.adresse)}}</span>
    </div>
  </div>

  <!--    TEMPLATE TELEPHONE-->
  <div *dxTemplate="let cell of 'telephoneCellTemplate'">
    <div>
      <span> {{cell.row.data.adresse.telephone}}</span>
    </div>
  </div>

  <!--    TEMPLATE TELEPHONE-->
  <div *dxTemplate="let cell of 'actionCellTemplate'" class="custom-button-container">
    <yo-button
      iconClass="fas fa-edit"
      buttonClass="cta-inner-edit-or-watch"
      (onClick)="editFiliale(cell.row.data)"
      [disabled]="isDisabledCreateBtn()"
      tooltip="Editer une filiale">
    </yo-button>
    <yo-button
      iconClass="fas fa-book-open"
      buttonClass="cta-inner-secondary"
      (onClick)="openDialogCatalogue(cell.row.data)"
      tooltip="Voir le catalogue articles de la filiale">
    </yo-button>
  </div>
</dx-data-grid>

<!--DIALOG FOURNISSEUR FICHE IDENTITE-->
<yo-filiale-form-dialog
  [displayDialog]="displayFormDialog"
  (onClose)="onCloseFormDialog($event)"
></yo-filiale-form-dialog>
