import {AfterContentInit, Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {MSG_KEY, MSG_SEVERITY, USER_PREFERENCE, USER_TYPE_PREFERENCE} from '../../../core/constants';
import {Subscription} from 'rxjs';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {PreferencesUtilisateurService} from '../../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {DolServiceWorkerService} from '../../../core/services/service-worker/dolsw.service';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-preferences-dialog-edit',
  templateUrl: './preferences-dialog-edit.component.html',
  styleUrls: ['./preferences-dialog-edit.component.scss'],

})
export class PreferencesDialogEditComponent implements OnInit, OnDestroy, AfterContentInit {

  selectedDisplay: { key, value };
  enableNotification = false;
  enableCopyCarbone = false;

  hasSuperAdmin: boolean = false;

  displayPreferences = false;

  saveItem: boolean = true;

  deleteItem: boolean = true;

  selectManuallyItemPrefered: boolean = true;
  addForbiddenFood: boolean = true;

  deleteForbiddenFood: boolean = true;
  replaceRawFood: boolean = true;
  duplicateProducts: boolean = true;

  importXlsItems: boolean = false;

  subPreferencesUtilisateur: Subscription;

  subOpenDialogPreferences: Subscription;

  subHasSuperAdmin: Subscription;

  displays = [
    {key: USER_PREFERENCE.GLOBAL_PREFERENCE_HOMEPAGE, value: `Aller sur l'accueil.`},
    {
      key: USER_PREFERENCE.GLOBAL_PREFERENCE_LASTPAGE,
      value: `Afficher la dernière page consultée lors de votre dernière déconnexion.`
    }
  ];

  constructor(public auth2Svc: Auth2Service,
              @Optional() public dolSwSvc: DolServiceWorkerService,
              private prefUserSvc: PreferencesUtilisateurService,
              public utils: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.init();
    this.subscriptionOpenDialogPreferences();
  }

  init() {
    const keyDefaultPage = this.prefUserSvc.getPreferenceUtilisateurValue(USER_PREFERENCE.GLOBAL_PREFERENCE_STARTPAGE);

    this.displays.map(item => {
      if (item.key === keyDefaultPage) {
        this.selectedDisplay = item;
      }
    });

    this.enableNotification = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GLOBAL_PREFERENCE_ENABLE_NOTIFICATIONS);
    this.enableCopyCarbone = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GLOBAL_PREFERENCE_ENABLE_COPY_CARBONE);

    this.saveItem = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterSaveItem;
    this.deleteItem = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterDeleteItem;
    this.selectManuallyItemPrefered = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterSelectManuallyItemPrefered
    this.addForbiddenFood = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterAddingForbiddenFood;
    this.deleteForbiddenFood = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterDeletingForbiddenFood;
    this.replaceRawFood = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterSwitchFood;
    this.duplicateProducts = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterDuplicateProduct;
    this.importXlsItems = this.prefUserSvc.preferencesGlobales.calculPriceMealsAfterImportXlsItems;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPreferencesUtilisateur);
    this.utils.unsubscribe(this.subOpenDialogPreferences);
    this.utils.unsubscribe(this.subHasSuperAdmin);
  }

  ngAfterContentInit(): void {
    this.subPreferencesUtilisateur = this.prefUserSvc.preferencesOfUser$.subscribe(prefs => {
      this.init();
    });
  }

  subscriptionOpenDialogPreferences() {
    this.subOpenDialogPreferences = this.prefUserSvc.openDialogEditPreferences$.subscribe(response => {
      this.hasSuperAdmin = response;
      this.displayPreferences = true;
    });
  }

  closePreferences() {
    this.displayPreferences = false;
  }

  savePreferences() {

    this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GLOBAL_PREFERENCE_STARTPAGE, USER_TYPE_PREFERENCE.STRING, this.selectedDisplay.key);
    this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GLOBAL_PREFERENCE_ENABLE_NOTIFICATIONS, USER_TYPE_PREFERENCE.BOOLEAN, this.enableNotification);
    this.prefUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GLOBAL_PREFERENCE_ENABLE_COPY_CARBONE, USER_TYPE_PREFERENCE.BOOLEAN, this.enableCopyCarbone);

    const globalPreferencesApp: any = {
      calculPriceMealsAfterSaveItem: this.saveItem,
      calculPriceMealsAfterDeleteItem: this.deleteItem,
      calculPriceMealsAfterSelectManuallyItemPrefered: this.selectManuallyItemPrefered,
      calculPriceMealsAfterAddingForbiddenFood: this.addForbiddenFood,
      calculPriceMealsAfterDeletingForbiddenFood: this.deleteForbiddenFood,
      calculPriceMealsAfterSwitchFood: this.replaceRawFood,
      calculPriceMealsAfterDuplicateProduct: this.duplicateProducts,
      calculPriceMealsAfterImportXlsItems: this.importXlsItems
    };

    if (!this.utils.isNullOrEmpty(this.dolSwSvc)) {
      this.dolSwSvc.enableNotifications(this.enableNotification);
    }

    this.prefUserSvc.savePreferencesUtilisateur(globalPreferencesApp).subscribe(response => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Préférences enregistrées avec succès');
        this.displayPreferences = false;
      }, error => this.utils.handleError(error));
  }
}
