import {Component} from '@angular/core';
import {DecoupagesRepasService} from '../../core/services/entities/decoupages-repas.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-decoupages-repas',
  templateUrl: './decoupages-repas.component.html',
  styleUrls: ['./decoupages-repas.component.scss']
})
export class DecoupagesRepasComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/decoupages-repas';

  constructor(public decoupagesRepasSvc:DecoupagesRepasService) { }

}
