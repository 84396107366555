import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {UnitedemesureDTO} from "../../../core/dtos/unitedemesure-dto";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-2',
  templateUrl: './wizard-configuration-step-2.component.html',
  styleUrls: ['./wizard-configuration-step-2.component.scss']
})
export class WizardConfigurationStepTwoComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  idUniteMesureSelected: number;

  uniteMesureSelected: UnitedemesureDTO;

  modeModelePlatSelected: string;

  labelModelPlat: string;

  idModelPlat: number;

  unitesAConditionner: UnitedemesureDTO[] = [];

  placeholderUniteConditionnerModelePlat: string = "Unité à conditionner";

  placeholderLabelModelePlat: string = "Libellé du modèle de plat";

  subUnitesDeProduction: Subscription;

  unitesDeProduction: UniteDeProductionDTO[] = [];

  unitesDeProductionSelected: number[] = [];


  constructor(private readonly auth2Svc: Auth2Service,
              private readonly udpSvc: UnitesDeProductionService,
              private readonly utilsSvc: UtilsService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly gdsSvc: GenericDatagridService,
              private readonly wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_TYPO_CONDITIONNEMENT;
  }

  ngOnInit() {
    this.findAllUnitesMesure();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subUnitesDeProduction);
  }

  initDataAlreadyChoosenByUser = (): void => {
    const dataAlreadyChoosen: any = this.wizardSvc.getDataStepTwo();
    if (dataAlreadyChoosen?.modeModelePlatSelected)
      this.modeModelePlatSelected = dataAlreadyChoosen.modeModelePlatSelected;
    if (dataAlreadyChoosen?.idUniteMesureSelected) {
      this.idUniteMesureSelected = dataAlreadyChoosen.idUniteMesureSelected;
      this.uniteMesureSelected = this.unitesAConditionner.find(u => u.id === this.idUniteMesureSelected);
    }
    if (dataAlreadyChoosen?.labelModelPlat)
      this.labelModelPlat = dataAlreadyChoosen.labelModelPlat;
    if (dataAlreadyChoosen?.id)
      this.idModelPlat = dataAlreadyChoosen.id;
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep, modeModelePlatSelected: this.modeModelePlatSelected, idUniteMesureSelected: this.idUniteMesureSelected, labelModelPlat: this.labelModelPlat });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep, id: this.idModelPlat, modeModelePlatSelected: this.modeModelePlatSelected, idUniteMesureSelected: this.idUniteMesureSelected, labelModelPlat: this.labelModelPlat });
  }

  findAllUnitesMesure = (): void => {
    this.gdsSvc.getAll('unitedemesure').subscribe((response: ResponseWrapper<any>) => {
      this.unitesAConditionner = response.resultList ? response.resultList : [];
      this.initDataAlreadyChoosenByUser();
    });
  }

  onChangeUniteMesure = ($event): void => {
    this.idUniteMesureSelected = $event.selectedItem?.id;
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
