<div class="d-flex flex-column size-info-general" [style.max-width.px]="utils.getMaxWidthBody()">

  <div>
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <label class="space">
      <strong *ngIf="environnementPlc?.id === 0">Nouvelle Env. de point de livraison</strong>
      <strong *ngIf="environnementPlc?.id > 0">Env. point de
        livraison {{environnementPlc?.libelle | uppercase}}</strong>
    </label>
  </div>
  <div class="mg-t-5" [style.max-height]="'calc(84vh)'" style=" overflow-y:auto;overflow-x: hidden">
    <div class="flex-grow-1">
      <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
      </yo-form>
    </div>

    <p-panel header="Point de livraisons" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
      <yo-button
        class="mg-r-15 mg-b-10"
        label="AJOUTER"
        iconClass="fa fa-plus"
        tooltip="Ajouter un point de livraison"
        (onClick)="openDialogAddPlc()">
      </yo-button>
      <dx-data-grid
        [dataSource]="environnementPlc.pointDeLivraisonList"
        keyExpr="id"
        [height]="utils.getWindowAvailableHeight(600)"
        width="100%"
        [hoverStateEnabled]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [showBorders]="true"
        #grid>
        <dxi-column dataField="id" caption="Actions" [width]="100"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    cellTemplate="actionsCellTemplate">
        </dxi-column>

        <dxi-column dataField="libelle" caption="Point de livraison" [width]="350"
                    [allowFiltering]="true">
        </dxi-column>

        <dxi-column dataField="code" caption="Code"
                    [width]="350"
                    [allowFiltering]="true">
        </dxi-column>

        <!--  ACTIONS CELL TEMPLATE-->
        <div *dxTemplate="let cell of 'siteCellTemplate'">
          <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"></yo-site>
        </div>

        <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
          <yo-cell-button (yoNavigation)="deletetPlc(cell.row.data)"
                          [yoTooltipShowDelay]="1500"
                          [yoSmall]="true"
                          yoTextAlign="center"
                          yoIconClass="fa fa-trash"
                          yoTooltip="Supprimer le marché"
          ></yo-cell-button>
        </div>
        <dxo-pager
          [showPageSizeSelector]="false"
          [showNavigationButtons]="false"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} Points de livraison">
        </dxo-pager>

      </dx-data-grid>
    </p-panel>
  </div>
</div>
<div>
  <hr>
  <div class="d-flex">
    <div class="mr-auto">
      🗣️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
    </div>
    <div class="mg-r-5">
      <yo-button iconClass="fas fa-save" (onClick)="saveEnvironnementPlc()"
                 buttonClass="cta-success"
                 [disabled]="isDisabledSaveBtn()" tooltip="Enregistrer"></yo-button>
    </div>
    <div>
      <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="utils.sidenav=false"
                 buttonClass="cta-delete"></yo-button>
    </div>
  </div>
</div>


<yo-dialog-add-plc></yo-dialog-add-plc>
