export class ExportPropertyDTO{

  label:string;
  property:string;
  locked:boolean;


  constructor(label: string, property: string, locked: boolean) {
    this.label = label;
    this.property = property;
    this.locked = locked;
  }
}
