import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {saveAs as fs_saveAs} from 'file-saver';
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";

export const URL_GET_ALL_RESULTS = `dolrest/conditionnements/calcul-conditionnements-plats/results/`;
export const URL_GET_ALL_CHILDREN_RESULTS = `dolrest/conditionnements/calcul-conditionnements-plats/children-results/`;
export const URL_START_CALCULATION = `dolrest/conditionnements/calcul-conditionnements-plats/start-calculation/`;
export const URL_DELETE_CALCULATION = `dolrest/conditionnements/calcul-conditionnements-plats/delete-calculation/`;
export const URL_EDITION_BON_CONDITIONNEMENT = `dolrest/conditionnements/calcul-conditionnements-plats/bon-conditionnement/`;
export const URL_EDITION_BON_LIVRAISON = `dolrest/conditionnements/calcul-conditionnements-plats/bon-livraison/`;

@Injectable({
  providedIn: 'root'
})
export class PreparationConditionnementService {

  constructor(private httpSvc: HttpService, private httpClient: HttpClient,
              public utils: UtilsService) { }

  /**
   * Récupère le calcul des conditionnements regroupés et non regroupés pour
   * un plan de production.
   * @param pid Identifiant du plan de production
   * @returns {Observable<>}
   */
  getAllResults = (pid: number): Observable<ResponseWrapper<any>> => this.httpSvc.get(`${URL_GET_ALL_RESULTS}${pid}`, null);

  /**
   * Récupère le détails des calculs d'une ligne conditionnement
   * @param parentId Identifiant de la ligne parente
   */
  getChildrenResults = (parentId: number): Observable<ResponseWrapper<any>> => this.httpSvc.get(`${URL_GET_ALL_CHILDREN_RESULTS}${parentId}`, null);

  /**
   * Lance le calcul des conditionnements d'un plan de production.
   * @param pid Identifiant du plan de production
   */
  startCalculation = (pid: number, refresh?: boolean): Observable<ResponseWrapper<any>> => this.httpSvc.post(`${URL_START_CALCULATION}${pid}?refresh=${refresh}`, null, null);

  /**
   * Supprime le calcul des conditionnements d'un plan de production
   * @param pid Identifiant du plan de production
   */
  deleteCalculation = (pid: number): Observable<ResponseWrapper<any>> => this.httpSvc.delete(`${URL_DELETE_CALCULATION}${pid}`, null);

  /**
   *
   * @param pid
   * @param mode SIMPLE / DETAILS
   */
  printBonConditionnement = (pid: number, mode: string, landscape?: boolean): void => {
    this.httpClient.get(`${URL_EDITION_BON_CONDITIONNEMENT}${pid}/${mode || 'SIMPLE'}?landscape=${landscape || false}`, {responseType: 'arraybuffer'})
      .subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, `edition-bon-conditionnement-plan-prod-${pid}.pdf`);
    });
  };

  printBonLivraison = (pid: number): void => {
    this.httpClient.get(`${URL_EDITION_BON_LIVRAISON}${pid}`, {responseType: 'arraybuffer'})
      .subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, `edition-bon-livraison-plan-prod-${pid}.pdf`);
    });
  };

}
