import {Component, OnInit} from '@angular/core';
import {RepasService} from '../../core/services/entities/repas.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-repas',
  templateUrl: './repas.component.html',
  styleUrls: ['./repas.component.scss']
})
export class RepasComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/repas';

  constructor(public repasSvc:RepasService) { }

  ngOnInit() {
  }

}
