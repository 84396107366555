<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [style]="{'width':'1000px'}"
  [modal]="true"
  [breakpoint]="640"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt mg-r-5"></i> Ajouter une denrée interdite
    </div>
  </ng-template>

  <ng-container>

    <div class="row">

      <div class="col-md-4">
        <p-table
          [value]="produitListInterdit"
          [columns]="colsInterdit"
          (onLazyLoad)="loadData($event, 0)"
          [rows]="50"
          [rowsPerPageOptions]="[10,20,50,100,200,500]"
          [paginator]="true"
          [lazy]="true"
          [totalRecords]="totalProduits"
          selectionMode="single"
          [scrollHeight]="'250px'"
          styleClass="p-datatable-sm"
          [responsive]="true"
          [scrollable]="true"
          [(selection)]="produitInterdit"
          dataKey="id" #dti>

          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
                <col *ngSwitchCase="'selection'" style="width:30px">
                <col *ngSwitchCase="'denree'" style="width:100px">
              </ng-container>
            </colgroup>
          </ng-template>


          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">

                <ng-container *ngSwitchCase="'selection'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'denree'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <ng-container *ngSwitchCase="'denree'">
                  <input pInputText type="text" (input)="dti.filter($event.target.value, 'denreeInterdite', undefined)"
                         style="width:200px">
                </ng-container>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">

            <tr [pSelectableRow]="rowData">

              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

                <td *ngSwitchCase="'selection'" style="text-align: left">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>

                <td *ngSwitchCase="'denree'" style="text-align: left">
                  {{rowData.libelle}}
                </td>

              </ng-container>

            </tr>
          </ng-template>

        </p-table>
      </div>

      <div class="col-md-4">

        <p-table
          [value]="produitListRemplacement"
          [columns]="colsRemplacement"
          (onLazyLoad)="loadData($event, 1)"
          [rows]="50"
          [rowsPerPageOptions]="[10,20,50,100,200,500]"
          [paginator]="true"
          [lazy]="true"
          [totalRecords]="totalProduits"
          selectionMode="single"
          [scrollHeight]="'250px'"
          styleClass="p-datatable-sm"
          [responsive]="true"
          [scrollable]="true"
          [(selection)]="produitRemplacement"
          dataKey="id" #dtr>

          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
                <col *ngSwitchCase="'selection'" style="width:30px">
                <col *ngSwitchCase="'denree'" style="width:100px">
              </ng-container>
            </colgroup>
          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">

                <ng-container *ngSwitchCase="'selection'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'denree'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

              </ng-container>

            </tr>

            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <ng-container *ngSwitchCase="'denree'">
                  <input pInputText type="text"
                         (input)="dtr.filter($event.target.value, 'denreeRemplacement', undefined)" style="width:200px">
                </ng-container>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">

            <tr [pSelectableRow]="rowData">

              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

                <td *ngSwitchCase="'selection'" style="text-align: left">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>

                <td *ngSwitchCase="'denree'" style="text-align: left">
                  {{rowData.libelle}}
                </td>

              </ng-container>

            </tr>
          </ng-template>

        </p-table>
      </div>

      <div class="col-md-4" style="margin: auto;">
        <label class="mg-r-5"><strong>Pourcentage de substitution</strong></label>
        <div class="p-inputgroup">
          <input matInput type="number" [(ngModel)]="pourcentageSubstitution">
          <span class="p-inputgroup-addon">%</span>
        </div>
        <div *ngIf="produitRemplacement && produitInterdit">
          <strong>1 {{produitInterdit.uniteDeMesure.code}}</strong> de la denrée interdite donnera
          <strong>{{getMesureDenreeSubstitution() | number : '1.0-4'}} {{produitRemplacement.uniteDeMesure.code}}</strong>
          de la denrée de substitution
        </div>
      </div>

    </div>

  </ng-container>

  <ng-template pTemplate="footer">



    <span class="mg-r-5">
      <yo-button iconClass="fas fa-sign-out-alt" (onClick)="ajouterDenreeDeSubstitution()"
                 tooltip="Ajouter une denrée de substitution"
      ></yo-button>
    </span>
    <span class="mg-r-5">
      <yo-button iconClass="fas fa-times"
                 buttonClass="cta-delete"
                 (onClick)="closeDialog()"
                 tooltip="Annuler"></yo-button>
    </span>


  </ng-template>

</p-dialog>

