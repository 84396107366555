import {Component} from '@angular/core';
import {DeclinaisonsService} from '../../core/services/entities/declinaisons.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-declinaisons',
  templateUrl: './declinaisons.component.html',
  styleUrls: ['./declinaisons.component.scss']
})
export class DeclinaisonsComponent {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/declinaisons';

  constructor(public declinaisonsService: DeclinaisonsService) { }

}
