import {Component, ViewChild} from '@angular/core';


@Component({
  selector: 'yo-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent {
  @ViewChild('grillesites') grillesites;
}
