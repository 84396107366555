import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PreparationConditionnementService} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {HELP_FOLDERS} from "../../../../core/constants";

@Component({
  selector: 'yo-preparation-calcul-conditionnement-details',
  templateUrl: './preparation-calcul-conditionnement-details.component.html',
  styleUrls: ['./preparation-calcul-conditionnement-details.component.scss']
})
export class PreparationCalculConditionnementDetailsComponent implements OnInit, OnDestroy {

  flatResults: any[] = [];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-details';

  constructor(private pcSvc: PreparationConditionnementService,
              public utils: UtilsService) {

  }

  @Input() set idParentSelected(id: number) {
    if (id) {
      this.pcSvc.getChildrenResults(id)
        .subscribe(results => {
          this.flatResults = results.resultList;
        });
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  help = (): void => {

  };

}
