import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {RepasDTO} from '../../dtos/repas-dto';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {catchError} from 'rxjs/operators';
import {startCase as _startCase} from 'lodash';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import * as moment from 'moment';

export const URL_GET_REPASLIST_BY_PRESTATION = `dolrest/gestioncontrats/contrat-menu-convive/repasList`;
export const URL_GET_REPASLIST_BY_PRESTATION_PLC = `dolrest/gestion-clients/point-de-livraison/prestation/repas-list`;
export const URL_GET_REPASLIST_BY_PLC = `dolrest/gestion-clients/point-de-livraison/repas-list`;

@Injectable()
export class RepasService extends GenericHandler<RepasDTO> {

  /**
   * Liste des repas dispo dans l'environnement
   */
  repassEnvironnement: RepasDTO[];

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getSort(): string[] {
    return ['ordre,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES TYPES DE REPAS';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UN TYPE DE REPAS';
  }

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  getEntityName(): string {
    return 'Repas';
  }

  getFields(dto: RepasDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut un Repas est actif.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new RepasDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType: 'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType: 'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType: 'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'ordre',
        label: 'Ordre',
        excelType: 'integer',
        readonly: !this.canModify(dto),
        value: dto.ordre,
        type: 'number',
        required: true,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }

  getAllFromEnvironnement(): void {

    console.log("RepasService#getAllFromEnvironnement()");
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.repassEnvironnement = [];

        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.repassEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }

        this.announceObjects(this.repassEnvironnement);

      });

  }

  createEmptyDTO(): RepasDTO {
    return new RepasDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LE REPAS '${data.libelle.toUpperCase()}'`;
  }

  /**
   * Récupérer tous les repas d'un contrat menu convive
   * @param idContratMenuConvive
   */
  getRepasList(idContratMenuConvive: number) {

    return this.http.get(URL_GET_REPASLIST_BY_PRESTATION, {
      params: new HttpParams().set('idContratMenuConvive', idContratMenuConvive + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error)),
      );

  }


  getRepasPlcPrestation(selectedPrestation: ContratMenuConviveDTO, selectedPlc: PointDeLivraisonDTO, weekMonday: Date, weekSunday: Date) {


    return this.http.get(URL_GET_REPASLIST_BY_PRESTATION_PLC, {
      params: new HttpParams()
        .set('idPrestation', selectedPrestation.id + '')
        .set('idPlc', selectedPlc.id + '')
        .set('dateDebut',this.utils.getYYYYMMDD( moment(weekMonday)))
        .set('dateFin', this.utils.getYYYYMMDD( moment(weekSunday)))
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }

  getRepasOfPlc(selectedPlc: PointDeLivraisonDTO, weekMonday: Date, weekSunday: Date) {

    return this.http.get(URL_GET_REPASLIST_BY_PLC, {
      params: new HttpParams()
        .set('idPlc', selectedPlc.id + '')
        .set('dateDebut',this.utils.getYYYYMMDD( moment(weekMonday)))
        .set('dateFin', this.utils.getYYYYMMDD( moment(weekSunday)))
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  }
}
