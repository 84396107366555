import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {ModelViewInventaire} from './model-view-inventaire';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {PrintInventaireService} from '../../../core/services/gestion-stock/print-inventaire.service';
import {ToastService} from "../../../core/services/technique/toast.service";
import {DxAccordionComponent} from "devextreme-angular";

@Component({
  selector: 'yo-inventaire',
  templateUrl: './inventaire.component.html',
  styleUrls: ['./inventaire.component.scss']
})
export class InventaireComponent implements OnInit, OnDestroy {

  subResultSearchStocks: Subscription;
  subRoute: Subscription;
  lignesInventaire: ModelViewInventaire[];
  totalDenrees = 0;
  totalLots = 0;
  selectedInventaire: ModelViewInventaire;

  loading = false;
  isSaveDisabled = true;

  // criteres de recherhce du panneau gauche (module gestion de stocks)
  ssw: SearchSupplierWrapper;

  rowGroupMetadata: any;

  timer: any;

  cols: any[] = [
    {field: 'denree', header: 'Lot'},
    {field: 'code', header: 'Code'},
    {field: 'zdsLibelle', header: 'Zone de stockage'},
    {field: 'dateEntree', header: 'Réceptionné le'},
    {field: 'dlc', header: 'DLC'},
    {field: 'uniteDeStockage', header: 'U.S'},
    {field: 'quantite', header: 'Qté'},
    {field: 'quantiteReaj', header: 'Qté Réaj'}
  ];

  feature: string;
  selectModelViewInventaire: ModelViewInventaire;

  page: any = 1;
  pageSize: any = 50;

  private isKeepCloseDialogConfirmSave: boolean = false;

  @ViewChild(DxAccordionComponent, { static: false }) accordion: DxAccordionComponent;

  constructor(
    private route: ActivatedRoute,
    private stocksSvc: StocksService,
    private produitDeclinaisonSvc: ProduitDeclinaisonService,
    public utils: UtilsService,
    public printInventaireService: PrintInventaireService,
    private toastSvc: ToastService) {
  }


  ngOnInit() {

    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subRoute = this.route.url.subscribe(response => {
      this.feature = response[0].path;
      this.stocksSvc.announceFeature(this.feature, this.page);
    });

    // s'abonner à la recherche des articles dans le stock
    this.subResultSearchStocks = this.produitDeclinaisonSvc.resultSearchStocksForInventaire$
      .subscribe(response => {

        this.lignesInventaire = response.resultList;
        this.ssw = new SearchSupplierWrapper();
        this.ssw.filtersMap = response.additionalProperties['filters'];
        this.totalDenrees = response.additionalProperties['totalDenrees'] ? response.additionalProperties['totalDenrees'] : 0;
        this.totalLots = response.additionalProperties['totalLots'] ? response.additionalProperties['totalLots'] : 0;

        this.initPagination();
      });

    // dialog confirm keep openable
    this.stocksSvc.isKeepItClose$.subscribe((response: boolean) => {
      this.isKeepCloseDialogConfirmSave = response;
    });

    // si l utilsateur annule son save le btn save reste clickable
    this.stocksSvc.isConfirmCancelled$.subscribe((reponse: boolean) => {
      this.isSaveDisabled = !reponse;
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchStocks);
    this.utils.unsubscribe(this.subRoute);
  }

  initPagination = () => {
    console.log(this.ssw)
    this.page = this.ssw.filtersMap.page ? this.ssw.filtersMap.page : 1;
    this.pageSize = this.ssw.filtersMap.size ? this.ssw.filtersMap.size : 50;
  }

  openDialogAjoutLot = (selectedInventaire: ModelViewInventaire, $event: any) => {
    $event.stopPropagation();
    this.selectedInventaire = selectedInventaire;
    this.produitDeclinaisonSvc.announceOpenDialogAjoutLot(null, this.selectedInventaire);
  };

  updateQuantityReaj = ($event: any, child: ModelViewInventaire): void => {
    clearTimeout(this.timer);
    child.quantiteReaj = $event;
    this.timer = setTimeout(() => {
      this.stocksSvc.saveInventaire([child]).subscribe(async response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.stocksSvc.announceFeature(this.feature, this.page);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les modifications ont été enregistrées avec succès`);
          this.isSaveDisabled = true;
          await this.accordion.instance.collapseItem(this.selectedInventaire.id);
        }
      });
    }, 1500);
  };

  updateQteLotsInventaire = ($event: any, inventaire: ModelViewInventaire) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.stocksSvc.updateQteLotsInventaire(inventaire, $event).subscribe(async response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.stocksSvc.announceFeature(this.feature, this.page);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les modifications ont été enregistrées avec succès`);
          await this.accordion.instance.collapseItem(this.selectedInventaire.id);
        }
      });
    }, 1500);
  }

  onClick = ($event: any, inventaire: ModelViewInventaire) => {
    this.selectedInventaire = inventaire;
    $event.stopPropagation();
  }

  handlePageChange = event => {
    this.page = event;
    this.produitDeclinaisonSvc.announcePaginationSearchStock({page: this.page - 1, size: this.pageSize});;
  };
}
