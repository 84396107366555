<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-book-reader mg-r-5 mg-l-15"></i> R&Eacute;SUM&Eacute;
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
      <yo-button
              iconClass="fas fa-highlighter"
              class="mr-1"
              (onClick)="printMenu.toggle($event)"
              tooltip="Générer le bon de conditionnement">
      </yo-button>
      <yo-button
        tooltip="Générer le bon de livraison"
        iconClass="fas fa-shipping-fast"
        class="mr-1"
        (onClick)="printLivraison(idPlanProduction)">
      </yo-button>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="helpSummaries()"
               [width]="800"></yo-help>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  id="tab-calcul-conditionnement-details"
  [dataSource]="headersResults"
  keyExpr="id"
  width="100%"
  columnResizingMode="widget"
  [allowColumnReordering]="true"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onRowClick)="loadChildren($event)"
  #gridHeaders>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="ficheTechniqueLabel" caption="Plat" [width]="100"
              [filterOperations]="['contains']"
              [allowGrouping]="true"
              [autoExpandGroup]="true"
              [groupIndex]="1">
  </dxi-column>
  <dxi-column dataField="atelierLabel" caption="Atelier" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="equipeLabel" caption="Equipe" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="pointLivraisonLabel" caption="Point livraison client" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="prestationLabel" caption="Prestation" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="regimeLabel" caption="Régime" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="repasLabel" caption="Repas" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="nbCommandes" caption="Nb. commandes" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="nbPiecesCalculated" caption="Nombre pièce(s)" cellTemplate="nbPiecesTemplate" [width]="100" [filterOperations]="['contains']"></dxi-column>
  <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="varianteLabel" caption="Variante" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="queueUc" caption="Reste" [width]="100" cellTemplate="resteTemplate"
              [filterOperations]="['contains']">
  </dxi-column>

  <dxi-column dataField="udpLibelle" caption="Unité de production" [width]="100"
              [filterOperations]="['contains']"
              [allowGrouping]="true"
              [groupIndex]="0">
  </dxi-column>
  <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="dateConsommation" caption="Date consommation" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="dateLivraison" caption="Date livraison" [width]="100"
              [filterOperations]="['contains']">
  </dxi-column>

  <div *dxTemplate="let cell of 'resteTemplate'">
    <span [className]="cell.value ? 'reste' : ''">{{cell.value}}</span>
  </div>

  <div *dxTemplate="let cell of 'nbPiecesTemplate'">
    {{cell.value}} {{ cell.value ? cell.row.data.uniteAConditionner : '' }}
  </div>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[1, 2, 20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} résumé(s)">
  </dxo-pager>

</dx-data-grid>

<div class="mg-t-5 mg-l-0" id="wrapper-lots">
  <div [style.width.px]="'90%'">
    <yo-preparation-calcul-conditionnement-details
      [idParentSelected]="idParentSelected"></yo-preparation-calcul-conditionnement-details>
  </div>
</div>

<dx-popup
  [showTitle]="true"
  title="Erreurs"
  [(visible)]="displayErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <ng-container *ngIf="errors?.plcsWithoutRulesConfiguration?.length">
    <label>Liste des points de livraison client pour lesquels aucune règle de regroupement n'a été créée : </label>
    <dx-data-grid
    [dataSource]="errors?.plcsWithoutRulesConfiguration"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column caption="Point de livraison client" cellTemplate="templatePlc" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} point(s) de livraison client">
    </dxo-pager>
    <div *dxTemplate="let cell of 'templatePlc'">
      <a (click)="goToPlcPage(cell.row.data.idClient, cell.row.data.id)"><span class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
    </div>
  </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.implementationsModelesPlatsNotRealized?.length">
    <label>Veuillez contrôler l'existence de l'implémentation d'un modèle de conditionnement plat pour les produits suivants : </label>
    <dx-data-grid
      [dataSource]="errors?.implementationsModelesPlatsNotRealized"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Produit" cellTemplate="templateProduit" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} produit(s)">
      </dxo-pager>
      <div *dxTemplate="let cell of 'templateProduit'">
        <a (click)="goToProductPage(cell.row.data.id)"><span class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
      </div>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.contraintesPlcsNotFound?.length">
    <label>Aucune contrainte point(s) de livraison client n'a été détecté : </label>
    <dx-data-grid
    [dataSource]="errors?.contraintesPlcsNotFound"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column caption="Modèle plat / Point de livraison client" cellTemplate="valueToDisplay" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <div *dxTemplate="let cell of 'valueToDisplay'">
        {{ cell.row.data }}
      </div>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} information(s)">
    </dxo-pager>
  </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="plcsNotTakenAccount?.length">
    <label>
           Certains points de livraison présent dans le plan de production ne sont pas pris en compte par le calcul du conditionnement car
           ces derniers sont absents des modèles points de livraison client :
    </label>
    <dx-data-grid
      [dataSource]="plcsNotTakenAccount"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Libellé" dataField="libelle" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} point(s) de livraison client">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>
