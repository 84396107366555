import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {
  DATEPICKER_FR,
  DATES_FORMAT,
  JOURS_SEMAINES,
  JourSemaine,
  MIME_TYPE,
  USER_PREFERENCE
} from '../../../../core/constants';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {cloneDeep as _cloneDeep, find as _find, forEach as _forEach} from 'lodash';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {MenuSearchDto} from '../../../../core/dtos/gestionmenus/menu-search-dto';
import * as moment from 'moment';
import {PrintMenuService} from '../../../../core/services/gestionmenus/print-menu.service';
import {saveAs as fs_saveAs} from 'file-saver';
import {of} from 'rxjs';
import {FrontStorageService} from '../../../../core/services/storage/front-storage.service';


@Component({
  selector: 'yo-print-menu',
  templateUrl: './print-menu.component.html',
  styleUrls: ['./print-menu.component.scss']
})
export class DialogPrintMenuComponent  implements  OnInit {

  @Input() public selectedStartDateMenus: Date;
  @Input() public selectedRegime: ContratMenuConvive__ContrainteAlimDTO;
  @Input() public contratsMenuConvives: ContratMenuConvive__ContrainteAlimDTO[];
  @Input() public regimeList : ContratMenuConvive__ContrainteAlimDTO[];

  fr = DATEPICKER_FR;
  startDate: Date;
  endDate: Date;
  selectedRegimeId:number;
  regimeOptionList: SelectItem[] = [];
  dayOfWeekOptionList: JourSemaine[] = _cloneDeep(JOURS_SEMAINES);
  selectedDayOfWeek: JourSemaine[] = [];
  isLibelleLong: boolean = true;
  isCouleur: boolean = true;
  isPrice: boolean = true;
  isCoutRepas: boolean = true;
  isContratPrestationRegime: boolean = true;
  isLogo: boolean = true;
  messagePerso: string = '';
  refresh =1;
  displayDialog :boolean;


  constructor(
    private printMenuService: PrintMenuService,
    private preferencesUtilisateurService: PreferencesUtilisateurService,
    public utils: UtilsService,
    private localStorageService: FrontStorageService) {}

  ngOnInit() {

    this.printMenuService.displayDialogPrintMenu$.subscribe((response:boolean)=>{
      this.displayDialog = response;

      this.initRegimeOptionList();
      this.messagePerso = this.localStorageService.localStoreGet('print_menu_remarque');
      this.startDate = this.selectedStartDateMenus;
      this.endDate = this.setEndDate(this.startDate);
      this.selectedRegimeId = this.selectedRegime.id;
      this.initDayOfWeekOptionListWithUserPerference();
    });

  }

  downloadMenu = () => {

    this.localStorageService.localStoreSet('print_menu_remarque', this.messagePerso);
    const params: MenuSearchDto = this.getMenuSearchDTO();
    const print$  = this.printMenuService.printMenu(params);

    print$.subscribe( response => {

      // naming file
      let reportName = 'Menu_'+params.startDate+'_au_'+params.endDate;

      let blob = new Blob([response], {
        type: MIME_TYPE.PDF // must match the Accept type
      });

      // save file
      fs_saveAs(blob,reportName);

      return of(blob);
    });

    this.closeDialog();
  };

  getMenuSearchDTO = () => {
    const menuSearch: MenuSearchDto = {
      startDate:  moment(this.startDate).clone().format(DATES_FORMAT.YYYYMMDD),
      endDate: moment(this.endDate).clone().format(DATES_FORMAT.YYYYMMDD),
      contratsMenuConviveId: this.contratsMenuConvives[0].id,
      selectedRegimeId: this.selectedRegimeId,
      joursSemaine: [],
      isLibelleLong: this.isLibelleLong,
      isCouleur: this.isCouleur,
      isPrice: this.isPrice,
      isCoutRepas: this.isCoutRepas,
      isContratPrestationRegime: this.isContratPrestationRegime,
      messagePerso : this.messagePerso,
      isMessagePerso: this.utils.isNullOrEmpty(this.messagePerso) ? false : this.messagePerso.length > 0,
      isLogo: this.isLogo
    };
    _forEach(this.selectedDayOfWeek, (item: JourSemaine) => {
      menuSearch.joursSemaine.push(item.value);
    });
    return menuSearch;
  };

  setEndDate = (startDate: Date): Date => {
    let endDate : Date;
    const offset: number = this.preferencesUtilisateurService.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS)[0] - 1 ;
    endDate =  new Date(startDate);
    endDate.setDate(endDate.getDate() + offset);
    return endDate;
  };

  initRegimeOptionList = () => {
    _forEach(this.regimeList, (item: ContratMenuConvive__ContrainteAlimDTO)  => {
      this.regimeOptionList.push({label: item.libelle, value: item.id})
    });
  };

  initDayOfWeekOptionListWithUserPerference = () => {
    _forEach(this.preferencesUtilisateurService.getPreferenceUtilisateur(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE).valeur.split(','), (itemPref: string) => {

      const jourSemaine: JourSemaine =  _find(JOURS_SEMAINES, (item: JourSemaine) => {
        return item.value === +itemPref;
      });

      this.selectedDayOfWeek.push(jourSemaine);
    });
  };

  isValidForm = (): boolean => {
    const isStartDateValid : boolean =  !this.utils.isNullOrEmpty(this.startDate);
    const isEndDateValid : boolean =  !this.utils.isNullOrEmpty(this.endDate);
    const isSelectedRegimeValid : boolean =  !this.utils.isNullOrEmpty(this.selectedRegimeId);
    return !(isStartDateValid && isEndDateValid && isSelectedRegimeValid);
  };

  uploadMenuLogo = event => {
    let uploadMenuLogo$: any;

    if(event.files.length > 0){
      uploadMenuLogo$ = this.printMenuService.uploadMenuLogo(event.files[0]);
    }
    uploadMenuLogo$.subscribe(response => {
      this.refresh = new Date().getTime();
    });
  };

  closeDialog = () => {
    this.displayDialog =false;
  };

}
