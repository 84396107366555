import {Component, OnInit} from '@angular/core';
import {FamillesProduitService} from '../../core/services/entities/familles-produit.service';
import {FamilleProduitDTO} from '../../core/dtos/famille-produit-dto';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {UtilsService} from '../../core/utils/utils.service';
import {cloneDeep as _cloneDeep} from 'lodash'
import {HELP_FOLDERS} from "../../core/constants";

export enum TypeMode {
  create = 1,
  update = 2,
  readyOnly = 3
};


@Component({
  selector: 'yo-familles-produit',
  templateUrl: './familles-produit.component.html',
  styleUrls: ['./familles-produit.component.scss']
})

export class FamillesProduitComponent implements OnInit {

  familleProduitList: FamilleProduitDTO[] = [];
  selectedFamilleProduit: FamilleProduitDTO;
  displayDialog : boolean;
  mode: number;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/familles-produits';

  constructor(public familleProduitSvc: FamillesProduitService,
              public utils: UtilsService) {
    this.selectedFamilleProduit = new FamilleProduitDTO();
    this.selectedFamilleProduit.parent = new FamilleProduitDTO();
    this.familleProduitSvc.announceFamilleProduit(this.selectedFamilleProduit);
  }

  ngOnInit() {
   this.initData();
  }

  initData(){
    this.familleProduitSvc.getAllFamilleProduits().subscribe((response: ResponseWrapper<FamilleProduitDTO>) => {
      this.familleProduitList = response.resultList;
    });
  }

  createFamilleProduit = () => {
    this.mode = TypeMode.create;
    this.displayDialog = true;
    this.familleProduitSvc.announceFamilleProduit(new FamilleProduitDTO());
  };

  updateFamilleProduit = () => {
    this.mode = TypeMode.update;
    this.displayDialog = true;
    this.familleProduitSvc.announceFamilleProduit(_cloneDeep(this.selectedFamilleProduit));
  };

  canUpdate = () => this.selectedFamilleProduit !== undefined;

  onCloseDialogForm = () => {
    this.displayDialog = false;
    this.initData();
  };

  onSelectionChanged = (event : any) => {
    this.selectedFamilleProduit = event.selectedRowsData[0];
  };

}
