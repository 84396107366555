import {Component, OnInit} from '@angular/core';
import {TypesProduitService} from '../../core/services/entities/types-produit.service';
import {HELP_FOLDERS} from "../../core/constants";

@Component({
  selector: 'yo-types-produit',
  templateUrl: './types-produit.component.html',
  styleUrls: ['./types-produit.component.scss']
})
export class TypesProduitComponent implements OnInit {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/types-de-produit';

  constructor(public typeProduitSvc: TypesProduitService) {
  }

  ngOnInit() {
  }

}
