import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../../utils/utils.service";
import {PrintOptionInventaire} from "../../models/gestion-stock/print-inventaire.model";
import {PrintOption} from "../../../gestion-stocks/stocks/inventaire/dialog-print-inventaire/dialog-print-inventaire.component";

@Injectable()
export class PrintInventaireService {

  constructor(
              private utils: UtilsService,
              private httpClient: HttpClient){

  }

  private subjectDisplayDialogPrintInventaire= new Subject();
  public displayDialogPrintInventaire$ = this.subjectDisplayDialogPrintInventaire.asObservable();

  public announceDisplayDialogPrintInventaire( isOpenDialog: boolean){
    this.subjectDisplayDialogPrintInventaire.next(isOpenDialog);
  }

  printInventaire(printOptionInventaire: PrintOptionInventaire) {
    let url = 'dolrest/gestion-stocks/print/inventaire?1=1';
    if (printOptionInventaire) {
      if (printOptionInventaire.printOptions) {
        if (printOptionInventaire.printOptions.find(opt => opt === PrintOption.hideEmpty))
          url += `&hideEmpty=${(printOptionInventaire.printOptions.some(opt => opt === PrintOption.hideEmpty)) ? 'true' : 'false'}`;
        if (printOptionInventaire.printOptions.find(opt => opt === PrintOption.detailsAccounting))
          url += `&detailsAccounting=${(printOptionInventaire.printOptions.some(opt => opt === PrintOption.detailsAccounting)) ? 'true' : 'false'}`;
        if (printOptionInventaire.printOptions.find(opt => opt === PrintOption.detailsByLot))
          url += `&detailsByLot=${(printOptionInventaire.printOptions.some(opt => opt === PrintOption.detailsByLot)) ? 'true' : 'false'}`;
        if (printOptionInventaire.printOptions.find(opt => opt === PrintOption.showQuantite))
          url += `&showQuantite=${(printOptionInventaire.printOptions.some(opt => opt === PrintOption.showQuantite)) ? 'true' : 'false'}`;
      }

      if (printOptionInventaire.searchSupplierWrapper && printOptionInventaire.searchSupplierWrapper.filtersMap) {
        let args = '';
        const searchStockSupplier: any = printOptionInventaire.searchSupplierWrapper.filtersMap;
        if (searchStockSupplier) {
          if(searchStockSupplier.dateEntree) args += `&dateEntree=${this.utils.convertYYYYMMdd(searchStockSupplier.dateEntree.getTime())}`;
          if(searchStockSupplier.datePerime) args += `&datePeremption=${this.utils.convertYYYYMMdd(searchStockSupplier.datePerime.getTime())}`;
          if(searchStockSupplier.denominationArticle) args += `&denominationArticle=${searchStockSupplier.denominationArticle}`;
          if(searchStockSupplier.referenceInterneArticle) args += `&referenceInterneArticle?=${searchStockSupplier.referenceInterneArticle}`;
          if(searchStockSupplier.seuil) args += `&seuil=${searchStockSupplier.seuil}`;
          if(searchStockSupplier.denree) args += `&denree=${searchStockSupplier.denree}`;
          if(searchStockSupplier.codeDenree) args += `&codeDenree=${searchStockSupplier.codeDenree}`;

          if (searchStockSupplier.sitesIds && searchStockSupplier.sitesIds.length) args += `&sitesIds=${searchStockSupplier.sitesIds}`;
          if (searchStockSupplier.idsUniteProductionZonesStockage && searchStockSupplier.idsUniteProductionZonesStockage.length) args += `&idsUniteProductionZonesStockage=${searchStockSupplier.idsUniteProductionZonesStockage}`;
          if (searchStockSupplier.idsZonesStockage && searchStockSupplier.idsZonesStockage.length) args += `&idsZonesStockagesSelected=${searchStockSupplier.idsZonesStockage}`;
        }
        url += args;
      }
    }

    return this.httpClient.get(url,{
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  }

}
