<dx-popup
  title="Préférences"
  [(visible)]="displayPreferences"
>
  <p>
    Utilisateur actuel : {{auth2Svc?.utilisateur?.prenom | titlecase}} {{auth2Svc?.utilisateur?.nom | titlecase}} <br/>
    Profil(s) associé(s) : <strong>{{auth2Svc?.utilisateur?.profil}}</strong>
  </p>
  <dx-tab-panel>
    <dxi-item title="Au démarrage de Datameal Online" icon="comment">
      <div class="padding-choice">
        <div *ngFor="let d of displays">
          <input [id]="d.key" type="radio" class="radio-button" name="selectedDisplay" [value]="d" [(ngModel)]="selectedDisplay" /> <label [for]="d.key">{{ d.value }}</label>
        </div>
      </div>
    </dxi-item>
    <dxi-item title="Notifications" icon="email">
      <div class="padding-choice">
        <dx-check-box name="enableNotification" [(ngModel)]="enableNotification" text="Activer les notifications"></dx-check-box>
      </div>
    </dxi-item>
    <dxi-item title="Emails" icon="mention">
      <div class="padding-choice">
        <dx-check-box name="enableCopyCarbone" [(ngModel)]="enableCopyCarbone" text="Envoi systématique d'une copie carbone (CC)"></dx-check-box>
      </div>
    </dxi-item>
    <dxi-item title="Calcul automatique du prix des plats" icon="preferences" *ngIf="hasSuperAdmin">
      <div class="padding-choice">
        <label>Veuillez indiquer pour chaque traitement indiqué ci-dessous, le déclenchement du calcul du prix des plats à la fin de des derniers : </label>
        <ul>
          <li><dx-check-box name="saveItem" [(ngModel)]="saveItem" text="Enregistrement d'un article"></dx-check-box></li>
          <li><dx-check-box name="deleteItem" [(ngModel)]="deleteItem" text="Suppression d'un article"></dx-check-box></li>
          <li><dx-check-box name="selectManuallyItemPrefered" [(ngModel)]="selectManuallyItemPrefered" text="Sélection manuelle d'un article préféré"></dx-check-box></li>
          <li><dx-check-box name="addForbiddenFood" [(ngModel)]="addForbiddenFood" text="Ajout d'une denrée interdite"></dx-check-box></li>
          <li><dx-check-box name="deleteForbiddenFood" [(ngModel)]="deleteForbiddenFood" text="Suppression d'une denrée interdite"></dx-check-box></li>
          <li><dx-check-box name="replaceRawFood" [(ngModel)]="replaceRawFood" text="Remplacement de denrée"></dx-check-box></li>
          <li><dx-check-box name="duplicateProducts" [(ngModel)]="duplicateProducts" text="Duplication d'un produit"></dx-check-box></li>
          <li><dx-check-box name="importXlsItems" [(ngModel)]="importXlsItems" text="Import Excel des articles"></dx-check-box></li>
        </ul>
      </div>
    </dxi-item>
  </dx-tab-panel>

  <div class="d-flex flex-column">

    <div class="d-flex mg-t-10">
      <div class="ml-auto">
            <span class="mg-r-5">
            <yo-button iconClass="fas fa-save" buttonClass="cta-success" (onClick)="savePreferences()"
                    tooltip="Enregistrer les préférences"></yo-button>
            </span>
        <span>
        <yo-button class="mg-r-5" buttonClass="cta-inner-delete " iconClass="fas fa-times" (onClick)="closePreferences()"
                tooltip="Fermer"></yo-button>
        </span>

      </div>
    </div>

  </div>
</dx-popup>
<!--<p-dialog [closable]="false" [modal]="true" [dismissableMask]="false" [style]="{'width':'900px'}"
          [contentStyle]="{'overflow':'visible'}" >



</p-dialog>

-->


