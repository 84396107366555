import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {PreparationConditionnementSupplier} from "../../preparation-conditionnement-resolver.service";
import {PreparationConditionnementService} from "../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {MenuItem} from "primeng/api";
import {FP_ROUTES, HELP_FOLDERS} from "../../../core/constants";
import {LoadingService} from "../../../core/services/loading.service";
import {RoutemapService} from "../../../core/services/routemap.service";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";

@Component({
  selector: 'yo-preparation-calcul-conditionnement',
  templateUrl: './preparation-calcul-conditionnement.component.html',
  styleUrls: ['./preparation-calcul-conditionnement.component.scss']
})
export class PreparationCalculConditionnementComponent implements OnInit, OnDestroy {

  displayErrors: boolean;

  subscriptionRoute: Subscription;
  idPlanProduction: number;

  headersResults: any[] = [];
  errors: any = {};

  idParentSelected: number;

  plcsNotTakenAccount: PointDeLivraisonDTO[] = [];

  itemsPrintMenu: MenuItem[] = [{
    label: `Choix d'impression :`,
    items: [
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Sans détails PLC (Portrait)',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'SIMPLE')
      },
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Sans détails PLC (Paysage)',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'SIMPLE', true)
      },
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Avec détails PLC (Portrait)',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'DETAILS')
      },
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Avec détails PLC (Paysage)',
        command: event => this.pcSvc.printBonConditionnement(this.idPlanProduction, 'DETAILS', true)
      }
    ]
  }];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-global';


  constructor(private route: ActivatedRoute,
              private pcSvc: PreparationConditionnementService,
              public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { preparationConditionnementSupplier: PreparationConditionnementSupplier}) => {
        if (data.preparationConditionnementSupplier) {
          this.idPlanProduction = data.preparationConditionnementSupplier.idPlanProduction;
          this.startCalculation(data.preparationConditionnementSupplier.isRefresh);
        }
      });
  };

  goToProductPage = (idProduct: number) => window.open(`/gestionproduits(secondary:gestionproduits/produit/true/${idProduct}/conditionnement)`, '_blank');

  goToPlcPage = (idClient: number, idPlc: number) => window.open(`/gestion-clients(secondary:point-de-livraison/${idClient}/${idPlc}/conditionnement/regles-regroupements)`, '_blank');

  closeErrors = () => {
    this.displayErrors = false;
  }

  startCalculation = (refresh?: boolean) => {
    this.pcSvc.startCalculation(this.idPlanProduction, refresh)
      .subscribe(data => {
        if (data.one?.contraintesPlcsNotFound || data.one?.implementationsModelesPlatsNotRealized || data.one?.plcsWithoutRulesConfiguration) {
          this.errors.contraintesPlcsNotFound = data.one.contraintesPlcsNotFound || [];
          this.errors.implementationsModelesPlatsNotRealized = data.one.implementationsModelesPlatsNotRealized ? data.one.implementationsModelesPlatsNotRealized.map(val => { let tmp: string[] = val.split(","); return { id: parseInt(tmp[0]), libelle: tmp[1] } }) : [];
          this.errors.plcsWithoutRulesConfiguration = data.one.plcsWithoutRulesConfiguration ? data.one.plcsWithoutRulesConfiguration.map(val => { let tmp: string[] = val.split(","); return { id: parseInt(tmp[0]), idClient: parseInt(tmp[1]), libelle: tmp[2] } }) : [];
          this.displayErrors = true;
        } else {
          this.plcsNotTakenAccount = data.one?.plcsNotTakenAccount;
          this.displayErrors = this.plcsNotTakenAccount?.length > 0;
          this.pcSvc.getAllResults(this.idPlanProduction)
            .subscribe(data => {
              this.headersResults = data.resultList.length ? data.resultList.filter(d => !d.distributionDirecte) : data.resultList;
            });
        }
      });
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.cursor = 'pointer';
    }
  };

  loadChildren = $event => {
    if($event.rowType === 'data') {
      const id = $event.data.id;
      this.idParentSelected = id;
    }
  };

  printLivraison = (pid: number) => {
    this.pcSvc.printBonLivraison(pid);
  };

  helpSummaries = () => {

  };

}
