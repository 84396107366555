import {ObjectDTO} from './object-dto';

export class BonReceptionLigneAppellationDTO extends ObjectDTO {

  // bon reception ligne
   idBonReceptionLigne : number;

  // litige
  idAppellation : number;
  libelleAppellation : string;




}
