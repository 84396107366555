import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {AppellationDTO} from '../../dtos/appellations-dto';
import {FormFieldFileSupplier} from '../../suppliers/form-field-file-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {HttpService} from "../technique/http.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";

export const URL_FIND_ALL_APPELATION=`dolrest/appellations/findAll`;

@Injectable({
  providedIn: 'root'
})
export class AppellationsService extends GenericHandler<AppellationDTO> {

  appellationsEnvironnement:AppellationDTO[];

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES APPELLATIONS';

  getCreateNewObjectLabel = (): string => 'CRÉER UNE APPELLATION';

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();
    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  getEntityName = (): string => 'appellation';

  getFields = (dto: AppellationDTO): FormFieldBaseSupplier<any>[] => {

    // Par défaut une Appellation est active.
    if (this.utils.isNullOrEmpty(dto)|| dto.id == 0 || dto.id == undefined) {
      dto = new AppellationDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        excelType:'string',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        excelType:'string',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),

      new FormFieldFileSupplier({
        key: 'file',
        label: 'Logo',
        readonly: !this.canModify(dto),
        value: '',
        entityName: this.getEntityName().toLowerCase(),
        required: false,
        refresh: new Date().getTime(),
        ordre: 1,
        order: 6
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 7
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),

      new FormFieldCheckboxSupplier({
        key: 'durable',
        label: 'Durable',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.durable,
        required: false,
        order: 8
      }),

      new FormFieldCheckboxSupplier({
        key: 'bio',
        label: 'Bio',
        excelType:'boolean',
        readonly: !this.canModify(dto),
        value: dto.bio,
        required: false,
        order: 9
      }),

    ];

    return formFields.sort((a, b) => a.order - b.order);
  };


  getAllFromEnvironnement = (): void => {
    this.gds.getAll(this.getEntityName(), ['libelle,asc'], true)
      .subscribe(response => {
        this.appellationsEnvironnement = [];
        if (!this.utils.isCollectionNullOrEmpty(response.resultList)) {
          this.appellationsEnvironnement = response.resultList.map(item => {
            item.libelle = item.libelle.toUpperCase();
            return item;
          });
        }
        this.announceObjects(this.appellationsEnvironnement);
      });
  };

  /**
   * récupèrer toutes les appellations
   */
  findAll = (ssw?: SearchSupplierWrapper) => this.httpSvc.post(URL_FIND_ALL_APPELATION, ssw || null);

  createEmptyDTO = (): AppellationDTO => new AppellationDTO();

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER L'APPELLATION '${data.libelle.toUpperCase()}'`;


}
