import {of, Subscription} from 'rxjs';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {MenuItem, SelectItem} from 'primeng/api';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {UtilsService} from '../../core/utils/utils.service';
import {PreferencesUtilisateurService} from '../../core/services/preferences-utilisateur.service';
import * as moment from 'moment';
import {isEmpty as _isEmpty} from 'lodash'
import {
  DATEPICKER_FR,
  FS_ROUTES,
  MENU_PROFIL,
  MENUS_PLANNING_CHOOSER,
  MOMENT_LUNDI,
  MSG_KEY,
  MSG_SEVERITY,
  USER_PREFERENCE
} from '../../core/constants';
import {ContratMenuConviveRepasDTO} from '../../core/dtos/contratmenuconviverepas-dto';
import {RoutemapService} from '../../core/services/routemap.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {MenusPlanning2Service} from '../../core/services/gestionmenus/menus-planning2.service';
import {GestionMenusSupplier} from '../gestionmenus-resolver.service';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {DuplicationMenuService} from '../../core/services/gestionmenus/duplication-menu.service';
import {PrintCoutMatiereService} from '../../core/services/gestionmenus/print-cout-matiere.service';
import {RepasDTO} from '../../core/dtos/repas-dto';
import {PrintMenuService} from '../../core/services/gestionmenus/print-menu.service';
import {ToastService} from "../../core/services/technique/toast.service";
import InfoMenuValidationSupplier from "../../core/suppliers/gestionmenus/info-menu-validation-supplier";


@Component({
  selector: 'yo-menus-toolbar',
  templateUrl: './menus-toolbar.component.html',
  styleUrls: ['./menus-toolbar.component.scss'],
})
export class MenusToolbarComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() gms: GestionMenusSupplier;

  prestations: ContratMenuConviveDTO[] = [];

  fr = DATEPICKER_FR;
  chooserPlanning = MENUS_PLANNING_CHOOSER;

  regimes: ContratMenuConvive__ContrainteAlimDTO[];
  selectedRegime: ContratMenuConvive__ContrainteAlimDTO;

  selectedStartDateMenus: Date = new Date();
  selectedContratMenu: ContratmenuDTO;
  // selectedPrestations: ContratMenuConviveDTO[];
  selectedPrestation: ContratMenuConviveDTO;
  selectedContratMenuConviveRepas: ContratMenuConviveRepasDTO[];

  selectedDates: moment.Moment[];

  //preferences utilisateur
  prefsUser$;
  prefsUser = undefined;

  //GEMRCN Supplier: données nécessaires à l'affichage du GEMRCN --> date, contrats menus convives (avec modeles nutris)
  displayFormSearch = false;
  displayDiet: boolean = false;

  displayEconomat: boolean = false;
  enableSaveImmediatly = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_TOOLBAR_SAVEIMMEDIATLY);

  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];
  disabledDays: number[];
  dateMin: Date;

  profils: SelectItem[];
  selectedProfil: SelectItem;

  itemsAnalyseMenu: MenuItem[] = [
    {
      label:'Analyses :',
      items: [
        {
          icon:'fas fa-file-alt',
          label: 'GEMRCN',
          command: event => this.openGEMRCN(),
        },
        {
          icon:'fas fa-chart-bar',
          label: 'COÛTS',
          command: event => this.openAnalyseCouts()
        },
        {
          icon:'fas fa-chart-bar',
          label: 'EGALIM',
          command: event => this.openAnalyseEgalim()
        }]
    }
  ];

  itemsPrintMenu: MenuItem[] = [{
    label: `Choix d'impression :`,
    items: [
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Menu',
        command: event => this.openDialogPrintMenu(),
        disabled: this.printButtonDisabled()
      },
      {
        icon: 'far fa-lg fa-file-pdf',
        label: 'Bilan allergène',
        command: event => this.openPrintDialogBilanAllergene()
      },
      {icon: 'far fa-lg fa-file-excel', label: 'Coût matière', command: event => this.openPrintDialogCoutMatiere()}
    ]
  }];

  repasList: RepasDTO[];


  //subscriptions
  subSelectedContratMenuConvives: Subscription;
  subPrefsUser: Subscription;
  subSearchResults: Subscription;
  subMonthChange: Subscription;

  constructor(public menusToolbarSvc: MenusToolbarService,
              private routeMapSvc: RoutemapService,
              public utils: UtilsService,
              private cd: ChangeDetectorRef,
              public prefUserSvc: PreferencesUtilisateurService,
              public mp2Svc: MenusPlanning2Service,
              private auth2Svc: Auth2Service,
              private duplicationMenusSvc: DuplicationMenuService,
              private printCoutMatiereService: PrintCoutMatiereService,
              private printMenuService: PrintMenuService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    this.iniData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSelectedContratMenuConvives);
    this.utils.unsubscribe(this.subPrefsUser);
    this.utils.unsubscribe(this.subSearchResults);
    this.utils.unsubscribe(this.subMonthChange);
  }


  loadPreferences = (key: string, property: string, objects: any[]) => {

    const pref = this.prefUserSvc.getPreferenceUtilisateurIntValue(key);
    const itemArr = objects ? objects.filter(item => item[property] === pref) : undefined;
    if (itemArr.length > 0) {
      return this.utils.preSelectSingleList(objects, itemArr[0])
    } else {
      if (objects.length > 0) {
        return objects[0];
      } else {
        return undefined;
      }

    }
  };

  iniData = () => {

    // profils d'affichage
    this.profils = [
      {label: 'Nutrition', value: MENU_PROFIL.DIET, icon: 'fab fa-nutritionix'},
      {label: 'Gestionnaire', value: MENU_PROFIL.ECONOMAT, icon: 'fas fa-piggy-bank'}
    ];

    this.selectedContratMenu = this.loadPreferences(USER_PREFERENCE.GESTIONMENUS_CONTRAT, 'id', this.gms.contrats);
    this.selectedStartDateMenus = this.prefUserSvc.getPreferenceUtilisateurDateValue(USER_PREFERENCE.GESTIONMENUS_DATE);
    this.changePlanning(undefined, MENUS_PLANNING_CHOOSER.CONTRAT);

    let firstCall = 0;
    this.subMonthChange = this.menusToolbarSvc.monthChange$
      .pipe(
        debounceTime(100),
        switchMap(event => {
          if(!this.selectedStartDateMenus)
            this.selectedStartDateMenus = new Date();
          if (this.utils.isNullOrEmpty(this.selectedPrestation)) {
            return of(null);
          }
          return this.mp2Svc.changeMonth(this.selectedPrestation, this.selectedStartDateMenus, event);
        }),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {

        if (response) {

          let reload = firstCall === 0 ? true : false;
          firstCall++;

          this.disabledDays = response.disabledDays;
          this.datesSaisieEffectifs = response.datesSaisieEffectifs;
          this.datesCreationMenus = response.datesCreationMenus;
          this.dateMin = response.dateMin;

          // si la date selectionnée n'est pas accessible, on prend la premiere dispo
          if (!this.utils.isNullOrEmpty(this.dateMin)
            && !this.utils.isDateAfterOrEquals(this.selectedStartDateMenus, this.dateMin)) {
            this.selectedStartDateMenus = this.dateMin;
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `La première date disponible pour cette prestation a été sélectionnée`);
            reload = true;
          }

          if (reload) {
            this.menusToolbarSvc.announceStartDate(this.selectedStartDateMenus);
          }
        }
      });
    this.menusToolbarSvc.announceMonthChange(undefined);
  };


  changePlanning = (event: any, chooser: MENUS_PLANNING_CHOOSER) => {
    let rf: { regimes: ContratMenuConvive__ContrainteAlimDTO[], selectedRegime: ContratMenuConvive__ContrainteAlimDTO } = undefined;
    switch (chooser) {

      case MENUS_PLANNING_CHOOSER.CONTRAT:

        if (this.utils.isNullOrEmpty(this.selectedContratMenu)) {
          this.selectedPrestation = undefined;
          this.selectedRegime = undefined;
        } else {

          const pf = this.changePrestationFilter(this.gms.prestations);
          this.prestations = pf.prestationsFilter;
          this.selectedPrestation = pf.selectedPrestation;
          rf = this.changeRegimeFilter(this.selectedPrestation);
        }

        break;

      case MENUS_PLANNING_CHOOSER.PRESTATION:
        if (this.utils.isNullOrEmpty(this.selectedPrestation)) {
          this.regimes = [];
          this.selectedRegime = undefined;
        } else {
          rf = this.changeRegimeFilter(this.selectedPrestation);
        }
    }

    if (!this.utils.isNullOrEmpty(rf)) {
      this.regimes = rf.regimes;
      this.selectedRegime = rf.selectedRegime;
    }

    this.menusToolbarSvc.announceContratMenu(this.selectedContratMenu);
    this.menusToolbarSvc.announcePrestations([this.selectedPrestation]);
    this.menusToolbarSvc.announceRegime(this.selectedRegime);
    this.menusToolbarSvc.announceStartDate(this.selectedStartDateMenus);
    this.menusToolbarSvc.announceMonthChange(undefined);
  };

  changePrestationFilter = (prestations: ContratMenuConviveDTO[]): { prestationsFilter: ContratMenuConviveDTO[], selectedPrestation: ContratMenuConviveDTO } => {

    let prestationsFilter = prestations.filter(prestation => prestation.contratMenuId === this.selectedContratMenu.id);
    let selectedPrestation;

    if (!this.utils.isCollectionNullOrEmpty(prestationsFilter) && prestationsFilter.length == 1) {
      selectedPrestation = prestationsFilter[0];
    } else {
      selectedPrestation = this.loadPreferences(USER_PREFERENCE.GESTIONMENUS_PRESTATION, 'id', prestationsFilter);
    }
    return {prestationsFilter, selectedPrestation};
  };

  changeRegimeFilter = (prestation: ContratMenuConviveDTO): { regimes: ContratMenuConvive__ContrainteAlimDTO[], selectedRegime: ContratMenuConvive__ContrainteAlimDTO } => {
    let regimes = prestation.contratMenuConvive__contrainteAlimList;
    let selectedRegime;

    if (!this.utils.isCollectionNullOrEmpty(regimes) && regimes.length == 1) {
      selectedRegime = regimes[0];
    } else {
      selectedRegime = this.loadPreferences(USER_PREFERENCE.GESTIONMENUS_REGIME, 'id', regimes);
    }
    return {regimes, selectedRegime};
  };


  openGEMRCN = () => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GME_GEMRCN]);
  };

  private openAnalyseCouts() {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GME_COUTS]);
    this.mp2Svc.announceAnalyseCout();
  }

  private openAnalyseEgalim = (): void => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GME_EGALIM]);
    this.mp2Svc.announceAnalyseEgalim();
  }

  yoStartDateMenusChange = (event: Date) => {
    this.selectedStartDateMenus = event;
    this.menusToolbarSvc.announceStartDate(this.selectedStartDateMenus);
  };

  goToNextDate = () => {
    this.selectedStartDateMenus = moment(this.selectedStartDateMenus).add(1, 'weeks').isoWeekday(MOMENT_LUNDI).toDate();
    this.menusToolbarSvc.announceStartDate(this.selectedStartDateMenus);
  };

  goToPreviousDate = () => {
    this.selectedStartDateMenus = moment(this.selectedStartDateMenus).subtract(1, 'weeks').isoWeekday(MOMENT_LUNDI).toDate();
    this.menusToolbarSvc.announceStartDate(this.selectedStartDateMenus);
  };

  ngAfterViewInit(): void {
    //this.menusToolbarSvc.openSettings();

    this.utils.unsubscribe(this.subPrefsUser);
    this.subPrefsUser = this.prefUserSvc.preferencesOfUser$.subscribe(prefs => {

      if (!_isEmpty(prefs)) {
        this.prefsUser = prefs;

        // Récupération de la préférence utilisateur : faut-il ou non montrer la form de recherche.
        this.displayFormSearch = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_FORMSEARCH);

        const displayCoherenceArticle = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_COHERENCE_ARTICLE);
        this.menusToolbarSvc.announceDisplayCoherenceArticle(displayCoherenceArticle);

        // Quelle que soit la préférence utilisateur sur la form de recherche, si l'Utilisateur ne peut modifier
        // la forme de recherche doit être cachée
        if (!this.canModifyNoParam()) {
          this.displayFormSearch = false;
        }

        // chargement du profil d'affichage (diet ou economat )
        const prefProfilMenu = this.prefUserSvc.getPreferenceUtilisateurIntValue(USER_PREFERENCE.GESTIONMENUS_PROFIL);
        this.menusToolbarSvc.announceDisplayProfil(prefProfilMenu);

        for (let profil of this.profils) {
          if (profil.value === prefProfilMenu) {
            this.selectedProfil = profil.value;
            break;
          }
        }

        this.displayDiet = this.menusToolbarSvc.isProfilToDisplay(prefProfilMenu, MENU_PROFIL.DIET);
        this.displayEconomat = this.menusToolbarSvc.isProfilToDisplay(prefProfilMenu, MENU_PROFIL.ECONOMAT);


        this.menusToolbarSvc.announceDisplayFormSearch(this.displayFormSearch);

        this.enableSaveImmediatly = this.prefUserSvc.getPreferenceUtilisateurBooleanValue(USER_PREFERENCE.GESTIONMENUS_TOOLBAR_SAVEIMMEDIATLY);

        this.cd.detectChanges();
      }
    });

    this.prefUserSvc.announcePreferencesUtilisateur();
  }

  /**
   * Ouverture du dialogue d'impression pour le bilan allergène
   */
  openPrintDialogBilanAllergene = () => {
    this.printMenuService.announceDisplayBilanAllegene(true);
  };

  /**
   * Ouverture du dialogue d'impression des menus
   */
  openDialogPrintMenu = () => {
    this.printMenuService.announceDisplayPrintMenu(true);
  };

  /**
   * Ouverture du dialogue permettant la duplication des MenuComposition
   */
  openDuplicationDialog = () => {

    this.duplicationMenusSvc.announceDisplayDialogDuplicationMenus(true);


  };

  openPrintDialogCoutMatiere = () => {
    this.printCoutMatiereService.announceDisplayCoutMatiere(true);
  };


  /**
   * Indique si le bouton de chargement du dialogue d'impression
   * doit être désactivé
   * @returns {boolean}
   */
  printButtonDisabled(): boolean {
    return false;
  };

  log = () => {
    console.log('changeDetection');
  };

  /**
   * Indique si la modification est autorisée. La modification est autorisée si et seulement si
   * le site du ContratMenu sélectionné est le site principal de l'utilisateur connecté.
   * @param contratMenu
   * @returns {boolean}
   */
  canModify = (contratMenu :ContratmenuDTO): boolean => {

    if (!this.utils.isNullOrEmpty(contratMenu)) {
      return this.auth2Svc.isSiteLocal(contratMenu.site.id);
    }

    return false;
  };

  /**
   * @deprecated use canModify generic instead
   * @returns {boolean}
   */
  canModifyNoParam = (): boolean => {
    if (!this.utils.isNullOrEmpty(this.selectedContratMenu)) {
      return this.auth2Svc.isSiteLocal(this.selectedContratMenu.site.id);
    }
    return false;
  };

  /**
   * Renvoie la liste des id jour semaine sur lesquels on peut positionner des menus.
   * Valeurs possibles :
   * 1 -> lundi
   * 2 -> mardi
   * 3 -> mercredi
   * 4 -> jeudi
   * 5 -> jeudi
   * 6 -> jeudi
   * 0 -> dimanche
   * @returns {number[]}
   */
  getSelectedIdsJoursSemaines = (cmcrList: ContratMenuConviveRepasDTO[]): number[] => {

    let arrayIds = [];


    cmcrList.forEach(contratMenuConviveRepas => {

      let idJour = contratMenuConviveRepas.idJourSemaine;
      if (idJour == 7) {
        idJour = 0;
      }

      // On vérifie si l'id jour semaine n'est pas déjà présent dans le tableau
      // let index = arrayIds.indexOf(contratMenuConviveRepas.idJourSemaine);
      let index = arrayIds.indexOf(idJour);
      if (index < 0) {
        // arrayIds.push(contratMenuConviveRepas.idJourSemaine);
        arrayIds.push(idJour);
      }
    });

    return arrayIds;
  };

  /**
   * Calculer les elements du calendrier
   * Highlight des jours ooù il  y a des repas
   * Desactivation des jours non dispo dans les contrats menus convives sélectionnés
   * @param event
   */
  getMonthMenusCompoDates = (event: any) => {

    // on ne doit pas déclencher le calcul du mois dès qu'un utilisateur fait un onChange sur le calendrier
    // c'est pour cela qu'on met un observable avec un debounceTime et switchMap
    this.menusToolbarSvc.announceMonthChange(event);


  };

  openValidationMenu = () => {
    let infos: InfoMenuValidationSupplier = new InfoMenuValidationSupplier();
    infos.contrats = this.gms?.contrats;
    infos.prestations = this.prestations;
    infos.regimes = this.regimes;
    infos.selectedContrat = this.selectedContratMenu;
    infos.selectedPrestation = this.selectedPrestation;
    infos.selectedRegime = this.selectedRegime;
    infos.displayDialog = true;

    this.menusToolbarSvc.announceDisplayValidationMenusDialog(infos);
  };


  /**
   * On désactive s'il n ' y pas pas contrat / prestation / régime sélectionné ou si le contrat n'est pas un site local
   * @param siteId
   */
  isDisabledBySelectionOrEnvironnement = (siteId: number) => {

    if (this.isDisabledActionButton()) {
      return true;
    }

    return !this.auth2Svc.isSiteLocal(siteId);

  };


  /**
   * Les boutons d'action sont désactivés s'il n ' y pas pas contrat / prestation / régime sélectionné
   */
  isDisabledActionButton = () => {

    if (this.utils.isNullOrEmpty(this.selectedContratMenu)
      || this.utils.isNullOrEmpty(this.selectedPrestation)
      || this.utils.isNullOrEmpty(this.selectedRegime)) {
      return true;
    }
    return false;
  };
}



