import {Subscription} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {UtilisateurDTO} from '../../../core/dtos/utilisateur-dto';
import {Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {UtilisateurService} from '../../../core/utilisateur.service';
import {SearchService} from '../../../core/services/search.service';
import {UtilisateurResolverService} from 'app/gestion-referentiel/utilisateur-resolver.service';
import {FS_ROUTES, HELP_FOLDERS} from '../../../core/constants';
import {RoutemapService} from '../../../core/services/routemap.service';
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-grilleutilisateurs',
  templateUrl: './grilleutilisateurs.component.html',
  styleUrls: ['./grilleutilisateurs.component.scss']
})
export class GrilleutilisateursComponent implements OnInit {

  start: boolean = true;
  _lazyParams: any;

  // Subscriptions
  subGrilleUtilisateurs: Subscription;
  subUtilisateur: Subscription;
  subDeleteUtilisateur: Subscription;


  totalRecords: number;
  loading: boolean;
  utilisateursForGrille: UtilisateurDTO[];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/utilisateurs';

  constructor( private router: Router,
               private routeMapSvc:RoutemapService,
               public utils: UtilsService,
               private utilisateurService: UtilisateurService,
               private searchService:SearchService,
               private utilisateurResolverService: UtilisateurResolverService) { }

  ngOnInit() {
    this.utils.setTitle('Gestion des utilisateurs');
    this.subGrilleUtilisateurs = this.utilisateurService.searchUtilisateur('').subscribe(utilisateurs => this.utilisateursForGrille = utilisateurs);

    this.subUtilisateur = this.utilisateurResolverService.utilisateurAnnounceSource.subscribe(utilisateur => {
      const idx = this.utilisateursForGrille.findIndex(u => u.id === utilisateur.id);
      if (idx >= 0)
        this.utilisateursForGrille[idx] = utilisateur;
      else
        this.utilisateursForGrille.push(utilisateur);
    });

    this.utilisateurService.utilisateurSubjectToDelete$.subscribe(utilisateur => {
      const idx = this.utilisateursForGrille.findIndex(u => u.id === utilisateur.id);
      this.utilisateursForGrille.splice(idx, 1);
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleUtilisateurs);
    this.utils.unsubscribe(this.subUtilisateur);
    this.utils.unsubscribe(this.subDeleteUtilisateur);
  }

  openUtilisateur = (utilisateurDTO: UtilisateurDTO): void => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.ADM_UTILISATEUR_FIC,utilisateurDTO.id]);
  };

  confirmDelete = async (utilisateurDTO: UtilisateurDTO): Promise<void> => {
    const result = confirm("Etes vous sûr de vouloir supprimer l\'utilisateur '" + utilisateurDTO.login + "' ?", 'Suppression de l\'utilisateur');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.delete(utilisateurDTO);
    }
  };

  /**
   * Suppression de l'UtilisateurDTO passé en paramètre.
   * @param {UtilisateurDTO} utilisateurDTO
   */
  delete = (utilisateurDTO: UtilisateurDTO): void => {
    this.subDeleteUtilisateur = this.utilisateurService.deleteUtilisateur(utilisateurDTO);
  };

  openNewUtilisateur = (): void => {
    this.utilisateurService.announceUtilisateurToCreate();
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.ADM_UTILISATEUR_FIC,0]);
  };

  help = (): undefined => undefined;

}
